// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import TableCell from '@material-ui/core/TableCell';

const SoilsTableCell = withStyles(theme => ({
    head: {
        backgroundColor: 'rgba(149, 75, 180, 0.1)',
        color: theme.palette.secondary.contrastText,
        padding: 10,
        fontSize: '1rem',
    },
    body: {
        padding: 0,
        fontSize: '1rem',
        '& input': {
            width: 50,
            fontSize: '1rem',
        },
    },
}))(TableCell);

export default SoilsTableCell;
