import { LegendText } from 'client/src/models/draft/legend.model';
import { svgNS } from '../draft/default.draft';

export const legendText = (data: LegendText): SVGGElement => {
    const { x, y, first, second, className } = data;
    const firstText: SVGTextElement = document.createElementNS(svgNS, 'text');
    const secondText: SVGTextElement = document.createElementNS(svgNS, 'text');
    const g: SVGGElement = document.createElementNS(svgNS, 'g');
    firstText.setAttribute('class', `legendFirstText ${className}`);
    firstText.textContent = first;
    firstText.setAttribute('x', `${x}`);
    firstText.setAttribute('y', `${y}`);
    secondText.setAttribute('class', `legendSecondText ${className}`);
    secondText.textContent = second;
    secondText.setAttribute('x', `${x + 10}`);
    secondText.setAttribute('y', `${y + 5}`);
    g.appendChild(firstText);
    g.appendChild(secondText);

    return g;
};
