import React from 'react';

import theme from '../../util/theme';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Icons
import DeleteIcon from '@material-ui/icons/Delete';

const styles = {
    ...theme
}

const ContextTaskMenu = props => {
    const { mouseX, mouseY } = props;
  return (
    <Menu
      keepMounted
      open={mouseY !== null}
      onClose={props.handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        mouseY !== null && mouseX !== null
          ? { top: mouseY, left: mouseX }
          : undefined
      }
    >
      <MenuItem onClick={props.handleOpenDialog}>
        <DeleteIcon/>
        &nbsp;Удалить
      </MenuItem>
    </Menu>
  );
};

export default withStyles(styles)(ContextTaskMenu);
