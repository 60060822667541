import { Task } from '../models/task.model';

import { draftDefault, svgNS } from './draft/default.draft';
import { getDraft } from './draft/get-draft.draft';
import { getLinePath } from './draft/get-line-path.draft';
import { getPath } from './draft/get-path.draft';
import { getWaterRectangle } from './draft/get-water-rectangle.draft';

import soilsDecoder from './soils-decoder';

import { clayHatch } from './svg/clay-hatch.svg';
import { dotMaker } from './svg/dot.svg';
import { grid } from './svg/grig.svg';
import { legendText } from './svg/legend-text.svg';
import { levelMark } from './svg/level-mark.svg';
import { lineMaker } from './svg/line-maker.svg';
import { pathMaker } from './svg/path.svg';
import { polyMaker } from './svg/polyline.svg';
import { sandHatch } from './svg/sand-hatch.svg';
import { trashHatch } from './svg/trash-hatch.svg';

export const printSettlement = (data: Task, id: string, index: number): string => {
    const svg: SVGElement | null = document.querySelector(`#${id}`);
    if (!svg) {
        return 'SVG элемент не найден';
    }

    if (!data.report) {
        return 'Отчёт отсутствует';
    }

    const dataset = draftDefault();
    const defs = document.createElementNS(svgNS, 'defs');
    const { well, soilsData } = data;
    const { scale } = dataset;

    const newLimit = (data.report[index].result.b * scale) / 2 + 40;
    const limit = newLimit > dataset.limit ? newLimit : 200;
    const soilsThickness = data.well.reduce((total, item) => total + Number(item.thickness), 0);
    const settlementTab = data.report[index].settlement.tab;
    const sSize = (settlementTab[settlementTab.length - 1].z + data.report[index].dn.value) * scale + 150 + 35;
    const newHeight = 600 < Math.max(soilsThickness * scale + 150 + 45, sSize) ? Math.max(soilsThickness, sSize) : 600;
    const newWidth = 600 < 2 * (newLimit + 165) ? 2 * (newLimit + 165) : 600;
    const newSize = Math.max(newHeight, newWidth);

    if (newWidth > newHeight) {
        svg.setAttribute('viewBox', `0 0 ${newWidth} ${newHeight}`);
        const trueScale = newSize / 600;
        svg.setAttribute('width', `${newWidth / trueScale}`);
        svg.setAttribute('height', `${newHeight / trueScale}`);
    } else {
        svg.setAttribute('viewBox', `0 0 ${newSize} ${newSize}`);
    }

    const centerX = newSize > 600 ? newSize / 2 : 300;
    const centerY = newSize > 600 ? (150 * 600) / newSize : 150;
    const draft = getDraft(data, index, true, centerX, centerY);
    draft.limit = limit;

    const { bodyCode } = draft;
    const { nl, dl, cl, fl, wl, al } = draft.levels;
    const { column } = draft.foundation;
    const { offset } = draft.levelMark;
    const { tab, hc, levelhc, xhc } = index === -1 ? data.report[index].settlement : data.report[index].settlement;
    const graphScale = 10;
    let line, path, pathBg, temp: any, g: any, newColor, title;

    svg.innerHTML = ''; // cleanup
    // Prepearing defs
    svg.appendChild(defs);
    defs.appendChild(trashHatch());
    defs.appendChild(clayHatch());

    if (draft.sandToggle) {
        // @@@ This condition is very interesting
        defs.appendChild(draft.sandHatch);
    } else {
        draft.sandHatch = sandHatch();
        defs.appendChild(draft.sandHatch);
        draft.sandToggle = true;
    }

    // Add to svg
    // Grid
    svg.appendChild(grid(newSize, newSize, draft));
    // Body
    svg.appendChild(polyMaker({ title: 'Фундамент', id: 'fBody1', class: 'fBodyResult', points: bodyCode }));
    temp = polyMaker({ title: 'Фундамент', id: 'fBody2', class: 'fBodyResult', points: bodyCode });
    temp.setAttribute('transform', `scale(-1, 1) translate(${-centerX * 2},0)`);
    svg.appendChild(temp);

    // Soils
    draft.soils.push(centerY - nl);
    for (let i = 0; i < well.length; i++) {
        if (!well[i].thickness || (!well[i].soil && well[i].soil !== 0)) {
            continue;
        }
        g = document.createElementNS(svgNS, 'g');
        g.setAttribute('id', `soilGroup${i + 1}`);

        const { paths, down } = getPath(draft, well[i].thickness, i, draft.soils[i], i === well.length - 1);
        draft.soils.push(down);

        path = pathMaker({ title: ``, id: ``, class: '', d: paths[0] });
        if (soilsData[well[i].soil].type === 'clay') {
            path.setAttribute('fill', 'url(#clayHatch)');
        } else {
            path.setAttribute('fill', 'url(#sandHatch)');
        }
        g.appendChild(path);

        title = `Слой ${well[i].soil + 1}\n`;
        title += `${soilsDecoder(soilsData[well[i].soil].type)} ${soilsDecoder(
            soilsData[well[i].soil].structure
        )} ${soilsDecoder(soilsData[well[i].soil].moisture)}\n`;
        title += `${soilsData[well[i].soil].il ? 'Iₗ=' + soilsData[well[i].soil].il + '; ' : ''}E=${
            soilsData[well[i].soil].E
        } т/м²\n`;
        title += `C₂=${soilsData[well[i].soil].c2}т/м²; φ₂=${soilsData[well[i].soil].phi2}°; γ₂=${
            soilsData[well[i].soil].gamma2
        }т/м³`;
        pathBg = pathMaker({ title: title, id: `soil${i}`, class: 'soils', d: paths[0] });
        newColor = soilsData[well[i].soil].color.split('');
        newColor[newColor.length - 2] = '1';
        newColor = newColor.join('');
        pathBg.setAttribute('fill', newColor);
        g.appendChild(pathBg);
        if (paths[1]) {
            path = pathMaker({ title: ``, id: ``, class: 'secondaryLine', d: paths[1] });
            g.appendChild(path);
        }
        g.setAttribute('fill', soilsData[data.well[i].soil].color);
        svg.appendChild(g);
    }

    // CL
    line = lineMaker({
        class: 'dimLine',
        x1: centerX + limit,
        y1: centerY - cl,
        x2: centerX + limit + 40,
        y2: centerY - cl,
    });
    svg.appendChild(
        levelMark(centerX + limit + 30, centerY - cl + offset, cl / 100, 'CL', false, false, line, draft.levelMark)
    );

    // DL
    if (dl !== nl) {
        path = pathMaker({
            title: ``,
            id: ``,
            class: 'secondaryLine dlLine',
            d: getLinePath(centerY - dl, column, draft),
        });
        svg.appendChild(path);
        line = lineMaker({
            class: 'dimLine',
            x1: centerX - limit,
            y1: centerY - dl,
            x2: centerX - limit - 40,
            y2: centerY - dl,
        });
        svg.appendChild(
            levelMark(
                centerX - limit - 30,
                centerY - dl + (dl > nl ? offset : -offset),
                dl / 100,
                'DL',
                dl > nl ? false : true,
                true,
                line,
                draft.levelMark
            )
        );
    }

    // NL
    line = lineMaker({
        class: 'dimLine',
        x1: centerX - limit,
        y1: centerY - nl,
        x2: centerX - limit - 40,
        y2: centerY - nl,
    });
    svg.appendChild(
        levelMark(
            centerX - limit - 30,
            centerY - nl - (dl > nl ? offset : -offset),
            nl / 100,
            dl !== nl ? 'NL' : 'DL,NL',
            dl > nl ? true : false,
            true,
            line,
            draft.levelMark
        )
    );

    // FL
    line = lineMaker({
        class: 'dimLine',
        x1: centerX + limit,
        y1: centerY - fl,
        x2: centerX + limit + 40,
        y2: centerY - fl,
    });
    svg.appendChild(
        levelMark(centerX + limit + 30, centerY - fl + offset, fl / 100, 'FL', false, false, line, draft.levelMark)
    );

    // WL && AL
    if (wl !== undefined) {
        path = pathMaker({
            title: ``,
            id: ``,
            class: 'secondaryLine wlLine',
            d: getLinePath(centerY - wl, column, draft, true),
        });
        svg.appendChild(path);

        line = lineMaker({
            class: 'dimLine',
            x1: centerX + column * 2,
            y1: centerY - wl,
            x2: centerX + limit + 60,
            y2: centerY - wl,
        });

        svg.appendChild(
            levelMark(centerX + limit + 45, centerY - wl + offset, wl / 100, 'WL', fl > wl, false, line, draft.levelMark)
        );

        if (al !== undefined) {
            path = pathMaker({
                title: ``,
                id: ``,
                class: 'secondaryLine wlLine',
                d: getLinePath(centerY - al, column, draft, true),
            });
            svg.appendChild(path);

            line = lineMaker({
                class: 'dimLine',
                x1: centerX + column * 2,
                y1: centerY - al,
                x2: centerX + limit + 60,
                y2: centerY - al,
            });
            svg.appendChild(
                levelMark(
                    centerX + limit + 45,
                    centerY - al + offset,
                    al / 100,
                    'AL',
                    al < fl - hc * scale,
                    false,
                    line,
                    draft.levelMark
                )
            );
        }

        const bottomBorder = Math.min(al ? centerY - al : Infinity, centerY - nl + soilsThickness * scale);
        path = pathMaker({
            title: ``,
            id: ``,
            class: 'waterBg',
            d: getWaterRectangle(draft, wl, bottomBorder),
        });
        svg.appendChild(path);
    }

    // down of soils
    line = lineMaker({
        class: 'dimLine',
        x1: centerX - limit,
        y1: centerY - nl + soilsThickness * scale,
        x2: centerX - limit - 40,
        y2: centerY - nl + soilsThickness * scale,
    });
    svg.appendChild(
        levelMark(
            centerX - limit - 30,
            centerY - nl + soilsThickness * scale + offset,
            (nl - soilsThickness * scale) / 100,
            '',
            false,
            true,
            line,
            draft.levelMark
        )
    );

    // BC
    line = lineMaker({
        class: 'dimLine',
        x1: centerX + xhc * graphScale + 20,
        y1: centerY - fl + hc * scale,
        x2: centerX + limit + 40,
        y2: centerY - fl + hc * scale,
    });
    svg.appendChild(
        levelMark(
            centerX + limit + 30,
            centerY - fl + hc * scale - offset,
            levelhc,
            'BC',
            al ? al > fl - hc * scale : true,
            false,
            line,
            draft.levelMark
        )
    );

    // settlement
    let legendTxt;
    // legend
    legendTxt = legendText({
        x: centerX - limit - 60,
        y: centerY - fl,
        first: 'σ',
        second: 'zp',
        className: 'textSigmazp',
    });
    svg.appendChild(legendTxt);
    line = dotMaker({
        class: 'dotSigmazp',
        x1: centerX - limit - 70,
        y1: centerY - fl - 5,
        x2: centerX - limit - 70 + 0.5,
        y2: centerY - fl - 5,
        title: ``,
    });
    svg.appendChild(line);

    legendTxt = legendText({
        x: centerX - limit - 60,
        y: centerY - fl + 20,
        first: 'σ',
        second: 'zg',
        className: 'textSigmazg',
    });
    svg.appendChild(legendTxt);
    line = dotMaker({
        class: 'dotSigmazg',
        x1: centerX - limit - 70,
        y1: centerY - fl + 15,
        x2: centerX - limit - 70 + 0.5,
        y2: centerY - fl + 15,
        title: ``,
    });
    svg.appendChild(line);

    legendTxt = legendText({
        x: centerX - limit - 60,
        y: centerY - fl + 40,
        first: 'σ',
        second: 'zg·0.5',
        className: 'textSigmazg02',
    });
    svg.appendChild(legendTxt);
    line = dotMaker({
        class: 'dotSigmazg02',
        x1: centerX - limit - 70,
        y1: centerY - fl + 35,
        x2: centerX - limit - 70 + 0.5,
        y2: centerY - fl + 35,
        title: ``,
    });
    svg.appendChild(line);

    legendTxt = legendText({
        x: centerX - limit - 60,
        y: centerY - fl + 60,
        first: 'σ',
        second: 'zγ',
        className: 'textSigmazgamma',
    });
    svg.appendChild(legendTxt);
    line = dotMaker({
        class: 'dotSigmazgamma',
        x1: centerX - limit - 70,
        y1: centerY - fl + 55,
        x2: centerX - limit - 70 + 0.5,
        y2: centerY - fl + 55,
        title: ``,
    });
    svg.appendChild(line);
    // axios
    line = lineMaker({
        class: 'axiosLine',
        x1: centerX,
        y1: centerY - 50,
        x2: centerX,
        y2: centerY + soilsThickness * scale - nl + 50,
    });
    svg.appendChild(line);
    // soils line definition
    g = document.createElementNS(svgNS, 'g');
    temp = 0;
    tab.forEach(row => {
        temp += row.h * scale;
        line = lineMaker({
            class: 'soilLineDef',
            x1: centerX - draft.limit,
            y1: centerY - fl + temp,
            x2: centerX + draft.limit,
            y2: centerY - fl + temp,
        });
        g.appendChild(line);
    });
    svg.appendChild(g);
    // sigma graphs
    g = document.createElementNS(svgNS, 'g');
    temp = 'M ';
    tab.forEach(row => {
        line = dotMaker({
            class: 'dotSigmazp',
            x1: centerX + row.sigmazp * graphScale - 0.5,
            y1: centerY - fl + row.z * scale,
            x2: centerX + row.sigmazp * graphScale + 0.5,
            y2: centerY - fl + row.z * scale,
            title: row.sigmazp.toFixed(2),
        });
        g.appendChild(line);
        temp += `${centerX + row.sigmazp * graphScale} ${centerY - fl + row.z * scale} `;
    });
    path = pathMaker({ title: `σzp`, id: ``, class: 'lineSigmazp', d: temp });
    g.insertBefore(path, g.childNodes[0]);
    svg.appendChild(g);

    g = document.createElementNS(svgNS, 'g');
    temp = `M ${centerX - 0.5} ${centerY - nl} `;
    line = dotMaker({
        class: 'dotSigmazg',
        x1: centerX - 0.5,
        y1: centerY - nl,
        x2: centerX + 0.5,
        y2: centerY - nl,
        title: `0`,
    });
    g.appendChild(line);
    tab.forEach(row => {
        line = dotMaker({
            class: 'dotSigmazg',
            x1: centerX + row.sigmazg * graphScale * -1 - 0.5,
            y1: centerY - fl + row.z * scale,
            x2: centerX + row.sigmazg * graphScale * -1 + 0.5,
            y2: centerY - fl + row.z * scale,
            title: row.sigmazg.toFixed(2),
        });
        g.appendChild(line);
        temp += `${centerX + row.sigmazg * graphScale * -1} ${centerY - fl + row.z * scale} `;
    });
    path = pathMaker({ title: `σzg`, id: ``, class: 'lineSigmazg', d: temp });
    g.insertBefore(path, g.childNodes[0]);
    svg.appendChild(g);

    g = document.createElementNS(svgNS, 'g');
    temp = `M ${centerX - 0.5} ${centerY - nl} `;
    line = dotMaker({
        class: 'dotSigmazg02',
        x1: centerX - 0.5,
        y1: centerY - nl,
        x2: centerX + 0.5,
        y2: centerY - nl,
        title: `0`,
    });
    tab.forEach(row => {
        line = dotMaker({
            class: 'dotSigmazg02',
            x1: centerX + row.sigmazg * graphScale * 0.5 - 0.5,
            y1: centerY - fl + row.z * scale,
            x2: centerX + row.sigmazg * graphScale * 0.5 + 0.5,
            y2: centerY - fl + row.z * scale,
            title: (row.sigmazg * 0.5).toFixed(2),
        });
        g.appendChild(line);
        temp += `${centerX + row.sigmazg * graphScale * 0.5} ${centerY - fl + row.z * scale} `;
    });
    path = pathMaker({ title: `0.2σzg`, id: ``, class: 'lineSigmazg02', d: temp });
    g.insertBefore(path, g.childNodes[0]);
    svg.appendChild(g);

    g = document.createElementNS(svgNS, 'g');
    temp = 'M ';
    tab.forEach(row => {
        line = dotMaker({
            class: 'dotSigmazgamma',
            x1: centerX + row.sigmazgamma * graphScale * 0.5 - 0.5,
            y1: centerY - fl + row.z * scale,
            x2: centerX + row.sigmazgamma * graphScale * 0.5 + 0.5,
            y2: centerY - fl + row.z * scale,
            title: row.sigmazgamma.toFixed(2),
        });
        g.appendChild(line);
        temp += `${centerX + row.sigmazgamma * graphScale * 0.5} ${centerY - fl + row.z * scale} `;
    });
    path = pathMaker({ title: `σzγ`, id: ``, class: 'lineSigmazgamma', d: temp });
    g.insertBefore(path, g.childNodes[0]);
    svg.appendChild(g);

    return '';
};
