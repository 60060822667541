import { UserAction } from "../types";

const initialState = {
  authenticated: false,
  credentials: {},
};

// TODO remove any
export default function(state = initialState, action: any) {
  switch (action.type) {
    case UserAction.SetAuth:
      return {
        ...state,
        authenticated: true
      };
    case UserAction.SetUnAuth:
      return initialState;
    case UserAction.SetUser:
      return {
        authenticated: true,
        credentials: action.payload
      };
    default:
      return state;
  }
}
