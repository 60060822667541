import { ObliqueMarker } from '../../models/draft/marker.model';
import { svgNS } from '../draft/default.draft';

export const getObliqueMarker = (id: ObliqueMarker): SVGMarkerElement => {
    const marker: SVGMarkerElement = document.createElementNS(svgNS, 'marker');
    marker.setAttribute('class', 'dimLine');
    marker.setAttribute('id', `${id}`);
    marker.setAttribute('viewBox', '0 0 10 10');
    marker.setAttribute('refX', '5');
    marker.setAttribute('refY', '5');
    marker.setAttribute('markerWidth', '10');
    marker.setAttribute('markerHeight', '10');

    let line = document.createElementNS(svgNS, 'line');
    line.setAttribute('class', 'dimLine');
    line.setAttribute('x1', '0');
    line.setAttribute('y1', id === ObliqueMarker.obliqueV ? '0' : '10');
    line.setAttribute('x2', '10');
    line.setAttribute('y2', id === ObliqueMarker.obliqueV ? '10' : '0');
    marker.appendChild(line);

    line = document.createElementNS(svgNS, 'line');
    line.setAttribute('class', 'dimLine');
    line.setAttribute('x1', '5');
    line.setAttribute('y1', '0');
    line.setAttribute('x2', '5');
    line.setAttribute('y2', '10');
    marker.appendChild(line);

    return marker;
};
