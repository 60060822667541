import { Report } from 'client/src/models/report.model';
import { AppDispatch } from '../store';
import { TaskAction, TaskListAction } from '../types';

export interface TaskInputChangeAP {
    type: TaskAction | TaskListAction;
    payload: {
        key: string;
        name?: string;
        index?: number;
        value?: number;
        isTaskNew?: boolean;
        report?: Report[];
    };
}

export const taskInputChange =
    (key: string, name: string, value: number, isTaskNew: boolean) => (dispatch: AppDispatch) => {
        dispatch({ type: TaskAction.InputChange, payload: { key, name, value, isTaskNew } });
    };

export const taskAddRow = (key: string, isTaskNew: boolean) => (dispatch: AppDispatch) => {
    dispatch({ type: TaskAction.AddRow, payload: { key, isTaskNew } });
};

export const taskDeleteRow = (key: string, index: number, isTaskNew: boolean) => (dispatch: AppDispatch) => {
    dispatch({ type: TaskAction.DeleteRow, payload: { key, index, isTaskNew } });
};

export const taskTableChange =
    (key: string, name: string, index: number, value: number, isTaskNew: boolean) => (dispatch: AppDispatch) => {
        dispatch({ type: TaskAction.TableChange, payload: { key, name, index, value, isTaskNew } });
    };

export const taskColorChange = (index: number, isTaskNew: boolean) => (dispatch: AppDispatch) => {
    dispatch({ type: TaskAction.ColorChange, payload: { index, isTaskNew } });
};

export const taskReset = (isTaskNew: boolean) => (dispatch: AppDispatch) => {
    dispatch({ type: TaskAction.Reset, payload: { isTaskNew } });
};
