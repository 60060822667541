import React, { Fragment } from 'react';

import { Report } from 'client/src/models/report.model';
import { CalcCriteria } from '../../../../models/calc-criteria.model';

import { Box } from '@material-ui/core';


const titles: Record<any, JSX.Element> = {
    [CalcCriteria.byPmid]: <Fragment>По среднему давлению (5.11<a href="#source01">[1]</a>): </Fragment>,
    [CalcCriteria.byPEdgeX]: <Fragment>По максимальному краевому давлению относительно оси X (5.11<a href="#source01">[1]</a>): </Fragment>,
    [CalcCriteria.bySeparationX]: <Fragment>По максимальному отрыву относительно оси X (п.5.6.26<a href="#source01">[1]</a>): </Fragment>,
    [CalcCriteria.byPEdgeY]: <Fragment>По максимальному краевому давлению относительно оси Y (5.11<a href="#source01">[1]</a>): </Fragment>,
    [CalcCriteria.bySeparationY]: <Fragment>По максимальному отрыву относительно оси Y (п.5.6.26<a href="#source01">[1]</a>): </Fragment>,
    [CalcCriteria.byPCorner]: <Fragment>По максимальному угловому давлению (5.12<a href="#source01">[1]</a>): </Fragment>,
    /*[CalcCriteria.bySeparationBoth]: <Fragment>По максимальному отрыву угла (п.5.6.26[1]): </Fragment>,*/
    [CalcCriteria.bySettlement]: <Fragment>По максимальной осадке (5.6<a href="#source01">[1]</a>): </Fragment>,
    [CalcCriteria.byWeakLayer]: <Fragment>По расчётному сопротивлению слоя меньшей прочности (5.9<a href="#source01">[1]</a>): </Fragment>,
    [CalcCriteria.byLeaningX]: <Fragment>По максимальному крену относительно оси X: </Fragment>,
    [CalcCriteria.byLeaningY]: <Fragment>По максимальному крену относительно оси Y: </Fragment>,
    // [CalcCriteria.byLeaningXY]: <Fragment>По расчётному сопротивлению слоя меньшей прочности (5.9<a href="#source01">[1]</a>): </Fragment>,
};

const UsageRatesRep = ({ report }: { report: Report }) => {
    const criterias: Record<string, number> = report.calcCriteria;
    return (
        criterias && (
            <article style={{ textAlign: 'center' }}>
                {Object.keys(criterias).map((key: string, index: number) =>
                    <Fragment key={`criteria${index}`}>
                        <Box display="flex" justifyContent="space-between">
                            <div>{titles[key]}</div>
                            <div style={{ marginRight: '30px', color: criterias[key] > 1 ? 'red' : 'black'  }}>{criterias[key].toFixed(3)}</div>
                        </Box>
                    </Fragment>
                )}
            </article>
        )
    );
};

export default UsageRatesRep;
