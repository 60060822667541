import React from 'react';

import theme from '../../util/theme';

// Components
import TabInput from './TabInput';
import SoilTooltip from './soils-tooltip.component';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

// Icons
import DeleteIcon from '@material-ui/icons/Delete';

const styles = {
  ...theme,
  addButton: {
    margin: 15
  }
};

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: 'rgba(149, 75, 180, 0.1)',
    color: theme.palette.secondary.contrastText,
    padding: 10,
    fontSize: '1rem'
  },
  body: {
    padding: 0,
    fontSize: '1rem',
    textAlign: 'right',
    '& input': {
      width: 30,
      fontSize: '1rem'
    }
  }
}))(TableCell);

const row = (element, index, soils, errors, handleChange, handleRemove, handleChangeColor) => {
  const error = errors?.wellSoils?.[index];

  return (
    <TableRow key={`wellr${index}`}>
      <StyledTableCell>
        <Select
          // disableUnderline={true}
          className="selectItem"
          name={'soil'}
          onChange={e => handleChange(e, index, "well")}
          value={element.soil}
          error={error?.soil ? true : false}
        >
          {soils.map((item, index) => (
            <MenuItem key={`menu${index}`} value={index}>
              {index + 1}
            </MenuItem>
          ))}
        </Select>
      </StyledTableCell>
      <StyledTableCell>
        <IconButton onClick={e => handleChangeColor(element.soil)}>
          {soils[element.soil] &&
            <SoilTooltip
              index={element.soil}
              data={soils[element.soil]}
              fragment={<div style={{ backgroundColor: element.soil || element.soil === 0 ?
              soils[element.soil].color : 'none' }}>&nbsp;&nbsp;</div>}
            />}
        </IconButton>
      </StyledTableCell>
      <StyledTableCell>
        <TabInput
          name={'thickness'}
          index={index}
          value={element.thickness}
          units={'м'}
          error={error?.thickness ? true : false}
          handleChange={e => handleChange(e, index, "well")}
        />
      </StyledTableCell>
      <StyledTableCell>
        <IconButton onClick={() => handleRemove(index, 'well')}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </StyledTableCell>
    </TableRow>
  );
};

const WellsTable = props => {
  const { data, soils, errors, handleChange, handleAdd, handleRemove, handleChangeColor } = props;
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Слой</StyledTableCell>
            <StyledTableCell>&nbsp;&nbsp;</StyledTableCell>
            <StyledTableCell>h, м</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((element, index) =>
            row(element, index, soils, errors,
                handleChange, handleRemove, handleChangeColor)
          )}
        </TableBody>
      </Table>
      <br />
      <Button
        color="primary"
        variant="outlined"
        size="small"
        onClick={e => handleAdd(e, 'well')}
      >
        добавить
      </Button>
    </div>
  );
};

export default withStyles(styles)(WellsTable);
