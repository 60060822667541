import { Draft } from 'client/src/models/draft/draft.model';
import { Task } from 'client/src/models/task.model';
import { draftDefault } from './default.draft';

export const getDraft = (
    data: Task,
    index: number = 0,
    realSize: boolean,
    centerXEd?: number,
    centerYEd?: number
): Draft => {
    const dataset = draftDefault();

    dataset.centerX = centerXEd || dataset.centerX;
    dataset.centerY = centerYEd || dataset.centerY;

    const { centerX, centerY, scale } = dataset;

    dataset.levels.nl = data.levels.NL ? Number(data.levels.NL) * scale : dataset.levels.nl;
    dataset.levels.dl = data.levels.DL ? Number(data.levels.DL) * scale : dataset.levels.dl;
    dataset.levels.cl = data.levels.CL ? Number(data.levels.CL) * scale : dataset.levels.cl;
    dataset.levels.wl = data.levels.WL ? Number(data.levels.WL) * scale : dataset.levels.wl;
    dataset.levels.al = data.levels.AL ? Number(data.levels.AL) * scale : dataset.levels.al;
    dataset.levels.fl =
        data.report && data.report[index]?.newFL
            ? Number(data.report[index].newFL) * scale
            : data.levels.FL
            ? Number(data.levels.FL) * scale
            : dataset.levels.fl;

    const { cl, fl } = dataset.levels;

    dataset.foundation.column = data.general.bColumn ? data.general.bColumn * scale : dataset.foundation.column;
    dataset.foundation.height = Math.abs(fl - cl);

    if (realSize && data.report) {
        dataset.foundation.footing = data.report[index].result.b * 100;
    } else {
        dataset.foundation.footing = 180; // @@@ Interesting
    }
    const { column, hStep, footing } = dataset.foundation;

    let body: string;
    body = `${centerX} ${centerY - cl} ${centerX + column / 2} ${centerY - cl} ${centerX + column / 2} ${
        centerY - fl - hStep
    } `;
    body += `${centerX + footing / 2} ${centerY - fl - hStep} ${centerX + footing / 2} ${centerY - fl} ${centerX} ${
        centerY - fl
    }`;

    dataset.bodyCode = body;
    const rec1 = {
            up: centerY - cl,
            down: centerY - fl - hStep,
            left: -column / 2 + centerX,
            right: column / 2 + centerX,
        },
        rec2 = {
            up: rec1.down,
            down: rec1.down + hStep,
            left: -footing / 2 + centerX,
            right: footing / 2 + centerX,
        };

    dataset.rec1 = rec1;
    dataset.rec2 = rec2;

    return dataset;
};
