import { SoilHatch } from '../../models/draft/hatchs';
import { svgNS } from '../draft/default.draft';

export const sandHatch = (): SVGPatternElement => {
    let line: SVGLineElement;
    const g: SVGGElement = document.createElementNS(svgNS, 'g');
    const hatch: SVGPatternElement = document.createElementNS(svgNS, 'pattern');
    hatch.setAttribute('id', SoilHatch.sandHatch);
    hatch.setAttribute('width', '250px');
    hatch.setAttribute('height', '100px');
    hatch.setAttribute('patternUnits', 'userSpaceOnUse');

    for (let i = 0; i < 1000; i++) {
        const x: number = Math.floor(Math.random() * 250);
        const y: number = Math.floor(Math.random() * 100);
        line = document.createElementNS(svgNS, 'line');
        line.setAttribute('x1', `${x}`);
        line.setAttribute('y1', `${y}`);
        line.setAttribute('x2', `${x + 1}`);
        line.setAttribute('y2', `${y + 1}`);
        g.appendChild(line);
    }

    g.setAttribute('class', SoilHatch.sandHatch);

    hatch.appendChild(g);

    return hatch;
};
