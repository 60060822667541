import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import userReducer from './reducers/user.reducer';
import taskReducer from './reducers/task.reducer';
import uiReducer from './reducers/ui.reducer';
import adminReducer from './reducers/admin.reducer';
import newTask from './reducers/new-task.reducer';

const initialState = {};

const middleware = [thunk];

const reducers = combineReducers({
    admin: adminReducer,
    user: userReducer,
    tasks: taskReducer,
    newTask: newTask,
    UI: uiReducer
});

const store = createStore(
    reducers,
    initialState,
    compose(
        applyMiddleware(...middleware),
        ((window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()) || compose
    )
);

export default store;

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
