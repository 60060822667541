import React, { Fragment } from 'react';
import MathJax from 'react-mathjax';

import { eFormula, c0Formula, p1, p2 } from './formulas';

const PEdgeRep = ({ report, sepLimit }) => {
    const {
        result: { r, rMin, loadMx, loadMy, gammamt, l, b, n, wx, wy, a },
        d,
    } = report;
    const limit = rMin ? 0.25 : sepLimit || 0;
    const results = [report.result.pEdge.x, report.result.pEdge.y];

    return (
        <div>
            {results.map(
                (result, idx) =>
                    result && (
                        <Fragment>
                            <h4 style={{ textAlign: 'center' }}>Для момента относительно плоскости {idx ? 'Y' : 'X'}</h4>
                            Эксцентриситет вычисляется по формуле 5.14 <a href='#source01'>[1]</a>
                            <br />
                            <MathJax.Node formula={eFormula({ loadMx, loadMy, gammamt, l, b, n, d }, result)} />, где
                            &gamma;<sub>mt</sub> = 2 т/м
                            <sup>3</sup>
                            <br />
                            {result.sep.value < 0 ? (
                                <Fragment>
                                    Расстояние от точки приложения равнодействующей до края фундамента по его оси
                                    вычисляется по формуле 5.13 <a href='#source01'>[1]</a>
                                    <br />
                                    <MathJax.Node formula={c0Formula({ loadMx, loadMy, n, gammamt, l, b, d }, result)} />
                                    <div className='page-break'></div>
                                    Краевое давление вычисляется по формуле 5.12 <a href='#source01'>[1]</a>
                                    <br />
                                    <div
                                        className={
                                            result.pMax.value < r * 1.2 && result.pMid < r && result.sep.value > limit * 100
                                                ? 'reportResult'
                                                : 'reportResultNegative'
                                        }
                                    >
                                        Краевое давления:
                                        <br />
                                        <MathJax.Node formula={p2({ n, gammamt, l, b, r, d, limit }, result)} />
                                    </div>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <div className='page-break'></div>
                                    Краевое давление вычисляется по формуле 5.12 <a href='#source01'>[1]</a>
                                    <br />
                                    <div
                                        className={
                                            result.pMax.value < r * 1.2 && result.pMid < r && result.sep.value > limit * 100
                                                ? 'reportResult'
                                                : 'reportResultNegative'
                                        }
                                    >
                                        Краевое давления:
                                        <br />
                                        <MathJax.Node
                                            formula={p1({ n, gammamt, loadMx, loadMy, wx, wy, a, r, d, limit }, result)}
                                        />
                                    </div>
                                </Fragment>
                            )}
                        </Fragment>
                    )
            )}
        </div>
    );
};

export default PEdgeRep;
