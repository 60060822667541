import { DraftRect } from 'client/src/models/draft/draft.model';

export const twoRectangles = (
    centerX: number,
    limit: number,
    rec1: DraftRect,
    rec2: DraftRect,
    up: number,
    down: number,
    index: number,
    last: boolean
): string[] => {
    let path: string = '',
        lastPath: string = '';
    const paths: string[] = [];
    if (up >= rec2.up) {
        path = `M ${centerX + limit} ${up} ${rec2.right} ${up} ${rec2.right} ${down} ${centerX + limit} ${down} Z `;
        path += `M ${centerX - limit} ${up} ${rec2.left} ${up} ${rec2.left} ${down} ${centerX - limit} ${down} Z `;
        if (!last) {
            lastPath = `M ${centerX + limit} ${down} ${rec2.right} ${down} `;
            lastPath += `M ${centerX - limit} ${down} ${rec2.left} ${down} `;
            lastPath +=
                index === 0
                    ? `M ${centerX + limit} ${up} ${rec2.right} ${up} M ${centerX - limit} ${up} ${rec2.left} ${up}`
                    : ``;
        }
    } else {
        path = `M ${centerX + limit} ${up} ${rec1.right} ${up} ${rec1.right} ${down} ${centerX + limit} ${down} Z `;
        path += `M ${centerX - limit} ${up} ${rec1.left} ${up} ${rec1.left} ${down} ${centerX - limit} ${down} Z `;
        if (!last) {
            lastPath = `M ${centerX + limit} ${down} ${rec1.right} ${down} `;
            lastPath += `M ${centerX - limit} ${down} ${rec1.left} ${down} `;
            lastPath +=
                index === 0
                    ? `M ${centerX + limit} ${up} ${rec1.right} ${up} M ${centerX - limit} ${up} ${rec1.left} ${up}`
                    : ``;
        }
    }

    paths.push(path);

    if (lastPath) {
        paths.push(lastPath);
    }

    return paths;
};
