import React from 'react';

// Mui Stuff
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

interface StyledCheckbox {
  name: string;
  value: boolean;
  label: string;
  handleChange: (e: any) => void;
}

const NewCheckbox = (props: StyledCheckbox) => {
  const { name, value, label, handleChange } = props;

  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          color="primary"
          checked={value}
          onChange={handleChange}
        />
      }
      label={label}
    />
  );
};

export default NewCheckbox;
