import { ObliqueMarker } from '../../models/draft/marker.model';
import { svgNS } from '../draft/default.draft';

export const getVerticalDimension = (
    yUp: number,
    yDown: number,
    x: number,
    xOffset: number,
    dim: string,
    mirror?: boolean
): SVGGElement => {
    const k: number = mirror ? -1 : 1;
    const g: SVGGElement = document.createElementNS(svgNS, 'g');
    const text: SVGTextElement = document.createElementNS(svgNS, 'text');

    let line: SVGLineElement = document.createElementNS(svgNS, 'line');
    line.setAttribute('class', 'dimLine');
    line.setAttribute('x1', `${x}`);
    line.setAttribute('y1', `${yUp}`);
    line.setAttribute('x2', `${x + (xOffset + 10) * k}`);
    line.setAttribute('y2', `${yUp}`);
    g.appendChild(line);

    line = document.createElementNS(svgNS, 'line');
    line.setAttribute('class', 'dimLine');
    line.setAttribute('x1', `${x}`);
    line.setAttribute('y1', `${yDown}`);
    line.setAttribute('x2', `${x + (xOffset + 10) * k}`);
    line.setAttribute('y2', `${yDown}`);
    g.appendChild(line);

    line = document.createElementNS(svgNS, 'line');
    line.setAttribute('class', 'dimLine');
    line.setAttribute('x1', `${x + xOffset * k}`);
    line.setAttribute('y1', `${yUp}`);
    line.setAttribute('x2', `${x + xOffset * k}`);
    line.setAttribute('y2', `${yDown}`);
    line.setAttribute('marker-start', `url(#${ObliqueMarker.obliqueV})`);
    line.setAttribute('marker-end', `url(#${ObliqueMarker.obliqueV})`);
    g.appendChild(line);

    text.textContent = dim;
    text.setAttribute('class', 'dimText');
    text.setAttribute('x', `${x + (xOffset - 5 * k) * k}`);
    text.setAttribute('y', `${yUp + (yDown - yUp) / 2 + 25}`);
    text.setAttribute('transform', `rotate(-90, ${x + (xOffset - 5 * k) * k}, ${yUp + (yDown - yUp) / 2 + 25})`);
    g.appendChild(text);

    return g;
};
