import React from 'react';
import { Dialog, Button, DialogActions, DialogContent, Typography } from '@material-ui/core';

// interface CurrentProps {
//     message: string;
//     open: boolean;
//     handleLogin: () => void;
//     handleSingUp: () => void;
//     handleClose: () => void;
// }

const ConfirmDialog = (props /*: CurrentProps*/) => {
    const { message, open, handleClose, handleConfirm } = props;
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <Typography>{message}</Typography>
                <DialogActions>
                    <Button color='primary' variant='contained' onClick={handleConfirm}>
                        Да
                    </Button>
                    <Button autoFocus color='default' variant='contained' onClick={handleClose}>
                        Нет
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default ConfirmDialog;
