import React, { Fragment } from 'react';

const settlementTabHeader = [
  {title: '#', field: 'color'},
  {title: 'Вода', field: 'water'},
  {title: 'Cлой', field: 'soil'},
  {title: 'Отм.', field: 'level'},
  {title: (<Fragment>{'z'}<sub>{'i'}</sub>, м</Fragment>), field: 'z'},
  {title: (<Fragment>{'h'}<sub>{'i'}</sub>, м</Fragment>), field: 'h'},
  {title: (<Fragment>&gamma;<sub>{'i'}</sub>, {'т/м'}<sup>{'3'}</sup></Fragment>), field: 'gamma2'},
  {title: (<Fragment>{'E'}<sub>{'i'}</sub>, {'т/м'}<sup>{'2'}</sup></Fragment>), field: 'e'},
  {title: (<Fragment>{'E'}<sub>{'e,i'}</sub>, {'т/м'}<sup>{'2'}</sup></Fragment>), field: 'ee'},
  {title: (<Fragment>&zeta;</Fragment>), field: 'zeta'},
  {title: (<Fragment>&alpha;</Fragment>), field: 'alpha'},
  {title: (<Fragment>u<sub>{'z'}</sub>, { 'т/м'}<sup>{'2'}</sup></Fragment>), field: 'uz'},
  {title: (<Fragment>&sigma;<sub>{'zg,i'}</sub>, { 'т/м'}<sup>{'2'}</sup></Fragment>), field: 'sigmazg'},
  {title: (<Fragment>&sigma;<sub>z&gamma;,i</sub>, { 'т/м'}<sup>{'2'}</sup></Fragment>), field: 'sigmazgamma'},
  {title: (<Fragment>&sigma;<sub>zp,i</sub>, { 'т/м'}<sup>{'2'}</sup></Fragment>), field: 'sigmazp'},
  {title: (<Fragment>&sigma;<sub>zp,i</sub> / &sigma;<sub>zg,i</sub></Fragment>), field: 'zpzg'},
  {title: (<Fragment>s, мм</Fragment>), field: 's'},
];

export default settlementTabHeader;
