import React, { useEffect, useState, WheelEvent } from 'react';

import './draft.css';

//import draft from "../util/draft";
import draftRender from '../../util/draft-render';
//import settlementRender from "../util/settlement.render";
import theme from '../../util/theme';

// Components
import WellsTable from '../helpers/WellsTable';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { Task } from 'client/src/models/task.model';

const styles = {
    ...theme,
    canvasDiv: {
        display: 'flex',
        alignItem: 'baseline',
        justifyContent: 'center',
        margin: 'auto',
        textAlign: 'center',
    },
    wellColumn: {
        marginLeft: 20,
    },
    draftMain: {
        maxHeight: '75vh',
        //maxWidth: '40vw'
    },
};

const Draft = ({
    classes,
    data,
    errors,
    handleChange,
    handleAdd,
    handleRemove,
    handleChangeColor,
}: {
    classes: any;
    data: Task;
    errors: Record<string, string>;
    handleChange: (e: MouseEvent, index: number, key: number) => void;
    handleAdd: (e: MouseEvent, key: number) => void;
    handleRemove: (indxe: number, key: number) => void;
    handleChangeColor: (index: number) => void;
}) => {
    const [error, setError] = useState('');

    useEffect(() => {
        const err = draftRender(data, 'draft');
        if (err) {
            setError(err);
        }
    }, [data]);

    const handleWheel = (event: WheelEvent<SVGSVGElement>) => {
        //draft.onScale(e);
    };

    return (
        <div className={classes.drawList}>
            <Typography variant='subtitle1'>Эскиз</Typography>
            <div className={classes.canvasDiv}>
                <div className={classes.draftSVG}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        id='draft'
                        className={classes.draftMain}
                        width='600'
                        height='600'
                        viewBox='0 0 600 600'
                        onWheel={handleWheel}
                    >
                        Ваш браузер не поддерживает svg
                    </svg>
                    {error ? (
                        <Typography variant='body2' className={classes.taskDataError}>
                            {error}
                        </Typography>
                    ) : null}
                </div>
                <div className={classes.wellColumn}>
                    <Typography variant='subtitle1'>Скважина</Typography>
                    <WellsTable
                        data={data.well}
                        soils={data.soilsData}
                        errors={errors}
                        handleChange={handleChange}
                        handleAdd={handleAdd}
                        handleRemove={handleRemove}
                        handleChangeColor={handleChangeColor}
                    />
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles as any)(Draft as any);
