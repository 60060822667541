import { SoilHatch } from '../models/draft/hatchs';
import { ObliqueMarker } from '../models/draft/marker.model';
import { Task } from '../models/task.model';

import { draftDefault, svgNS } from './draft/default.draft';
import { getDraft } from './draft/get-draft.draft';
import { getLinePath } from './draft/get-line-path.draft';
import { getPath } from './draft/get-path.draft';
import { getWaterRectangle } from './draft/get-water-rectangle.draft';

import soilsDecoder from './soils-decoder';

import { clayHatch } from './svg/clay-hatch.svg';
import { grid } from './svg/grig.svg';
import { getHorizontalDimension } from './svg/horizontal-dimension.svg';
import { levelMark } from './svg/level-mark.svg';
import { lineMaker } from './svg/line-maker.svg';
import { getObliqueMarker } from './svg/oblique-marker.svg';
import { pathMaker } from './svg/path.svg';
import { polyMaker } from './svg/polyline.svg';
import { sandHatch } from './svg/sand-hatch.svg';
import { trashHatch } from './svg/trash-hatch.svg';
import { getVerticalDimension } from './svg/vertical-dimension.svg';

export const printDraft = (data: Task, id: string, index: number): string => {
    const svg: SVGElement | null = document.querySelector(`#${id}`);
    if (!svg) {
        return 'SVG элемент не найден';
    }

    if (!data.report) {
        return 'Отчёт отсутствует';
    }

    const dataset = draftDefault();
    const defs: SVGDefsElement = document.createElementNS(svgNS, 'defs');
    const { well, soilsData } = data;
    const { scale } = dataset;

    const newLimit = (data.report[index].result.b * scale) / 2 + 40;
    const limit = newLimit > dataset.limit ? newLimit : dataset.limit;
    const soilsThickness = data.well.reduce((total, item) => total + Number(item.thickness), 0);
    const zSize = (data.report[index].soilUnder.values.z + (data.levels.CL - data.levels.NL)) * scale + 150 + 35;
    const newHeight = Math.max(Math.max(soilsThickness * scale + 150 + 35, zSize), 600);
    const newWidth = Math.max(2 * (newLimit + 165), 600);
    const newSize = Math.max(newHeight, newWidth);

    if (newWidth > newHeight) {
        svg.setAttribute('viewBox', `0 0 ${newWidth} ${newHeight}`);
        const trueScale = newSize / 600;
        svg.setAttribute('width', `${newWidth / trueScale}`);
        svg.setAttribute('height', `${newHeight / trueScale}`);
    } else {
        svg.setAttribute('viewBox', `0 0 ${newSize} ${newSize}`);
    }

    const centerX = newSize > 600 ? newSize / 2 : 300;
    const centerY = newSize > 600 ? (150 * 600) / newSize : 150;
    const draft = getDraft(data, index, true, centerX, centerY);
    draft.limit = limit;

    const { bodyCode } = draft;
    const { nl, dl, cl, fl, wl, al } = draft.levels;
    const { footing, column } = draft.foundation;
    const { offset } = draft.levelMark;
    const { weakLayer } = data.report[index];

    let line, path, pathBg, temp, g, title, dimension;

    svg.innerHTML = ''; // cleanup
    // Prepearing defs
    defs.appendChild(trashHatch());
    defs.appendChild(clayHatch());
    defs.appendChild(getObliqueMarker(ObliqueMarker.obliqueV));
    defs.appendChild(getObliqueMarker(ObliqueMarker.obliqueH));
    svg.appendChild(defs);

    if (draft.sandToggle) {
        // @@@ This condition is very interesting
        defs.appendChild(draft.sandHatch);
    } else {
        draft.sandHatch = sandHatch();
        defs.appendChild(draft.sandHatch);
        draft.sandToggle = true;
    }
    // Add to svg
    // Grid
    svg.appendChild(grid(newSize, newSize, draft));
    // Body
    svg.appendChild(
        polyMaker({
            title: 'Фундамент',
            id: 'fBody1',
            class: 'fBodyResult',
            points: bodyCode,
        })
    );
    temp = polyMaker({
        title: 'Фундамент',
        id: 'fBody2',
        class: 'fBodyResult',
        points: bodyCode,
    });
    temp.setAttribute('transform', `scale(-1, 1) translate(${-centerX * 2},0)`);
    svg.appendChild(temp);

    temp = footing / 2 + 80 < limit ? limit : limit + (footing / 2 + 80 - limit);
    // Soils
    draft.soils.push(centerY - nl);
    for (let i = 0; i < well.length; i++) {
        if (!well[i].thickness || (!well[i].soil && well[i].soil !== 0)) {
            continue;
        }
        g = document.createElementNS(svgNS, 'g');
        g.setAttribute('id', `soilGroup${i + 1}`);

        const { paths, down } = getPath(draft, well[i].thickness, i, draft.soils[i], i === well.length - 1);
        draft.soils.push(down);

        path = pathMaker({ title: ``, id: ``, class: '', d: paths[0] });
        if (soilsData[well[i].soil].type === 'clay') {
            path.setAttribute('fill', `url(#${SoilHatch.clayHatch})`);
        } else {
            path.setAttribute('fill', `url(#${SoilHatch.sandHatch})`);
        }
        g.appendChild(path);

        title = `Слой ${well[i].soil + 1}\n`;
        title += `${soilsDecoder(soilsData[well[i].soil].type)} ${soilsDecoder(
            soilsData[well[i].soil].structure
        )} ${soilsDecoder(soilsData[well[i].soil].moisture)}\n`;
        title += `${soilsData[well[i].soil].il ? 'Iₗ=' + soilsData[well[i].soil].il + '; ' : ''}E=${
            soilsData[well[i].soil].E
        } т/м²; e=${soilsData[well[i].soil].e}\n`;
        title += `C₂=${soilsData[well[i].soil].c2}т/м²; φ₂=${soilsData[well[i].soil].phi2}°; γ₂=${
            soilsData[well[i].soil].gamma2
        }т/м³\n`;
        title += `γₛ=${soilsData[well[i].soil].gammas}т/м³\n`;
        pathBg = pathMaker({ title: title, id: `soil${i}`, class: 'soils', d: paths[0] });
        pathBg.setAttribute('fill', soilsData[well[i].soil].color);
        g.appendChild(pathBg);
        if (paths[1]) {
            path = pathMaker({
                title: ``,
                id: ``,
                class: 'secondaryLine',
                d: paths[1],
            });
            g.appendChild(path);
        }
        g.setAttribute('fill', soilsData[data.well[i].soil].color);

        // levels
        if (i < well.length - 1) {
            const level = down - draft.soils[0] - nl;
            line = lineMaker({
                class: 'dimLine',
                x1: centerX - temp,
                y1: centerY + level,
                x2: centerX - temp - 40,
                y2: centerY + level,
            });
            svg.appendChild(
                levelMark(
                    centerX - temp - 30,
                    centerY + level,
                    level / -scale,
                    '',
                    true,
                    true,
                    line,
                    draft.levelMark
                )
            );
        }

        // add wl if we have weak layer @@@ WL - is water line not weak layer
        const wlMarker = document.createElementNS(svgNS, 'g');
        if (weakLayer && weakLayer.report.soilUnder.idx === well[i].soil) {
            const circle = document.createElementNS(svgNS, 'circle');
            circle.setAttribute('cx', `${centerX}`);
            circle.setAttribute('cy', `${centerY - (weakLayer.level - well[i].thickness / 2) * scale - 15}`);
            circle.setAttribute('r', '15');
            circle.setAttribute('class', 'dimLine');
            const wlText = document.createElementNS(svgNS, 'text');
            wlText.setAttribute('x', `${centerX - 14}`);
            wlText.setAttribute('y', `${centerY - (weakLayer.level - well[i].thickness / 2) * scale - 10}`);
            wlText.setAttribute('class', 'dimText');
            wlText.textContent = 'WL';
            wlMarker.appendChild(circle);
            wlMarker.appendChild(wlText);
        }
        g.appendChild(wlMarker);
        svg.appendChild(g);
    }

    // CL
    line = lineMaker({
        class: 'dimLine',
        x1: centerX + limit,
        y1: centerY - cl,
        x2: centerX + limit + 40,
        y2: centerY - cl,
    });
    svg.appendChild(
        levelMark(centerX + limit + 30, centerY - cl + offset, cl / 100, 'CL', false, false, line, draft.levelMark)
    );

    // DL
    if (dl !== nl) {
        path = pathMaker({
            title: ``,
            id: ``,
            class: 'secondaryLine dlLine',
            d: getLinePath(centerY - dl, column, draft),
        });
        svg.appendChild(path);
        line = lineMaker({
            class: 'dimLine',
            x1: centerX - temp,
            y1: centerY - dl,
            x2: centerX - temp - 40,
            y2: centerY - dl,
        });
        svg.appendChild(
            levelMark(
                centerX - temp - 30,
                centerY - dl + (dl > nl ? offset : -offset),
                dl / 100,
                'DL',
                dl > nl ? false : true,
                true,
                line,
                draft.levelMark
            )
        );
    }

    // NL
    line = lineMaker({
        class: 'dimLine',
        x1: centerX - temp,
        y1: centerY - nl,
        x2: centerX - temp - 40,
        y2: centerY - nl,
    });
    svg.appendChild(
        levelMark(
            centerX - temp - 30,
            centerY - nl - (dl > nl ? offset : -offset),
            nl / 100,
            dl !== nl ? 'NL' : 'DL,NL',
            dl > nl ? true : false,
            true,
            line,
            draft.levelMark
        )
    );

    // FL
    line = lineMaker({
        class: 'dimLine',
        x1: centerX + limit,
        y1: centerY - fl,
        x2: centerX + limit + 40,
        y2: centerY - fl,
    });
    svg.appendChild(
        levelMark(
            centerX + limit + 30,
            centerY - fl + offset,
            fl / 100,
            'FL',
            wl ? fl < wl : false,
            false,
            line,
            draft.levelMark
        )
    );

    // WL && AL
    if (wl !== undefined) {
        path = pathMaker({
            title: ``,
            id: ``,
            class: 'secondaryLine wlLine',
            d: getLinePath(centerY - wl, column, draft, true),
        });
        svg.appendChild(path);

        line = lineMaker({
            class: 'dimLine',
            x1: centerX + column * 2,
            y1: centerY - wl,
            x2: centerX + temp + 40,
            y2: centerY - wl,
        });
        svg.appendChild(
            levelMark(
                centerX + limit + 30,
                centerY - wl + offset,
                wl / 100,
                'WL',
                fl > wl,
                false,
                line,
                draft.levelMark
            )
        );

        if (al !== undefined) {
            path = pathMaker({
                title: ``,
                id: ``,
                class: 'secondaryLine wlLine',
                d: getLinePath(centerY - al, column, draft, true),
            });
            svg.appendChild(path);

            line = lineMaker({
                class: 'dimLine',
                x1: centerX + column * 2,
                y1: centerY - al,
                x2: centerX + temp + 40,
                y2: centerY - al,
            });
            svg.appendChild(
                levelMark(
                    centerX + limit + 30,
                    centerY - al + offset,
                    al / 100,
                    'AL',
                    true,
                    false,
                    line,
                    draft.levelMark
                )
            );
        }

        const bottomBorder = Math.min(al ? centerY - al : Infinity, centerY - nl + soilsThickness * scale);
        path = pathMaker({
            title: ``,
            id: ``,
            class: 'waterBg',
            d: getWaterRectangle(draft, wl, bottomBorder),
        });
        svg.appendChild(path);
    }

    // down of soils
    line = lineMaker({
        class: 'dimLine',
        x1: centerX - temp,
        y1: centerY - nl + soilsThickness * scale,
        x2: centerX - temp - 40,
        y2: centerY - nl + soilsThickness * scale,
    });
    svg.appendChild(
        levelMark(
            centerX - temp - 30,
            centerY - nl + soilsThickness * scale + offset,
            (nl - soilsThickness * scale) / 100,
            '',
            false,
            true,
            line,
            draft.levelMark
        )
    );

    // thickness z
    line = lineMaker({
        class: 'zLine',
        x1: centerX - draft.limit,
        y1: centerY - fl + data.report[index].soilUnder.values.z * scale,
        x2: centerX + draft.limit,
        y2: centerY - fl + data.report[index].soilUnder.values.z * scale,
    });
    svg.appendChild(line);
    dimension = getVerticalDimension(
        centerY - fl,
        centerY - fl + data.report[index].soilUnder.values.z * scale,
        centerX + (footing * 1.1) / 2,
        50,
        `z=${(data.report[index].soilUnder.values.z * 1000).toFixed(0)}`
    );
    svg.appendChild(dimension);

    // b
    dimension = getHorizontalDimension(
        centerX - footing / 2,
        centerX + footing / 2,
        centerY - fl + 10,
        50,
        `b=${(data.report[index].result.b * 1000).toFixed(0)}`
    );
    svg.appendChild(dimension);

    // d
    dimension = getVerticalDimension(
        centerY - dl,
        centerY - fl,
        centerX - footing / 2 - 10,
        40,
        `d=${(data.report[index].d.value * 1000).toFixed(0)}`,
        true
    );
    svg.appendChild(dimension);

    // dn
    dimension = getVerticalDimension(
        centerY - nl,
        centerY - fl,
        centerX - footing / 2 - 10,
        70,
        `dn=${(data.report[index].dn.value * 1000).toFixed(0)}`,
        true
    );
    svg.appendChild(dimension);

    return '';
};
