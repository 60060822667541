import { LevelMark } from 'client/src/models/draft/draft.model';
import { svgNS } from '../draft/default.draft';

export const levelMark = (
    x: number,
    y: number,
    upNote: number | string,
    downNote: number | string,
    down: boolean,
    left: boolean,
    line: SVGLineElement,
    levelMark: LevelMark
): SVGGElement => {
    // x,y - down of arrow
    let d: string;
    const k: number = down ? -1 : 1;
    const j: number = left ? -1 : 1;
    const g: SVGGElement = document.createElementNS(svgNS, 'g');
    const arrowPath: SVGPathElement = document.createElementNS(svgNS, 'path');
    const linePath: SVGPathElement = document.createElementNS(svgNS, 'path');
    const upText: SVGTextElement = document.createElementNS(svgNS, 'text');
    const downText: SVGTextElement = document.createElementNS(svgNS, 'text');
    const { arrowWidth, arrowHeight, height, length, textSize } = levelMark;

    d = `M ${x - arrowWidth} ${y - arrowHeight * k} ${x} ${y} ${x + arrowWidth} ${y - arrowHeight * k}`;
    arrowPath.setAttribute('class', 'dimArrow');
    arrowPath.setAttribute('d', d);

    d = `M ${x} ${y} L ${x} ${y - height * k} ${x + length * j} ${y - height * k}`;
    linePath.setAttribute('class', 'dimLine');
    linePath.setAttribute('d', d);

    upText.textContent = Number(upNote) > 0 ? '+' : '';
    upText.textContent += Number(upNote).toFixed(3);
    upText.setAttribute('class', 'levelMarkText');
    upText.setAttribute('x', `${left ? x - length : x + 5}`);
    upText.setAttribute('y', `${down ? y + height * 0.8 : y - height * 1.2}`);

    downText.textContent = `${downNote}`;
    downText.setAttribute('class', 'levelMarkText');
    downText.setAttribute('x', `${left ? x - length : x + 5}`);
    downText.setAttribute('y', `${y - height * k + textSize * 1.7}`);

    g.setAttribute('class', 'levelMark');
    line && g.appendChild(line);
    g.appendChild(upText);
    g.appendChild(downText);
    g.appendChild(linePath);
    g.appendChild(arrowPath);

    return g;
};
