import React from 'react';
import PropTypes from 'prop-types';

import theme from '../util/theme';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux Stuff
import { connect } from 'react-redux';

const styles = {
    ...theme,
    fieldsList: {
        flexDirection: 'column'
    }
};

class InputPanel extends React.Component {
    constructor() {
        super();
        this.state = {
            errors: {}
        };
    }

    componentDidMount() {
        if (this.props.UI.errors) {
            this.setState({ errors: this.props.UI.errors });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.UI.errors) {
            this.setState({ errors: nextProps.UI.errors });
        } else {
            this.setState({ errors: {} });
        }
    }

    render() {
        const {
            classes,
            UI: { loading },
            taskChanged,
            reportDone,
            batchCalc,
            isTaskNew
        } = this.props;
        const { errors } = this.state;

        return (
            <Paper
                className={classes.paperSheet}
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
            >
                <List className={classes.taskList}>
                    <ListItem
                        className={classes.listItem}
                        button
                        onClick={e => this.props.handlePanelClick(e, 'general')}
                    >
                        <ListItemText
                            primary='Общие данные'
                            secondary={
                                (errors.title || errors.general) && (
                                    <Typography variant='body2' className={classes.taskDataError}>
                                        {errors.title ?? errors.general}
                                    </Typography>
                                )
                            }
                        />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={e => this.props.handlePanelClick(e, 'soils')}>
                        <ListItemText
                            primary='Слои грунта'
                            secondary={
                                errors.soils && (
                                    <Typography variant='body2' className={classes.taskDataError}>
                                        {errors.soilsData}
                                    </Typography>
                                )
                            }
                        />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={e => this.props.handlePanelClick(e, 'levels')}>
                        <ListItemText
                            primary='Отметки'
                            secondary={
                                errors.levels && (
                                    <Typography variant='body2' className={classes.taskDataError}>
                                        {errors.levels}
                                    </Typography>
                                )
                            }
                        />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={e => this.props.handlePanelClick(e, 'loads')}>
                        <ListItemText
                            primary='Нагрузки'
                            secondary={
                                errors.loads ? (
                                    <Typography variant='body2' className={classes.taskDataError}>
                                        {errors.loads}
                                    </Typography>
                                ) : null
                            }
                        />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={e => this.props.handlePanelClick(e, 'draft')}>
                        <ListItemText
                            primary='Эскиз'
                            secondary={
                                errors.well ? (
                                    <Typography variant='body2' className={classes.taskDataError}>
                                        {errors.well}
                                    </Typography>
                                ) : null
                            }
                        />
                    </ListItem>
                    <ListItem style={{ flexDirection: 'column', justifyContent: 'center' }}>
                        {window.location.href.split('/').reverse()[0] !== 'newtask' ? (
                            <ButtonGroup>
                                <Button
                                    color={taskChanged ? 'secondary' : 'primary'}
                                    variant={taskChanged ? 'contained' : 'outlined'}
                                    size='small'
                                    onClick={this.props.handleUpdate}
                                    disabled={loading}
                                >
                                    Сохранить изменения
                                </Button>
                                <Button
                                    color='primary'
                                    variant='outlined'
                                    size='small'
                                    onClick={this.props.handlePost}
                                    disabled={loading}
                                >
                                    Сохранить как новую задачу
                                </Button>
                            </ButtonGroup>
                        ) : (
                            <ButtonGroup>
                                <Button
                                    color={taskChanged ? 'secondary' : 'primary'}
                                    variant={taskChanged ? 'contained' : 'outlined'}
                                    size='small'
                                    onClick={this.props.handlePost}
                                    disabled={loading}
                                >
                                    Сохранить новую <br /> задачу
                                </Button>
                                <Button
                                    style={{
                                        color: 'red',
                                        borderColor: 'red'
                                    }}
                                    variant='outlined'
                                    size='small'
                                    onClick={this.props.handleResetTask}
                                    disabled={loading}
                                >
                                    Обнулить <br /> задачу
                                </Button>
                            </ButtonGroup>
                        )}
                        {taskChanged ? (
                            <Typography variant='subtitle1' component='div' className={classes.taskDataError}>
                                Есть несохранённые изменения
                            </Typography>
                        ) : null}
                    </ListItem>
                    <ListItem style={{ justifyContent: 'center' }}>
                        <Button
                            color='primary'
                            variant='contained'
                            size='large'
                            onClick={this.props.handleSubmit}
                            disabled={loading}
                        >
                            Расчёт
                            {loading && <CircularProgress size={25} className={classes.progress} />}
                        </Button>
                    </ListItem>
                    <ListItem
                        button
                        disabled={/*taskChanged || */ !reportDone || batchCalc}
                        onClick={e => this.props.handlePanelClick(e, 'report')}
                    >
                        <ListItemText
                            primary='Отчёт'
                            secondary={
                                // Проблема с ошибкой, когда отчёт создан, а задача изменена и не сохранена
                                isTaskNew && reportDone && taskChanged && !batchCalc ? (
                                    <Typography variant='body2' className={classes.taskDataError}>
                                        {/* Сохраните изменения и  */}
                                        Отчёт устарел, сделайте расчёт
                                    </Typography>
                                ) : null
                            }
                        />
                    </ListItem>
                    {/*<ListItem button onClick={e => this.props.handlePanelClick(e, 'draw')}>
          <ListItemText primary="Чертёж" />
        </ListItem>*/}
                    <ListItem
                        button
                        disabled={taskChanged || !reportDone || !batchCalc}
                        onClick={e => this.props.handlePanelClick(e, 'analysis')}
                    >
                        <ListItemText
                            primary='Анализ'
                            secondary={
                                taskChanged && batchCalc ? (
                                    <Typography variant='body2' className={classes.taskDataError}>
                                        Сохраните изменения и сделайте расчёт
                                    </Typography>
                                ) : null
                            }
                        />
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ justifyContent: 'center' }}>
                        <Button
                            style={{
                                color: 'red',
                                borderColor: 'red',
                                fontSize: 'small',
                                marginBottom: '10px'
                            }}
                            color='primary'
                            variant='outlined'
                            size='large'
                            onClick={this.props.handleReportBug}
                            disabled={loading || taskChanged}
                        >
                            Сообщить об ошибке
                            {loading && <CircularProgress size={25} className={classes.progress} />}
                        </Button>
                    </ListItem>
                    <ListItem style={{ justifyContent: 'center', visibility: isTaskNew ? 'hidden' : 'visible' }}>
                        <Button
                            style={{
                                color: 'gray',
                                borderColor: 'gray',
                                fontSize: 'small',
                                marginBottom: '10px',
                                alignSelf: 'center'
                            }}
                            color='primary'
                            variant='outlined'
                            size='large'
                            onClick={this.props.handleResetTask}
                            disabled={loading}
                        >
                            Закрыть задачу
                            {loading && <CircularProgress size={25} className={classes.progress} />}
                        </Button>
                    </ListItem>
                </List>
            </Paper>
        );
    }
}

const mapStateToProps = state => ({
    UI: state.UI
});

InputPanel.propTypes = {
    handlePanelClick: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(withStyles(styles)(InputPanel));
