import React from 'react';

import theme from '../util/theme';

// Components
import SingleTask from './helpers/SingleTask';
import TasksSearchbar from './helpers/TasksSearchbar';
import ContextTaskMenu from './helpers/ContextTaskMenu';
import SimpleDialog from './helpers/SimpleDialog';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

// Redux Stuff
import { connect } from 'react-redux';
import { getTasksList } from '../redux/actions/task.actions';
import { deleteTask } from '../redux/actions/task.actions';

const styles = {
    ...theme
};

class TasksList extends React.Component {
    constructor() {
        super();
        this.state = {
            searchBar: '',
            tasks: [],
            descending: true,
            anchorEl: null,
            selectedIndex: 0,
            mouseX: null,
            mouseY: null,
            taskForDelete: {},
            open: false
        };
    }

    componentDidMount() {
        if (this.props.user.admin) {
            this.setState({ tasks: this.props.admin.allTasks });
        } else {
            this.setState({ tasks: this.props.task.tasks });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.user.credentials.admin) {
            const tasks = nextProps.admin.allTasks || [];
            const bugs = nextProps.admin.allBugs || [];
            const tasksAndBugs = [...tasks, ...bugs].sort(
                (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );

            this.setState({ tasks: tasksAndBugs });
        } else {
            this.setState({ tasks: nextProps.task.tasks });
        }
    }

    // Sort menu functions
    handleMenuClick = e => {
        this.setState({ anchorEl: e.currentTarget });
    };
    handleMenuItemClick = (e, index) => {
        switch (index) {
            case 0:
                this.sortTasks('createdAt');
                break;
            case 1:
                this.sortTasks('lastChange');
                break;
            case 2:
                this.sortTasks('title');
                break;
            default:
                break;
        }
        this.setState({
            selectedIndex: index,
            anchorEl: null
        });
    };
    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    // Contextmenu functions
    handleClick = (e, task) => {
        e.preventDefault();
        this.setState({
            mouseX: e.clientX - 2,
            mouseY: e.clientY - 4,
            taskForDelete: task
        });
    };
    handleClose = () => {
        this.setState({
            mouseX: null,
            mouseY: null
        });
    };

    // Dialog functions
    handleOpenDialog = () => {
        this.setState({ open: true });
        this.handleClose();
    };
    handleCloseDialog = () => {
        this.setState({ open: false });
    };
    handleAgreedDialog = () => {
        this.props.deleteTask(this.state.taskForDelete.taskId);
        this.handleCloseDialog();
    };

    // Searchbar functions
    sortTasks = key => {
        let tasks;
        if (key === 'title') {
            const getComplexTitle = (title = '', createdAt = '') => title.toLowerCase() + createdAt;
            tasks = this.state.tasks
                .map(x => getComplexTitle(x.title, x.createdAt))
                .sort()
                .map(y => this.state.tasks.find(z => getComplexTitle(z.title, z.createdAt) === y));
        } else {
            tasks = this.state.tasks.sort((a, b) => new Date(b[key]).getTime() - new Date(a[key]).getTime());
        }

        this.state.descending && tasks.reverse();
        this.setState({ tasks });
    };
    handleFilterToggle = () => {
        const value = this.state.tasks.reverse();
        const toggle = !this.state.descending;
        this.setState({
            tasks: [...value],
            descending: toggle
        });
    };
    handleChange = e => {
        const value = e.target.value;
        if (value) {
            const tasks = this.props.task.tasks.filter(task =>
                task.title.toLowerCase().includes(value.toLowerCase()) ? task : null
            );
            this.setState({ searchBar: value, tasks: tasks });
        } else {
            this.setState({ searchBar: value, tasks: this.props.task.tasks });
        }
    };
    handleRefreshTasksList = () => {
        this.setState({ selectedIndex: 0, descending: true, searchBar: '' });
        this.props.getTasksList(this.props.user.credentials.handle);
    };

    render() {
        const {
            classes,
            UI: { loading },
            user: {
                credentials: { userId }
            }
        } = this.props;

        const { tasks, anchorEl, selectedIndex, searchBar, descending } = this.state;
        return (
            <Paper className={classes.taskList}>
                <Typography variant='h6' noWrap>
                    Список сохранённых задач
                </Typography>
                <TasksSearchbar
                    anchorEl={anchorEl}
                    selectedIndex={selectedIndex}
                    loading={loading}
                    searchBar={searchBar}
                    descending={descending}
                    handleMenuClick={this.handleMenuClick}
                    handleClose={this.handleMenuClose}
                    handleMenuItemClick={this.handleMenuItemClick}
                    handleFilterToggle={this.handleFilterToggle}
                    handleChange={this.handleChange}
                    handleRefreshTasksList={this.handleRefreshTasksList}
                />
                <div className='task-list-container'>
                    <List>
                        {loading ? (
                            <div>...загрузка</div>
                        ) : tasks.length !== 0 ? (
                            tasks.map((task, index) => {
                                return (
                                    <div key={task.createdAt}>
                                        {index !== 0 ? <Divider /> : null}
                                        <SingleTask userId={userId} task={task} handleClick={this.handleClick} />
                                    </div>
                                );
                            })
                        ) : searchBar ? (
                            <div>...ничего не найдено</div>
                        ) : (
                            <div> У Вас нет сохранённых задач</div>
                        )}
                    </List>
                </div>
                <ContextTaskMenu
                    mouseX={this.state.mouseX}
                    mouseY={this.state.mouseY}
                    handleClose={this.handleClose}
                    handleOpenDialog={this.handleOpenDialog}
                />
                <SimpleDialog
                    task={this.state.taskForDelete}
                    open={this.state.open}
                    handleAgreed={this.handleAgreedDialog}
                    handleClose={this.handleCloseDialog}
                />
            </Paper>
        );
    }
}

const mapActionsToProps = {
    getTasksList,
    deleteTask
};

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user,
    task: state.tasks,
    UI: state.UI
});

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(TasksList));
