import React, { Fragment } from 'react';

import soilsDecoder from '../../util/soils-decoder';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import { Soil } from 'client/src/models/soil.model';

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const SoilTooltip = ({ index, data, fragment }: { index: number; data: Soil; fragment: JSX.Element }) => {
    return (
        <HtmlTooltip
            placement='right'
            title={
                <Fragment>
                    Слой {index + 1}
                    <br />
                    {soilsDecoder(data.type)} {soilsDecoder(data.structure)} {soilsDecoder(data.moisture)}
                    <br />
                    {data.il ? 'Iₗ=' + data.il + '; ' : ''}E={data.e} т/м²
                    <br />
                    C₂={data.c2}т/м²; φ₂={data.phi2}°; γ₂={data.gamma2}т/м³
                </Fragment>
            }
        >
            {fragment}
        </HtmlTooltip>
    );
};

export default SoilTooltip;
