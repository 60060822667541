import { Task } from "client/src/models/task.model";
import { LocalStorageTaskKey } from "../models/local-storage-task-key";
import { getDefaultTask } from "../util/default-task";

// const KEY = 'task';

export const saveToLocalStorage = (task: Task, durty: boolean, key: LocalStorageTaskKey) => {
    localStorage.setItem(key, JSON.stringify({ task, durty }));
}

export const getFromLocalStorage = (key: LocalStorageTaskKey): Task => {
    const newTask = localStorage.getItem(key);
    if (newTask) {
        return JSON.parse(newTask);
    } else {
        return key === LocalStorageTaskKey.NewTaks ? getDefaultTask() : {} as Task;
    }
}

export const resetStorage = (key: LocalStorageTaskKey) => {
    localStorage.removeItem(key);
}