import React, { ChangeEvent } from 'react';

import theme from '../../util/theme';
import SoilTable from './soil-table.component';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

const styles = {
    ...theme,
    addButton: {
        margin: 15
    }
};

// TODO any
const SoilsData = (props: any) => {
    // TODO same in all other components
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.handleChange('root', 'tableValues', e.target.checked);
    };

    const { classes, errors, data } = props;
    
    return (
        <div className={classes.soilsList}>
            <Typography variant='subtitle1'>Грунты</Typography>
            <SoilTable
                handleRemove={props.handleRemove}
                handleChange={props.handleTableChange}
                handleChangeColor={props.handleChangeColor}
                data={data}
                errors={errors}
                classes={classes}
            />
            <Button
                className={classes.addButton}
                color='primary'
                variant='outlined'
                size='small'
                onClick={e => props.handleAdd(e, 'soilsData')}
            >
                Добавить слой
            </Button>
            <div className={classes.inputGroup}>
                <FormControlLabel
                    control={
                        <Checkbox
                            name='tableValues'
                            color='primary'
                            checked={props.tableValues}
                            onChange={handleChange}
                        />
                    }
                    label='Значения приняты по таблицам СП'
                />
            </div>
        </div>
    );
};

export default withStyles(styles as any)(SoilsData);
