import React from 'react';

const tooltips: Record<string, string | JSX.Element> = {
    sepLimit: (
        <React.Fragment>
            {'Форма эпюры давления по подошве фундаментов'}
            <br />
            <b>{'0.25'}</b>
            {' - для зданий, оборудованных мостовыми кранами грузоподъёмностью '}
            {'75 т и выше, для открытых крановых эстакад при грузоподъемности кранов свыше 15 т, '}
            {'для сооружений башенного типа (труб, домен и др.), а также для всех видов сооружений '}
            {'при расчётном сопротивлении грунта основания R<15 т/м'}
            <sup>2</sup>
            <br />
            <b>{'0'}</b>
            {' - в остальных случаях для фундаментов зданий с мостовыми кранами'}
            <br />
            <b>{'- 0.25'}</b>
            {' - для бескрановых зданий с подвесным транспортным оборудование'}
            <br />
            <em>{'0 - по умолчанию'}</em>
        </React.Fragment>
    ),
    sep: (
        <React.Fragment>
            {'> 0 - трапециевидная форма эпюры'}
            <br />
            {'< 0 - треугольная форма эпюры, отрыв'}
            <br />
        </React.Fragment>
    ),
    lb: (
        <React.Fragment>
            {'Коэффициент отношение длины к ширине подошвы фундамента'}
            <br />
            <em>{'1.5 - по умолчанию'}</em>
        </React.Fragment>
    ),
    sizeModule: (
        <React.Fragment>
            {'Модуль кратности размеров подошвы фундамента'}
            <br />
            <em>{'0.3 м - по умолчанию'}</em>
        </React.Fragment>
    ),
    hp: (
        <React.Fragment>
            {'Толщина бетонной или щебёночной подготовки под подошвой фундамента. '}
            {'При наличии глубина заложения фундамента '}d<sub>1</sub>
            {' увеличивается на её толщину'}
            <br />
        </React.Fragment>
    ),
    column: 'Габарит подколонника по умолчанию 0.9 х 0.9 м',
    strongSchema:
        'К сооружениям с жесткой конструктивной схемой относят сооружения, ' +
        'конструкции которых специально приспособлены к восприятию усилий от деформации оснований',
    soilThickness: (
        <React.Fragment>
            {'Минимальная толщина i-го слоя грунта при вычислении осадки'}
            <br />
            {'Значение по умолчанию 0.4b'}
        </React.Fragment>
    ),
    variablePreparation: (
        <React.Fragment>
            {'Для фундаментов с промежуточной подготовкой переменной жесткости расчётное'}
            {'сопротивление грунта основания под бетонной частью вычисляют по формуле (5.7).'}
            {'При этом расчётное сопротивление грунта основания под бетонной частью фундамента'}
            {'принимают не менее 2R п.5.6.20 СП 22.13330.2016'}
        </React.Fragment>
    ),
    Ee: (
        <React.Fragment>
            {'Модуль деформации по ветви вторичного нагружения.'}
            <br />
            {'При отсутствии опытных определений принимается '}E<sub>e</sub> = 5 E<br />
            {'(допускается для сооружений геотехнических категорий 1 и 2)'}
        </React.Fragment>
    ),
    rmax: 'Ограничение давления на слой',
    NL: 'По умолчанию равна планировочной отметке земли',
    WL: 'По умолчанию вода отсутствует',
    AL: 'По умолчанию водоупор не вскрыт',
    AFL: 'Отметка границы, до которой, с отметки подошвы, выполнятеся расчёт с заданным шагом',
    smaxAnalysis: 'Расчётная осадка больше максимально допустимой',
};

export default tooltips;
