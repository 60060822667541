import React, { Fragment } from 'react';

const header: { field: string, title: string | JSX.Element }[] = [
    { title: '', field: 'color'},
    { title: 'Грунт', field: 'type' },
    { title: 'Структура', field: 'structure' },
    { title: 'Влажность', field: 'moisture' },
    { title: 'Iₗ', field: 'il' },
    { title: 'C₁, т/м²', field: 'c1' },
    { title: 'φ₁, град', field: 'phi1' },
    { title: 'C₂, т/м²', field: 'c2' },
    { title: 'φ₂, град', field: 'phi2' },
    { title: 'γ₂, т/м³', field: 'gamma2' },
    { title: (<Fragment>{'γ'}<sub>{'s'}</sub>{', т/м³'}</Fragment>), field: 'gammas' },
    { title: 'e', field: 'e' },
    { title: 'E, т/м²', field: 'E' },
    { title: (<Fragment>{'E'}<sub>{'e'}</sub>{', т/м²'}</Fragment>), field: 'Ee' },
    { title: (<Fragment>{'R'}<sub>{'max'}</sub>{', т/м²'}</Fragment>), field: 'rmax' }
  ];

  export default header;
