import React, { useEffect } from 'react';

import draw from '../util/draw';
import theme from '../util/theme';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

const styles = {
  ...theme,
  canvasDiv: {
    margin: 'auto',
    textAlign: 'center'
  }
};

const Draw = props => {
  useEffect(() => {
      draw.render();
  }, [])

  const handleWheel = e => {
   //draft.onScale(e);
  };

  const { classes } = props;

  return (
    <div className={classes.drawList}>
      <Typography variant="subtitle1">Чертёж</Typography>
      <div className={classes.canvasDiv}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="draft"
          width="600"
          height="600"
          viewBox="-300 -150 600 600"
          onWheel={handleWheel}
        >
          Ваш браузер не поддерживает svg :(
        </svg>
      </div>
    </div>
  );
};

export default withStyles(styles)(Draw);
