import React, { Fragment } from 'react';
import MathJax from 'react-mathjax';

import { p0, p4, m } from './formulas';
import PEdgeRep from './p-edge';

const RepP = props => {
    const { data, report } = props;
    const {
        result: { rMin, n, a, pmax, r, gammamt, loadMx, loadMy, wx, wy },
        d,
    } = report;

    return (
        <div>
            Моменты от равнодействующей всех нагузок, действующих по подошве:
            <br />
            <MathJax.Node
                formula={m(
                    data.loads.mx,
                    data.loads.my,
                    report.result.loadMx,
                    report.result.loadMy,
                    data.loads.qx,
                    data.loads.qy,
                    data.levels.CL,
                    report.newFl || data.levels.FL
                )}
            />
            {report.result.loadMy === 0 && report.result.loadMx === 0 ? (
                <Fragment>
                    Раномерное давление вычислено по формуле 5.11 <a href='#source01'>[1]</a>
                    <br />
                    <div className={report.result.pmax < report.result.r ? 'reportResult' : 'reportResultNegative'}>
                        <MathJax.Node formula={p0({ n, a, pmax, r, gammamt, d })} />
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    <PEdgeRep report={report} sepLimit={data.general.sepLimit}></PEdgeRep>

                    {report.result.loadMy && report.result.loadMx ? (
                        <Fragment>
                            <h4 style={{ textAlign: 'center' }}>Для момента в двух плоскостях</h4>
                            Давление в угловых точках вычислено по формуле 5.15 <a href='#source01'>[1]</a>
                            <br />
                            <div
                                className={
                                    report.result.pmax < report.result.r * 1.5 ? 'reportResult' : 'reportResultNegative'
                                }
                            >
                                <MathJax.Node
                                    formula={p4({ gammamt, d, loadMx, loadMy, n, wx, wy, r, a }, report.result.pCorner)}
                                />
                            </div>
                        </Fragment>
                    ) : null}
                </Fragment>
            )}
            {rMin ? (
                <Fragment>
                    <div className='reportAttention'>
                        Так как R {'<'} 15 т/м<sup>2</sup>, форма эпюры не может быть меньше 0.25 п.5.6.27
                        <a href='#source01'>[1]</a>
                        <br />
                    </div>
                </Fragment>
            ) : null}
        </div>
    );
};

export default RepP;
