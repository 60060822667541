import React from 'react';

import tooltips from '../../util/tooltips';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const SimpleTooltip = ({ titleKey, text, placement }: { titleKey: string; text: string | JSX.Element; placement: any }) => {
    return (
        <HtmlTooltip placement={placement} title={tooltips[titleKey]}>
            <div>{text}</div>
        </HtmlTooltip>
    );
};

export default SimpleTooltip;
