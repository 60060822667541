import { Draft } from 'client/src/models/draft/draft.model';
import { GetPath } from 'client/src/models/draft/path.model';

import { complexShape } from './path-complex-shape.draft';
import { oneRectangle } from './path-one-rectangle.draft';
import { twoRectangles } from './path-two-rectangles.draft';

export const getPath = (draft: Draft, thickness: number, index: number, up: number, last: boolean): GetPath => {
    const { centerX, limit, rec1, rec2, scale } = draft;
    const h = Number(thickness) * scale;
    const down = up + h;

    let paths: string[] = [];

    if ((down <= rec1.down && up >= rec1.up) || (down <= rec2.down && up >= rec2.up)) {
        paths = twoRectangles(centerX, limit, rec1, rec2, up, down, index, last);
    } else if (up >= rec2.down || down <= rec1.up) {
        paths = oneRectangle(centerX, limit, up, down, index, last);
    } else {
        paths = complexShape(centerX, limit, rec1, rec2, up, down, index, last);
    }

    return { paths, down };
};
