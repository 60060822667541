export enum ErrorMessages {
    required = 'поле должно быть заполнено',
    notEnoughData = 'заданы не все исходные данные',
    wrongLevels1 = 'неверная отметка',
    wrongLevels2 = 'отметка подошвы должна быть ниже отметки обреза подколонника',
    wrongLevels3 = 'отметка подошвы должна быть ниже отметки уровня земли',
    wrongLevels4 = 'отметка границы должна быть ниже отметки подошвы',
    baseHeightTooLow = 'высота фундамента слишком мала',
    wrongFStep1 = 'расчётный шаг больше разности отметок между подошвой и границей пакетного расчёта',
    wrongFStep2 = 'неверный шаг',
    notEnoughGeology = 'геологии недостаточно',
    waterLevelBelowLastSoil = 'грунтовые воды ниже границы последнего слоя',
    aquicludeLevelBelowLastSoil = 'водоупор ниже границы последнего слоя',
    waterLevelBelowAquicludeLevel = 'уровень грунтовых вод выше водоупора',
    isFootSizeOutOfBounds = 'размер подошвы фундаменты выходит за границы расчёта; проверьте, пожалуйста, исходные данные',
    canBeEqualToZero = 'не может равняться нулю'
}