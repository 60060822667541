import { UIAction } from '../types';

import { AppDispatch } from '../store';

import axios from 'axios';

export const getNews = () => (dispatch: AppDispatch) => {
    dispatch({ type: UIAction.StartLoading });
    axios
        .get('/news')
        .then(res => {
            dispatch({
                type: UIAction.SetNews,
                payload: res.data
            });
            dispatch({ type: UIAction.StopLoading });
        })
        .catch(err => console.error(err));
};
