import React from 'react';

import theme from '../../util/theme';

// Components
import TasksList from '../../components/TasksList';
import SocialLinks from '../../components/SocialLinks';
import News from '../../components/news/news.component';
// import Promo from '../../components/Promo';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

// Redux Stuff
import { connect } from 'react-redux'; // @@@ REDUX TYPES ISSUE
import { setMenu } from '../../redux/actions/user.actions';

const styles = {
  ...theme
};

class Home extends React.Component {
  componentDidMount() {
    if (this.props.UI.menu) {
      this.props.setMenu('');
    }
  }

  componentDidCatch(err, info){
    console.log(err);
    console.log(info);
  }

  render() {
    const { classes, user: { authenticated }/*, UI: { loading }*/ } = this.props;
    return (
      <Grid container spacing={2} className={classes.mainGrid}>
        <Grid item xs={3} className={classes.itemGrid}>
          {authenticated ? <TasksList /> : <SocialLinks />}
        </Grid>
        <Grid item xs={9} className={classes.itemGrid} styles={{ padding: '20px'}}>
          <Paper id="news"className={classes.paperSheet}>
            <News/>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  UI: state.UI
});

export default connect(mapStateToProps, { setMenu })(withStyles(styles)(Home));
