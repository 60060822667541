import React from 'react';
import { Link } from 'react-router-dom';
import { Dialog, Button, DialogActions, DialogContent, Typography } from '@material-ui/core';

// interface CurrentProps {
//     message: string;
//     open: boolean;
//     handleLogin: () => void;
//     handleSingUp: () => void;
//     handleClose: () => void;
// }

const LoginWarningDialog = (props /*: CurrentProps*/) => {
    const { message, open, handleClose } = props;
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <Typography>Войдите или зарегистрируйтесь чтобы {message}</Typography>
                <DialogActions>
                    <Button color='secondary' variant='contained' component={Link} to='/signup'>
                        Регистрация
                    </Button>

                    <Button color='primary' variant='contained' component={Link} to='/login'>
                        Вход
                    </Button>
                    <Button autoFocus color='default' variant='contained' onClick={handleClose}>
                        Назад
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default LoginWarningDialog;
