import React from "react";

import theme from "../../util/theme";
import labels from "../../util/labels";
import HelpTooltip from "./HelpTooltip";

// Mui Stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";

// Icon
import Info from "@material-ui/icons/Info";

const styles = {
  ...theme
};

const NumberInput = props => {
  const {
    classes,
    name,
    value,
    units,
    handleChange,
    handleOpen,
    error,
    tooltip,
    informations
  } = props;
  return (
    <div className={classes.singleInput}>
      <InputLabel htmlFor={name}>{labels[name]}</InputLabel>
      <Input
        error={error ? true : false}
        id={name}
        name={name}
        type="number"
        endAdornment={<InputAdornment position="end">{units}</InputAdornment>}
        value={value}
        onChange={handleChange}
      />
      {tooltip && <HelpTooltip titleKey={name} />}
      {informations && (
        <IconButton onClick={handleOpen}>
          <Info />
        </IconButton>
      )}
    </div>
  );
};

export default withStyles(styles)(NumberInput);
