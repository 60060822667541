import { Task } from '../../../models/task.model';
import { getDefaultSoil } from '../../../util/default-soil';
import randomColor from '../../../util/randomColor';

interface TaskHelperResponse {
    task: Task;
    durty: boolean;
}

/**
 * Serve all inputs, except in array
 * @param state Task
 * @param key primary key
 * @param name sectondary key
 * @param value changed value
 * @returns { Task, durty }
 */
export const inputChange = (state: Task, key: string, name?: string, value?: number): TaskHelperResponse => {
    const task = cloneTask(state);

    if (!name || (typeof value !== 'number' && typeof value !== 'string' && typeof value !== 'boolean')) {
        console.warn(`No name "${name}" or wrong value `, value);
        return withoutChanges(state);
    }

    if (key === 'root') {
        if (name in task) {
            (task as any)[name] = value;
        } else {
            console.warn(`Key "${name}" is not in object Task`);
            return withoutChanges(state);
        }
    } else {
        if (!(key in task)) {
            console.warn(`Key "${key}" is not in object Task`);
        }
        const part = (task as any)[key];
        // if (name in part) {
            part[name] = value; // HOTFIX NEED TO INVESTIGATE
        // } else {
        //     console.warn(`Key "${name}" is not in object ${key.charAt(0).toUpperCase() + key.slice(1)}`);
        //     return withoutChanges(state);
        // }
    }

    return { task, durty: true };
};
/**
 * Add item to array. Curruntly works for soil and well
 * @param state Task
 * @param key primary key
 * @returns { Task, durty }
 */
export const addRow = (state: Task, key: NamedCurve): TaskHelperResponse => {
    const task = cloneTask(state);

    if (!key || !(key in task)) {
        console.warn(`Wrong key "${key}" or key is not in object Task`);
        return withoutChanges(state);
    }

    const part = (task as any)[key].slice();
    if (key === 'soilsData') {
        // TODO need do something with magic word
        part.push(getDefaultSoil());
    } else {
        part.push({ soil: '', thickness: '' });
    }
    (task as any)[key] = part;

    return { task, durty: true };
};
/**
 * Delete item in array. Curruntly works fro soil and well
 * @param state Task
 * @param key primary key
 * @param index item index
 * @returns { Task, durty }
 */
export const deleteRow = (state: Task, key: string, index?: number): TaskHelperResponse => {
    const task = cloneTask(state);

    if (!key || !(key in task) || typeof index !== 'number') {
        console.warn(`Wrong key "${key}" or index ${index} or key is not in object Task`);
        return withoutChanges(state);
    }

    task.soilsData = task.soilsData.filter((_, idx) => idx !== index);
    task.well = task.well.filter(layer => layer.soil !== index);

    return { task, durty: true };
};
/**
 * Works with changes in array
 * @param st Task
 * @param key primary key
 * @param name secondary key
 * @param idx array item index
 * @param val value
 * @returns { Task, durty }
 */
export const tableChange = (st: Task, key: string, name?: string, idx?: number, val?: number): TaskHelperResponse => {
    const task = cloneTask(st);
    if (!key || !(key in task) || typeof idx !== 'number') {
        console.warn(`Wrong key "${key}" or index ${idx} or key is not in object Task`);
        return withoutChanges(st);
    }

    const row = (task as any)[key][idx];
    if (!row) {
        console.warn(`Wrong index ${idx}`);
        return withoutChanges(st);
    }
    if (!name || !(name in row)) {
        console.warn(`Key "${name}" is not in ${key.charAt(0).toUpperCase() + key.slice(1)}`);
        return withoutChanges(st);
    }
    row[name] = val;

    return { task, durty: true };
};
/**
 * Change color
 * @param state Task
 * @param index array item index
 * @returns { Task, durty }
 */
export const changeColor = (state: Task, index?: number): TaskHelperResponse => {
    const task = cloneTask(state);
    const soil = typeof index === 'number' ? task.soilsData[index] : null;
    if (!soil) {
        console.warn(`Wrong index ${index}`);
        return withoutChanges(state);
    }

    soil.color = randomColor();

    return { task, durty: true };
};

/**
 * Return non-changes task with false flag
 * @param task Task
 * @returns { Task, durty }
 */
const withoutChanges = (task: Task): TaskHelperResponse => ({ task, durty: false });
/**
 * Semi-deep clone task
 * @param task Task
 * @returns {Task}
 */
const cloneTask = (task: Task): Task => ({ ...task });
