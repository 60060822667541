import { DraftRect } from "client/src/models/draft/draft.model";

export const complexShape = (
    centerX: number,
    limit: number,
    rec1: DraftRect,
    rec2: DraftRect,
    up: number,
    down: number,
    index: number,
    last: boolean
): string[] => {
    let path: string = 'M',
        reversePath: string = 'M',
        tempPath: string = '';
    const paths: string[] = [];

    if (up < rec1.up) {
        path += ` ${centerX + limit} ${up} ${centerX} ${up} ${centerX} ${rec1.up} ${rec1.right} ${rec1.up} `;
        reversePath += ` ${centerX - limit} ${up} ${centerX} ${up} ${centerX} ${rec1.up} ${rec1.left} ${rec1.up} `;
        if (down > rec1.down) {
            path += `${rec1.right} ${rec1.down} ${rec2.right} ${rec2.up} `;
            reversePath += `${rec1.left} ${rec1.down} ${rec2.left} ${rec2.up} `;
        }
        tempPath = index === 0 ? `M ${centerX + limit} ${up} ${centerX - limit} ${up} ` : ``;
    } else if (up < rec1.down) {
        path += ` ${centerX + limit} ${up} ${rec1.right} ${up} ${rec1.right} ${rec1.down} ${rec2.right} ${
            rec2.up
        } `;
        reversePath += ` ${centerX - limit} ${up} ${rec1.left} ${up} ${rec1.left} ${rec1.down} ${rec2.left} ${
            rec2.up
        } `;
        tempPath =
            index === 0
                ? `M ${centerX + limit} ${up} ${rec1.right} ${up} M ${centerX - limit} ${up} ${rec1.left} ${up} `
                : ``;
    } else if (up >= rec2.up) {
        path += ` ${centerX + limit} ${up} ${rec2.right} ${up} ${rec2.right} ${rec2.down} `;
        reversePath += ` ${centerX - limit} ${up} ${rec2.left} ${up} ${rec2.left} ${rec2.down} `;
        tempPath =
            index === 0
                ? `M ${centerX + limit} ${up} ${rec2.right} ${up} M ${centerX - limit} ${up} ${rec2.left} ${up} `
                : ``;
    }
    if (down < rec1.down) {
        path += ` ${rec1.right} ${down} ${centerX + limit} ${down} Z`;
        reversePath += ` ${rec1.left} ${down} ${centerX - limit} ${down} Z`;
        tempPath += `M ${rec1.left} ${down} ${centerX - limit} ${down} M ${rec1.right} ${down} ${
            centerX + limit
        } ${down} `;
    } else if (down <= rec2.down) {
        path += ` ${rec2.right} ${down} ${centerX + limit} ${down} Z`;
        reversePath += ` ${rec2.left} ${down} ${centerX - limit} ${down} z`;
        tempPath += `M ${rec2.left} ${down} ${centerX - limit} ${down} M ${rec2.right} ${down} ${
            centerX + limit
        } ${down} `;
    } else {
        path += `${rec2.right} ${rec2.down} ${centerX} ${rec2.down} ${centerX} ${down} ${
            centerX + limit
        } ${down} Z`;
        reversePath += `${rec2.left} ${rec2.down} ${centerX} ${rec2.down} ${centerX} ${down} ${
            centerX - limit
        } ${down} Z`;
        tempPath += `M ${centerX + limit} ${down} ${centerX - limit} ${down} `;
    }

    path += ' ' + reversePath;
    paths.push(path);
    paths.push(tempPath);
    
    return paths;
};