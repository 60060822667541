import React from 'react';

import header from '../../util/soils-tab-headers';
import soilsDecoder from '../../util/soils-decoder';

import { MoistureType, SoilType, SandStructureType, CoarseStructureType } from '../../models/soil.model';

// Components
import TabInput from '../helpers/TabInput';
import SoilsTableCell from './soils-table-cell.component';

// Mui Stuff
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import TableRow from '@material-ui/core/TableRow';

// Icons
import DeleteIcon from '@material-ui/icons/Delete';

const soilTypes = Object.values(SoilType) as SoilType[];
const sandStructureTypes = Object.values(SandStructureType) as SandStructureType[];
const coarseStructureTypes = Object.values(CoarseStructureType) as CoarseStructureType[];
const moistureTypes = Object.values(MoistureType) as MoistureType[];

const SoilsTableRow = (
    element: any,
    index: number,
    handleRemove: (index: number, title: string) => void,
    handleChange: (e: React.ChangeEvent<{ name?: string, value?: any }>, index: number, title: string) => void,
    handleChangeColor: (index: number) => void,
    errors: any
) => {
    const soilErrors = errors?.soils?.[index] || {};
    const isClay = element.type === SoilType.clay || element.type === SoilType.loam || element.type === SoilType.sandLoam;

    return (
        <TableRow key={`tr-${index}`}>
            <SoilsTableCell>
                <IconButton onClick={() => handleRemove(index, 'soilsData')}>
                    <DeleteIcon fontSize='small' />
                </IconButton>
            </SoilsTableCell>
            <SoilsTableCell>{index + 1}</SoilsTableCell>
            {header.map((key, currentIndex) => (
                <SoilsTableCell key={`trc-${currentIndex}`}>
                    {(() => {
                        switch (key.field) {
                            case 'color':
                                return (
                                    <IconButton onClick={e => handleChangeColor(index)}>
                                        <div style={{ backgroundColor: element[key.field] }}>&nbsp;&nbsp;</div>
                                    </IconButton>
                                );
                            case 'type':
                                return (
                                    <Select
                                        error={soilErrors[key.field] ? true : false}
                                        className='selectItem'
                                        name={key.field}
                                        onChange={e => handleChange(e, index, 'soilsData')}
                                        value={element[key.field] || ''}
                                    >
                                        <MenuItem value='' disabled></MenuItem>
                                        {soilTypes.map((soil, idx) => <MenuItem key={`type#${index}${idx}`} value={soil}>{soilsDecoder(soil, true)}</MenuItem>)}
                                    </Select>
                                );
                            case 'structure':
                                return element['type'] === SoilType.sand ? (
                                    <Select
                                        error={soilErrors[key.field] ? true : false}
                                        className='selectItem'
                                        name={key.field}
                                        onChange={e => handleChange(e, index, 'soilsData')}
                                        value={element[key.field]}
                                    >
                                        <MenuItem value={''} disabled></MenuItem>
                                        {sandStructureTypes.map((structure, idx) => <MenuItem key={`structure#${index}${idx}`} value={structure}>{soilsDecoder(structure, true)}</MenuItem>)}
                                    </Select>
                                ) : element['type'] === SoilType.coarseGrained ? (
                                    <Select
                                        error={soilErrors[key.field] ? true : false}
                                        className='selectItem'
                                        name={key.field}
                                        onChange={e => handleChange(e, index, 'soilsData')}
                                        value={element[key.field]}
                                    >
                                        <MenuItem value={''} disabled></MenuItem>
                                        {coarseStructureTypes.map((structure, idx) => <MenuItem key={`structure#${index}${idx}`} value={structure}>{soilsDecoder(structure, true)}</MenuItem>)}
                                    </Select>
                                ):(
                                    (element[key.field] = '')
                                );
                            case 'moisture':
                                return element['structure'] === SandStructureType.dust ? (
                                    <Select
                                        error={soilErrors[key.field] ? true : false}
                                        className='selectItem'
                                        name={key.field}
                                        onChange={e => handleChange(e, index, 'soilsData')}
                                        value={element[key.field]}
                                    >
                                        <MenuItem value={''} disabled></MenuItem>
                                        {moistureTypes.map((moisture, idx) => <MenuItem key={`moisture#${index}${idx}`} value={moisture}>{soilsDecoder(moisture, true)}</MenuItem>)}
                                    </Select>
                                ) : (
                                    (element[key.field] = '')
                                );
                            case 'il':
                                return isClay ? (
                                    <TabInput
                                        error={soilErrors[key.field] ? true : false}
                                        name={key.field}
                                        index={index}
                                        handleChange={handleChange}
                                        value={element[key.field]}
                                    />
                                ) : (
                                    (element[key.field] = '')
                                );
                            default:
                                return (
                                    <TabInput
                                        error={soilErrors[key.field] ? true : false}
                                        name={key.field}
                                        index={index}
                                        handleChange={handleChange}
                                        value={element[key.field]}
                                    />
                                );
                        }
                    })()}
                </SoilsTableCell>
            ))}
        </TableRow>
    );
};


export default SoilsTableRow;