import React, { ChangeEvent, useState } from 'react';

import theme from '../../util/theme';

// components
import NumberInput from '../helpers/NumberInput';
import NewCheckbox from '../helpers/new-checkbox';
import HelpTooltip from '../helpers/HelpTooltip';
import ModalWindow from '../helpers/ModalWindow';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const styles = {
    ...theme
};

const GeneralData = (props: any) => {
    const [isOpen, setOpen] = useState(false);
    const [isAboutSettlement, setAboutSettlement] = useState(false);

    const handleOpen = (isAboutSettlement: boolean) => {
        setAboutSettlement(isAboutSettlement);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        // TODO same function in soils-data component
        const inputType = e.target.type;
        const name = e.target.name;
        const value = inputType === 'checkbox' ? e.target.checked : e.target.value;
        const key = name === 'title' || name === 'description' ? 'root' : 'general';

        props.handleChange(key, name, value);
    };

    const { classes, code, state, title, description, errors } = props;
    const haveData = state && Object.keys(state).length > 0;

    return (
        haveData ? (
            <div className={classes.dataList}>
                <Typography variant='subtitle1'>Общие данные</Typography>
                <div className={classes.inputGroup}>
                    <TextField
                        error={errors.title ? true : false}
                        name='title'
                        label='Имя задачи'
                        inputProps={{ maxLength: 20 }}
                        fullWidth
                        value={title}
                        onChange={handleChange}
                    />
                    <br />
                    <TextField
                        name='description'
                        label='Описание'
                        multiline
                        rows='2'
                        rowsMax='4'
                        fullWidth
                        value={description}
                        onChange={handleChange}
                    />
                    <NewCheckbox
                        name={'schema'}
                        value={state.schema}
                        handleChange={handleChange}
                        label={'Жёсткая схема'}
                    />
                    <HelpTooltip titleKey='strongSchema' />
                    {state.schema ? (
                        <div>
                            <NumberInput
                                name={'lSize'}
                                value={state.lSize}
                                units={'м'}
                                handleChange={handleChange}
                                error={errors.lSize}
                            />
                            <NumberInput
                                name={'hSize'}
                                value={state.hSize}
                                units={'м'}
                                handleChange={handleChange}
                                error={errors.hSize}
                            />
                        </div>
                    ) : null}
                    <br />
                    <NewCheckbox
                        name={'basement'}
                        value={state.basement}
                        handleChange={handleChange}
                        label={'Подвал'}
                    />
                    {state.basement ? (
                        <div>
                            <NumberInput
                                name={'hs'}
                                value={state.hs}
                                units={'м'}
                                handleChange={handleChange}
                                error={errors.hs}
                            />
                            <NumberInput
                                name={'hcf'}
                                value={state.hcf}
                                units={'м'}
                                handleChange={handleChange}
                                error={errors.hcf}
                            />
                            <NumberInput
                                name={'gammacf'}
                                value={state.gammacf}
                                units={'т/м³'}
                                handleChange={handleChange}
                                error={errors.gammacf}
                            />
                        </div>
                    ) : null}
                    <br />
                    <NewCheckbox
                        name={'checkExisting'}
                        value={state.checkExisting}
                        handleChange={handleChange}
                        label={'Проверка существующего фундамента'}
                    />
                    {state.checkExisting && !state.roundFoot ? (
                        <div>
                            <NumberInput
                                name={'bExisting'}
                                value={state.bExisting}
                                units={'м'}
                                handleChange={handleChange}
                                error={errors.bExisting}
                            />
                            <NumberInput
                                name={'lExisting'}
                                value={state.lExisting}
                                units={'м'}
                                handleChange={handleChange}
                                error={errors.lExisting}
                            />
                        </div>
                    ) : null}
                    <br />
                    <NewCheckbox
                        name={'roundFoot'}
                        value={state.roundFoot}
                        handleChange={handleChange}
                        label={'Подошва круглой формы'}
                    />
                    {state.checkExisting && state.roundFoot && (
                        <NumberInput
                            name={'diam'}
                            value={state.diam}
                            units={'м'}
                            handleChange={handleChange}
                            error={errors.diam}
                        />
                    )}
                    {!state.checkExisting && !state.roundFoot && (
                        <NumberInput
                            name={'lb'}
                            value={state.lb}
                            units={''}
                            handleChange={handleChange}
                            tooltip={true}
                        />
                    )}
                    <NumberInput
                        name={'sepLimit'}
                        value={state.sepLimit}
                        units={''}
                        handleChange={handleChange}
                        tooltip={true}
                    />
                    {!state.checkExisting && (
                        <NumberInput
                            name={'sizeModule'}
                            value={state.sizeModule}
                            units={'м'}
                            handleChange={handleChange}
                            tooltip={true}
                        />
                    )}
                    <br />
                    <NewCheckbox
                        name={'preparation'}
                        value={state.preparation}
                        handleChange={handleChange}
                        label={'Подготовка'}
                    />
                    {state.preparation ? (
                        <div>
                            <NewCheckbox
                                name={'variablePreparation'}
                                value={state.variablePreparation}
                                handleChange={handleChange}
                                label={'переменной жесткости'}
                            />
                            <HelpTooltip titleKey='variablePreparation' />
                            <NumberInput
                                name={'hp'}
                                value={state.hp}
                                units={'м'}
                                handleChange={handleChange}
                                error={errors.hp}
                                tooltip={true}
                            />
                        </div>
                    ) : null}
                    <br />
                    <NewCheckbox
                        name={'column'}
                        value={state.column}
                        handleChange={handleChange}
                        label={'Подколонник'}
                    />
                    <HelpTooltip titleKey='column' />
                    <br />
                    {state.column ? (
                        <div>
                            <NumberInput
                                name={'bColumn'}
                                value={state.bColumn}
                                units={'м'}
                                handleChange={handleChange}
                                error={errors.bColumn}
                            />
                            <NumberInput
                                name={'lColumn'}
                                value={state.lColumn}
                                units={'м'}
                                handleChange={handleChange}
                                error={errors.lColumn}
                            />
                        </div>
                    ) : null}
                    <NewCheckbox
                        name={'foundationPit'}
                        value={state.foundationPit}
                        handleChange={handleChange}
                        label={'Котлован глубиной менее 5 м.'}
                    />
                    <NumberInput
                        name={'smax'}
                        value={state.smax}
                        units={'см'}
                        handleChange={handleChange}
                        handleOpen={() => handleOpen(true)}
                        error={errors.smax}
                        informations={true}
                    />
                    <NumberInput
                        name={'imax'}
                        value={state.imax}
                        units={''}
                        handleChange={handleChange}
                        handleOpen={() => handleOpen(false)}
                        error={errors.imax}
                        informations={true}
                    />
                    <NumberInput
                        name={'soilThickness'}
                        value={state.soilThickness}
                        units={'м'}
                        handleChange={handleChange}
                        tooltip={true}
                    />
                    <br />
                    <Typography variant='body2' color='textSecondary'>
                        код: {code}
                    </Typography>
                </div>
                <ModalWindow isS={isAboutSettlement} toolKey='smax' open={isOpen} handleClose={handleClose} />
            </div>
        ) : (<Typography variant='body2' color='textSecondary'>
                Подождите, пожалуйста, идёт загрузка задачи
            </Typography>)
    );
};

export default withStyles(styles as any)(GeneralData as any);
// 356 
// TODO divide into smallest pieces, move schema, basement, existen and column to a new components
// becasue it have several additional fields
