import { Draft } from "client/src/models/draft/draft.model";

const recDefault = () => ({
    up: 0,
    down: 0,
    left: 0,
    right: 0
})

export const svgNS = 'http://www.w3.org/2000/svg';

export const draftDefault = (): Draft => ({
    scale: 100,
    x: 0,
    y: 0,
    centerX: 300,
    centerY: 150,
    gridStep: 10,
    crossStep: 100,
    crossSize: 7,
    limit: 200,
    levelMark: {
        arrowHeight: 8,
        arrowWidth: 10,
        height: 22,
        length: 50,
        textSize: 8,
        offset: -2,
    },
    foundation: {
        column: 90,
        footing: 180,
        hStep: 45,
        height: 210,
    },
    levels: {
        nl: 0,
        dl: 0,
        fl: -200,
        cl: 10,
        wl: undefined,
        al: undefined
    },
    sandToggle: false,
    sandHatch: null as any,
    soils: [],
    bodyCode: '',
    rec1: recDefault(),
    rec2: recDefault()
});
