import React, { Component } from 'react';

import theme from '../../util/theme';

// Components
import NewsMessage from '../helpers/NewsMessage';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';

// Redux Stuff
import { connect } from 'react-redux';

const styles = {
  ...theme
};

class News extends Component {
  render() {
    const { classes, UI: { news } } = this.props;
    return (
      <div className={classes.dataList}>
        <Typography variant="h6">Новости</Typography>
        <List>
          {news.length !== 0 ?
            news.map((message, index) => <NewsMessage key={'item'+index} message={message}/>)
            : null
          }
        </List>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  UI: state.UI
})

export default connect(mapStateToProps)(withStyles(styles)(News));
