import React from 'react';
// MATJAX TYPES PROBLEM
import MathJax from 'react-mathjax';

import { printDraft } from '../../../util/draft.render.print';
import { printSettlement } from '../../../util/settlement.render.print';
import { graphPRender } from '../../../util/graph-p-render';

// components
import GeneralRep from '../../helpers/report/GeneralRep';
import SoilsRep from '../../helpers/report/soil-table.report';
import LevelsRep from './levels/levels-report';
import LoadsRep from '../../helpers/report/LoadsRep';
import DraftRep from '../../helpers/report/draft.report';
import RReport from './r-report/r.report';
import RepP from '../../helpers/report/RepP';
import SettlementReport from './settlement-report/settlement.report';
import SettlementReportTable from './settlement-report-table/settlement-report-table';
import RepWeakLayer from '../../helpers/report/RepWeakLayer';
import UsageRatesRep from './usage-rates/usage-rates';
import LeaningReport from './leaning/leaning-report';

import './print-report.css';

class ViewReport extends React.Component {
    componentDidMount() {
        printDraft(this.props.data, 'reportDraft', this.props.index);
        printSettlement(this.props.data, 'reportSettlement', this.props.index);
        graphPRender(this.props.data.report[this.props.index].result);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        printDraft(this.props.data, 'reportDraft', this.props.index);
        printSettlement(this.props.data, 'reportSettlement', this.props.index);
        graphPRender(this.props.data.report[this.props.index].result);
    }

    render() {
        const { data, user, index } = this.props;
        const report = data.report[index];
        return (
            <MathJax.Provider>
                <div className='sheetHelper'>
                    <div className='sheetA4'>
                        <main>
                            <h1>ОТЧЁТ</h1>
                            <section>
                                <h2>Исходные данные</h2>
                                <h3>Общие данные</h3>
                                <GeneralRep data={data} user={user} index={index} />
                                <div className='page-break'></div>
                                <h3>Грунтовые условия</h3>
                                <SoilsRep data={data} />
                                {data.soilsData.length > 8 && <div className='page-break'></div>}
                                <h3>Отметки</h3>
                                <LevelsRep data={data} index={index} />
                                <h3>Нагрузки</h3>
                                <LoadsRep data={data} />
                                <div className='page-break'></div>
                                <h3>Эскиз</h3>
                                <DraftRep data={data} />
                            </section>
                            <div className='page-break'></div>
                            <section>
                                <h2>Результаты расчёта</h2>
                                <h3>Коэффициенты использования</h3>
                                <UsageRatesRep report={report}></UsageRatesRep>
                                <div className='page-break'></div>
                                <h3>Расчёт по деформациям</h3>
                                <h4>Определение габаритов подошвы фундамента</h4>
                                <article style={{ textAlign: 'left' }}>
                                    <RReport data={data} report={report} />
                                    <RepP data={data} report={report} />
                                    <div style={{ textAlign: 'center' }}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            id='graphP'
                                            width='600'
                                            height='450'
                                            viewBox='0 0 600 450'
                                        >
                                            Ваш браузер не поддерживает svg
                                        </svg>
                                    </div>
                                </article>

                                {report.weakLayer ? (
                                    <div>
                                        <div className='page-break'></div>
                                        <h4>Учёт слоя меньшей прочности</h4>
                                        <article>
                                            <RepWeakLayer data={data} report={report} />
                                        </article>
                                    </div>
                                ) : (
                                    ''
                                )}

                                <div className='page-break'></div>

                                <h4>Определение осадки</h4>
                                <article style={{ textAlign: 'left' }}>
                                    <SettlementReport data={data} report={report} />
                                    <div className='page-break'></div>
                                    <div style={{ textAlign: 'center' }}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            id='reportSettlement'
                                            width='600'
                                            height='600'
                                            viewBox='0 0 600 600'
                                        >
                                            Ваш браузер не поддерживает svg
                                        </svg>
                                    </div>
                                </article>

                                <div className='page-break'></div>

                                <h4>Определение крена</h4>
                                <LeaningReport leaning={report.leaning}></LeaningReport>

                                {/*<h3>Расчёт по несущей способности</h3>
              <article>
                ... в процессе
              </article>
              <h3>Расчёт по прочности материала</h3>
              <article>
                ... в процессе
              </article>*/}
                            </section>

                            <div className='page-break'></div>

                            <section>
                                <h2>Источники</h2>
                                <article style={{ textAlign: 'left' }}>
                                    <ol>
                                        <li id='source01'>
                                            СП 22.13330.2016 Основания зданий и сооружений. Актуализированная редакция
                                            СНиП 2.02.01-83* (с Изменениями N 1, 2, 3, 4), М. 2017 г.
                                        </li>
                                        <li id='source02'>
                                            СП 23.13330.2018 Основания гидротехнических сооружений. Актуализированная
                                            редакция СНиП 2.02.02-85, М. 2019 г.
                                        </li>
                                        <li id='source03'>
                                            Пособие по проектированию оснований зданий и сооружений (к СНиП 2.02.01-83),
                                            М. 1986 г.
                                        </li>
                                    </ol>
                                </article>
                            </section>

                            <div className='page-break'></div>

                            <section>
                                <h2>Приложение</h2>
                                <h3>Таблица расчёта осадки</h3>
                                <article>
                                    <SettlementReportTable settlement={report.settlement} />
                                </article>
                            </section>
                        </main>
                    </div>
                </div>
            </MathJax.Provider>
        );
    }
}

export default ViewReport;
