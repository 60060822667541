import React, { Fragment } from "react";
import MathJax from "react-mathjax";

import soilsDecoder from "../../../util/soils-decoder";
import { wl_a, wl_az, wl_bz, wl_sigmaz, /*wl_p,*/ r,
  sigmazg0, wl_sigmazg, wl_sigmazgamma, wl_sigmazp, wlCondition } from "./formulas";

const RepWeakLayer = props => {
  const { data, report } = props;
  const { weakLayer } = report;
  return (
    <div style={{ textAlign: "left" }}>
      Под подошвой фундамента, в пределах сжимаемой толщи, располагается слой
      меньшей прочности. Проверка выполняется по п.5.6.25 <a href="#source01">[1]</a><br />
      <br/>
      Кровля слоя находится на глубине {weakLayer.z.toFixed(2)} м от подошвы фундамента<br />
      
      Суммарное напряжение на кровлю слоя меньшей прочности:<br/>
      <div style={{ textAlign: "center" }}>
        &zeta; = {weakLayer.zeta.toFixed(2)}{"; "}
        &eta; = {weakLayer.eta.toFixed(2)}{"; "}
        &alpha; = {weakLayer.alpha.toFixed(3)}{"; "}<br />
      </div>
      {/* Пособие стр. 120 */}
      <MathJax.Node formula={sigmazg0(data.levels.NL, data.levels.DL, weakLayer.report.gamma2s.value,
        report.d.value, report.dn.value, weakLayer.sigmazg0)} />
      <MathJax.Node formula={wl_sigmazg(weakLayer.sigmazg)} />
      <MathJax.Node formula={wl_sigmazgamma(weakLayer.alpha, weakLayer.sigmazg0, weakLayer.sigmazgamma)} />
      <MathJax.Node formula={wl_sigmazp(weakLayer.alpha, weakLayer.pmid, weakLayer.sigmazp)} />
      <MathJax.Node formula={wl_sigmaz(weakLayer.sigmazp, weakLayer.sigmazgamma,
        weakLayer.sigmazg, weakLayer.sigmaz)}/>

      Определение условной ширины фундамента:<br/>
      <MathJax.Node formula={wl_a(report.result.l, report.result.b, weakLayer.a)} />
      <MathJax.Node formula={wl_az(report.result, weakLayer.sigmazp, weakLayer.az)} />
      <MathJax.Node formula={wl_bz(weakLayer.az, weakLayer.a, weakLayer.bz)} />

      Расчётное сопротивление на кровле грунта меньшей прочности вычисляется по{' '}
      формуле 5.7{" "}<a href="#source01">[1]</a><br />
      В основании {soilsDecoder(data.soilsData[weakLayer.report.soilUnder.idx].type)}{" "}
      {soilsDecoder(data.soilsData[weakLayer.report.soilUnder.idx].structure)}{" "}
      {soilsDecoder(data.soilsData[weakLayer.report.soilUnder.idx].moisture)}<br />
      Коэффициенты &gamma;<sub>c1</sub> и &gamma;<sub>c2</sub> подбираются по таб. 5.4 <a href="#source01">[1]</a><br />
      {weakLayer.report.gammaC1.comment === weakLayer.report.gammaC2.comment ? (
        <Fragment>
          &gamma;<sub>c1</sub> = &gamma;<sub>c2</sub> ={" "}
          {weakLayer.report.gammaC1.value.toFixed(2)} {weakLayer.report.gammaC1.comment}
          <br />
        </Fragment>
      ) : (
        <Fragment>
          &gamma;<sub>c1</sub> = {weakLayer.report.gammaC1.value.toFixed(2)}{" "}
          {weakLayer.report.gammaC1.comment}
          <br />
          &gamma;<sub>c1</sub> = {weakLayer.report.gammaC2.value.toFixed(2)}{" "}
          {weakLayer.report.gammaC2.comment}
          <br />
        </Fragment>
      )}
      k = {weakLayer.report.k.value} {weakLayer.report.k.comment} п.5.6.7 <a href="#source01">[1]</a><br />
      Для угла внутреннего трения &phi;<sub>2</sub> = {weakLayer.report.soilUnder.values.phi2.toFixed(0)}&deg;<br />
      Коэффициенты M подбираются по таб. 5.5 <a href="#source01">[1]</a><br />
      M<sub>&gamma;</sub> = {weakLayer.report.Mg.value}<br />
      M<sub>q</sub> = {weakLayer.report.Mq.value}<br />
      M<sub>c</sub> = {weakLayer.report.Mc.value}<br />
      k<sub>z</sub> = {weakLayer.report.kz.value.toFixed(2)} {weakLayer.report.kz.comment}<br />
      &gamma;<sub>II</sub>' = {weakLayer.report.gamma2s.value.toFixed(2)} т/м<sup>3</sup>{" "}
      осреднённый вес грунтов выше подошвы<br />
      &gamma;<sub>II</sub> = {weakLayer.report.soilUnder.values.gamma2.toFixed(2)} т/м<sup>3</sup> вес грунта ниже подошвы<br />
      с<sub>II</sub> = {weakLayer.report.soilUnder.values.c2.toFixed(2)} т/м<sup>2</sup> удельное сцепление грунта ниже подошвы<br />
      Глубина заложения от уровня планировки с учётом слабого слоя<br />
      d = {weakLayer.report.d.value.toFixed(2)} м<br />
      Глубина заложения от уровня природного рельефа с учётом слабого слоя<br />
      d<sub>n</sub> = {weakLayer.report.dn.value.toFixed(2)} м<br />
      {data.general.basement ? (
        <Fragment>
          Для сооружений с подвалом d<sub>1</sub> определяется по формуле 5.8{" "}<a href="#source01">[1]</a><br />
          d<sub>1</sub> = {weakLayer.report.d1.value.toFixed(2)} м<br />
          Расстояние от уровня планировки до пола подвала<br />
          d<sub>b</sub> = {weakLayer.report.db.value.toFixed(2)} м<br />
        </Fragment>
      ) : (
        <Fragment>
          Для бесподвальных сооружений:<br />
          d<sub>1</sub> = d = {weakLayer.report.d.value.toFixed(2)} м<br />
          d<sub>b</sub> = 0<br />
        </Fragment>
      )}

      Расчётное сопротивление грунта меньшей прочности:<br />
      <MathJax.Node
        formula={r(weakLayer.report.gammaC1.value, weakLayer.report.gammaC2.value, weakLayer.report.k.value,
          weakLayer.report.Mg.value, weakLayer.report.Mq.value, weakLayer.report.Mc.value,
          weakLayer.report.kz.value, weakLayer.bz, weakLayer.report.soilUnder.values.gamma2, weakLayer.report.gamma2s.value,
          weakLayer.report.d1.value, weakLayer.report.db.value, weakLayer.report.soilUnder.values.c2, weakLayer.rz
        )}
      />

      <div className={weakLayer.sigmaz <= weakLayer.rz ? "reportResult" : "reportResultNegative"}>
          Проверка условия:<br/>
          <MathJax.Node formula={wlCondition(weakLayer.sigmaz, weakLayer.rz)} />
      </div>

      (формулы нахождения &eta;, &zeta;, &alpha; приведены в разреле расчёта осадки, d,{" "}
      d<sub>n</sub>, d<sub>1</sub>, d<sub>b</sub> в разделе определения
      габаритов подошвы фундамента)
    </div>
  );
};

export default RepWeakLayer;
