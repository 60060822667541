import React from 'react';

import settlementTabHeader from './settlement-tab-header/settlementTabHeader';

import { SettlementReport } from '../../../../models/settlement-report.model';

const SettlementReportTable: React.FC<{ settlement: SettlementReport }> = ({ settlement }) => {
    const { tab, index } = settlement;
    return (
        <table className='soilsTable settlementTable'>
            <thead>
                <tr>
                    <th></th>
                    {settlementTabHeader.map((item, i) => (
                        <th key={`ths${i}`}>{item.title}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {tab
                    .filter((row, currentIndex) => currentIndex <= index + 2)
                    .map((item, i) => (
                        <tr
                            key={`trs${i}`}
                            className={
                                i === index ? 'settlementConditionRow' : item.isForWeakLayer ? 'hiddenWeakLayerRow' : ''
                            }
                        >
                            <td>{i !== 0 ? i : ''}</td>
                            {settlementTabHeader.map((key, k) => {
                                switch (key.field) {
                                    case 'color':
                                        return (
                                            <td key={`tds${i}${k}`}>
                                                <div style={{ backgroundColor: item[key.field] }}>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                </div>
                                            </td>
                                        );
                                    case 'water':
                                        return (
                                            <td key={`tds${i}${k}`}>
                                                {item[key.field] ? (
                                                    <div style={{ backgroundColor: 'rgba(10, 18, 247, 0.2)' }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                    </div>
                                                ) : null}
                                            </td>
                                        );
                                    case 'soil':
                                        return <td key={`tds${i}${k}`}>{item[key.field] + 1}</td>;
                                    case 'level':
                                    case 'z':
                                    case 'h':
                                    case 'gamma2':
                                        return <td key={`tds${i}${k}`}>{item[key.field].toFixed(2)}</td>;
                                    case 'e':
                                    case 'ee':
                                        return <td key={`tds${i}${k}`}>{item[key.field].toFixed(1)}</td>;
                                    case 'zeta':
                                    case 'alpha':
                                    case 'uz':
                                    case 'sigmazg':
                                    case 'sigmazgamma':
                                    case 'sigmazp':
                                    case 'zpzg':
                                        return <td key={`tds${i}${k}`}>{item[key.field].toFixed(3)}</td>;
                                    case 's':
                                        return <td key={`tds${i}${k}`}>{item[key.field].toFixed(3)}</td>;
                                    default:
                                        return <td key={`tds${i}${k}`}></td>;
                                }
                            })}
                        </tr>
                    ))}
            </tbody>
        </table>
    );
};

export default SettlementReportTable;
