import React from 'react';

import loadsSvg from '../../../images/loads.svg';

const LoadsRep = props => {
  const { data } = props;
  return (
    <article style={{ display: "flex" }}>
        <div style={{ textAlign: 'left', minWidth: '100px' }}>
            N = {data.loads.n ? parseFloat(data.loads.n).toFixed(2) : '0.00'} т<br/>
            M<sub>x</sub> = {data.loads.mx ? parseFloat(data.loads.mx).toFixed(2) : '0.00'} т&sdot;м<br/>
            Q<sub>y</sub> = {data.loads.qy ? parseFloat(data.loads.qy).toFixed(2) : '0.00'} т<br/>
            M<sub>y</sub> = {data.loads.my ? parseFloat(data.loads.my).toFixed(2) : '0.00'} т&sdot;м<br/>
            Q<sub>x</sub> = {data.loads.qx ? parseFloat(data.loads.qx).toFixed(2) : '0.00'} т<br/>
        </div>
        <div>
          <img src={loadsSvg} alt="схема приложения нагрузок на фундамент" width="50%"/>
        </div>
      </article>
  )
}

export default LoadsRep;
