import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

// Mui Stuff
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';

const SimpleDialog = props => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    open,
    task: { title, description, createdAt }
  } = props;
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={props.handleClose}
      aria-labelledby="simple-dialog"
    >
      <DialogTitle id="simple-dialog">Подтвердите действие</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography component="span" variant="body1" color="textPrimary">
            Вы действительно хотите удалить задачу:{' '}
          </Typography>
          <Typography component="span" variant="h6" color="primary">
            {title}
          </Typography>
          <br />
          <Typography component="span" variant="body2" color="textSecondary">
            {description}
          </Typography>
          <br />
          <Typography component="span" variant="body1" color="textPrimary">
            Созданную:{' '}
          </Typography>
          {dayjs(createdAt).format('DD MMMM YYYY')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          onClick={props.handleClose}
          color="primary"
        >
          Отменить
        </Button>
        <Button
          autoFocus
          variant="contained"
          onClick={props.handleAgreed}
          color="secondary"
        >
          Подтвердить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default SimpleDialog;
