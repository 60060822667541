import React, { useState, useEffect } from 'react';
//import { Prompt } from "react-router-dom";

import { useSelector } from 'react-redux';

import theme from '../util/theme';

// Components
import InputPanel from '../components/InputPanel';
import GeneralData from '../components/general/general-data';
import SoilsData from '../components/soils/soils-data';
import LevelsData from '../components/levels/levels-data';
import LoadsData from '../components/loads/loads-data';
import Draft from '../components/draft/draft.component';
import Report from '../components/report/report.component';
import Draw from '../components/Draw';
import Analysis from '../components/Analysis';
import ReportBugDialog from '../components/helpers/dialogs/report-bug.dialog';
import LoginWarningDialog from '../components/helpers/dialogs/login-warning.dialog';
import ConfirmDialog from '../components/helpers/dialogs/confirm.dialog ';
//import SaveDialog from "../components/helpers/SaveDialog";

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

// Redux Stuff
import { connect } from 'react-redux';
import { getTask, postTask, updateTask, doTask } from '../redux/actions/task.actions';
import {
    taskInputChange,
    taskAddRow,
    taskDeleteRow,
    taskTableChange,
    taskColorChange,
    taskReset
} from '../redux/actions/change-task.actions';
import { postBug } from '../redux/actions/bugs.actions';
import { setMenu } from '../redux/actions/user.actions';

const styles = {
    ...theme
};

const Home = props => {
    const errors = useSelector(state => state.UI.errors);
    const newTask = useSelector(state => state.newTask);
    const tasks = useSelector(state => state.tasks);
    const reportExists = useSelector(state => state.UI.reportDone); 

    const [task, setTask] = useState({});
    const [durty, setDurty] = useState(false);
    const [reportDone, setReportDone] = useState(false);
    const [isTaskNew, setTaskStatus] = useState(false);
    const [view, setView] = useState('');

    const [foundationIndex, setFoundationIndex] = useState(0);

    const [openBugReport, setOpenBugReport] = useState(false);
    const [openLoginWarning, setOpenLoginWarning] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [message, setMessage] = useState('');
    const [confirmHandle, setConfirmHandle] = useState(() => {});

    let newTaskId; // need to refactoring

    useEffect(() => {
        const taskId = props.match.params.taskId;
        if (task.taskId !== taskId) {
            if (taskId === 'newtask') {
                setNewTask();
            } else {
                if (tasks.task.taskId === taskId) {
                    setExistingTask();
                } else {
                    newTaskId = taskId;
                    if (tasks.durty) {
                        setExistingTask();
                        setMessage(`Задача ${tasks.task.title} не сохранена. Все изменения пропадут, продолжить?`)
                        setConfirmHandle(() => getTask);
                        setOpenConfirm(true);
                    } else {
                        getTask();
                    }
                }
            }
        }
        if (task.taskId === taskId) {
            if (taskId === 'newtask') {
                // first loading new task
                setNewTask();
            } else {
                console.warn('strange case');
            }
        }
    }, [props.match.params.taskId]);

    useEffect(() => {
        const taskId = props.match.params.taskId;
        if (taskId === 'newtask') {
            setNewTask();
        }
    }, [newTask.task]);

    useEffect(() => {
        const taskId = props.match.params.taskId;
        if (taskId === tasks.task.taskId) {
            setExistingTask();
        }
    }, [tasks.task]);

    const getTask = () => {
        props.getTask(newTaskId);
        handleCloseWithoutSending();
    }

    useEffect(() => {
        if (!reportExists) {
            return;
        }

        setView('report');
    }, [reportExists])

    const setNewTask = () => {
        setTask(newTask.task);
        setDurty(newTask.durty);
        setTaskStatus(true);
        setReportDone(newTask.task?.report?.length > 0);
    }

    const setExistingTask = () => {
        setTask({ ...tasks.task });
        setDurty(tasks.durty);
        setTaskStatus(false);
        setReportDone(tasks.task?.report?.length > 0);
    }

    const handleInputChange = (key, name, value) => {
        props.taskInputChange(key, name, value, isTaskNew);
    };

    // For soils only
    const handleAdd = (e, key) => {
        props.taskAddRow(key, isTaskNew);
    };

    const handleRemove = (index, key) => {
        props.taskDeleteRow(key, index, isTaskNew);
    };

    // For well and soils
    const handleTableChange = (e, index, key) => {
        const name = e.target.name;
        const value = e.target.value;
        props.taskTableChange(key, name, index, value, isTaskNew);
    };

    const handleChangeColor = index => {
        props.taskColorChange(index, isTaskNew);
    };

    const handleConfirmResetTask = () => {
        setMessage(`Задача не сохранена. Вы уверены, что хотите ${isTaskNew ? 'обнулить данные' : 'закрыть'} ?`);
        setConfirmHandle(() => handleResetTask);
        setOpenConfirm(true);
    };
    const handleResetTask = () => {
        props.taskReset(isTaskNew);
        if (isTaskNew) {
            setView('general');
        } else {
            props.history.push('/');
        }
        handleCloseWithoutSending();
    };
    // Change main view
    const handleInputPanelClick = (e, name) => {
        setView(name);
    };
    // Task manage
    const handleSubmit = () => {
        props.doTask(task, isTaskNew);
    };
    const handlePost = () => {
        if (props.user.authenticated) {
            props.postTask(task, props.user.credentials.userId, props.history, isTaskNew);
            // setReportDone(false);
        } else {
            setMessage('сохранить задачу');
            setOpenLoginWarning(true);
        }
    };
    const handleUpdate = () => {
        props.updateTask(task);
        // setReportDone(false);
    };
    const handleReport = (e, index) => {
        setView('report');
        setFoundationIndex(index);
    };
    // Report bug
    const handleReportBug = () => {
        if (props.user.authenticated) {
            setOpenBugReport(true);
        } else {
            setMessage('отправить сообщение об ошибке');
            setOpenLoginWarning(true);
        }
    };
    const handleSendBugReport = description => {
        const { userHandle, taskId, title } = task;
        props.postBug(userHandle, taskId, title, description);
        setOpenBugReport(false);
    };
    const handleCloseWithoutSending = () => {
        setOpenBugReport(false);
        setOpenLoginWarning(false);
        setOpenConfirm(false);
    };

    const { classes } = props;

    return (
        <Grid container spacing={2} className={classes.mainGrid}>
            {/*<Prompt
          when={this.state.taskChanged}
          message={'Данные не сохранены. Вы действительно хотите покинуть задачу без сохранения данных?'}
        />*/}
            <Grid item xs={3} id='taskMenu' className={classes.itemGrid + " taskMenu"}>
                <InputPanel
                    handlePanelClick={handleInputPanelClick}
                    handleSubmit={handleSubmit}
                    handlePost={handlePost}
                    handleUpdate={handleUpdate}
                    handleReportBug={handleReportBug}
                    handleResetTask={handleConfirmResetTask}
                    taskId={task.taskId}
                    isTaskNew={isTaskNew}
                    taskChanged={durty}
                    reportDone={reportDone}
                    batchCalc={task.levels?.batchCalc}
                    error={errors}
                />
            </Grid>
            <Grid item xs={9} className={classes.itemGrid + " paperContainer"}>
                <Paper id='mainPaper' className={classes.paperSheet}>
                    {(() => {
                        switch (view) {
                            case 'general':
                                return (
                                    <GeneralData
                                        state={task.general}
                                        title={task.title}
                                        code={task.code}
                                        errors={errors}
                                        description={task.description}
                                        handleChange={handleInputChange}
                                    />
                                );
                            case 'soils':
                                return (
                                    <SoilsData
                                        handleAdd={handleAdd}
                                        handleRemove={handleRemove}
                                        handleTableChange={handleTableChange}
                                        handleChangeColor={handleChangeColor}
                                        data={task.soilsData}
                                        tableValues={task.tableValues}
                                        errors={errors}
                                        handleChange={handleInputChange}
                                        gamma2s={task.gamma2s}
                                    />
                                );
                            case 'levels':
                                return (
                                    <LevelsData handleChange={handleInputChange} state={task.levels} errors={errors} />
                                );
                            case 'loads':
                                return (
                                    <LoadsData handleChange={handleInputChange} state={task.loads} errors={errors} />
                                );
                            case 'draft':
                                return (
                                    <Draft
                                        data={task}
                                        errors={errors}
                                        handleChange={handleTableChange}
                                        handleAdd={handleAdd}
                                        handleRemove={handleRemove}
                                        handleChangeColor={handleChangeColor}
                                    />
                                );
                            case 'report':
                                return (
                                    <Report
                                        task={task}
                                        user={props.user.credentials.handle}
                                        taskChanged={durty}
                                        index={task?.report?.length !== 1 ? foundationIndex : 0}
                                        reportDone={reportDone}
                                    />
                                );
                            case 'draw':
                                return <Draw />;
                            case 'analysis':
                                return <Analysis data={task} reportDone={reportDone} handleReport={handleReport} />;
                            default:
                                return (
                                    <GeneralData
                                        state={task.general}
                                        title={task.title}
                                        code={task.code}
                                        errors={errors}
                                        description={task.description}
                                        handleChange={handleInputChange}
                                    />
                                );
                        }
                    })(view)}
                </Paper>
            </Grid>

            <ReportBugDialog
                open={openBugReport}
                handleAgreed={handleSendBugReport}
                handleClose={handleCloseWithoutSending}
            />

            <LoginWarningDialog message={message} open={openLoginWarning} handleClose={handleCloseWithoutSending} />

            <ConfirmDialog
                message={message}
                open={openConfirm}
                handleClose={handleCloseWithoutSending}
                handleConfirm={confirmHandle}
            />
        </Grid>
    );
};

const mapActionsToProps = {
    getTask,
    postTask,
    updateTask,
    doTask,
    setMenu,
    postBug,
    taskInputChange,
    taskAddRow,
    taskDeleteRow,
    taskTableChange,
    taskColorChange,
    taskReset
};

const mapStateToProps = state => ({
    user: state.user,
    UI: state.UI
});

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(Home));
// 414-339
