import React from 'react';

import header from '../../../util/soils-tab-headers';
import soilsDecoder from '../../../util/soils-decoder';

import { Task } from 'client/src/models/task.model';

const SoilsRep = ({ data }: { data: Task }) => {
    return (
        <article style={{ textAlign: 'center' }}>
            <table className='soilsTable'>
                <thead>
                    <tr>
                        <th>#</th>
                        {header.map((item, index) => (
                            <th key={`pr${index}`}>{item.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.soilsData.map((item, index) => (
                        <tr key={`prd${index}`}>
                            <td>{index + 1}</td>
                            <td>
                                <div style={{ backgroundColor: item.color }}>&nbsp;&nbsp;</div>
                            </td>
                            {header.map((type, k) => {
                                const decoder = soilsDecoder((item as any)[type.field], true);
                                return type.field !== 'color' && <td key={`prs${index}${k}`}>{decoder}</td>;
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
            <br />
            <div style={{ textAlign: 'left' }}>
                {data.tableValues
                    ? 'Значения приняты по таблицам СП'
                    : 'Значения определены непосредственными испытаниями'}
            </div>
        </article>
    );
};

export default SoilsRep;
