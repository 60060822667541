import React, { Fragment } from "react";

import dayjs from "dayjs";
import "dayjs/locale/ru";

const GeneralRep = props => {
  const { data, user, index } = props;
  return (
    <article style={{ textAlign: "left" }}>
      Имя задачи: <em>{data.title}</em>
      <br />
      Описание: <em>{data.description}</em>
      <br />
      Автор: <em>{user}</em>
      <br />
      Дата создания:{" "}
      <em>
        {data.createdAt ? dayjs(data.createdAt).format("DD.MM.YYYY") : "..."}
      </em>
      <br />
      Последние изменения:{" "}
      <em>
        {data.lastChange ? dayjs(data.lastChange).format("DD.MM.YYYY") : "..."}
      </em>
      <br />
      Количество изменений: <em>{data.numberOfChange}</em>
      <br />
      <br />
      Конструктивная схема здания{" "}
      {!data.general.schema
        ? "гибкая"
        : `жесткая, с соотношением L/H = ${data.general.lSize}/${data.general.hSize}`}
      <br />
      Подвал{" "}
      {data.general.basement ? (
        <Fragment>
          со следующими характеристиками: <br />h<sub>s</sub>
          {` = ${data.general.hs} м`} - толщина пола подвала <br />h
          <sub>cf</sub>
          {` = ${data.general.hcf} м`} - толщина слоя грунта под полом подвала
          <br />
          &gamma;<sub>cf</sub>
          {` = ${data.general.gammacf} т/м`}
          <sup>3</sup> - осреднённое значени удельного веса конструкции пола и
          слоя грунта
        </Fragment>
      ) : (
        "отсутствует."
      )}
      <br />
      Габариты подколонника{" "}
      {data.general.column ? (
        <Fragment>
          :<br />b<sub>c</sub>
          {` = ${data.general.bColumn} м`} <br />l<sub>c</sub>
          {` = ${data.general.lColumn} м`}
        </Fragment>
      ) : (
        <Fragment>
          :<br />b<sub>c</sub> = <mark>0.9</mark> м<br />l<sub>c</sub> ={" "}
          <mark>0.9</mark> м
        </Fragment>
      )}
      <br />
      {data.general.checkExisting ? (
        <Fragment>
          Осуществлялась проверка сущуствующего фундамента{" "}
          {data.general.roundFoot ? (
            <Fragment>
              с подошвой круглой формы, диаметром:
              <br />D = {data.general.diam} м
            </Fragment>
          ) : (
            <Fragment>
              с габаритами подошвы:
              <br />b<sub>сущ</sub> = {data.general.bExisting} м<br />l
              <sub>сущ</sub> = {data.general.lExisting} м
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Fragment>
          Осуществляется подбор оптимальных габаритов подошвы{" "}
          {data.general.roundFoot ? (
            <Fragment>
              круглой формы
              <br />
            </Fragment>
          ) : (
            <Fragment>
              прямоугольной формы с:
              <br />l / b ={" "}
              {data.general.lb && parseFloat(data.general.lb) !== 0 ? (
                data.general.lb
              ) : (
                <Fragment>
                  <mark>1.5</mark>
                </Fragment>
              )}{" "}
              - максимально допустимое соотношение сторон
              <br />
            </Fragment>
          )}
          Допустимая форма эпюры{" "}
          {data.general.sepLimit || parseFloat(data.general.sepLimit) === 0 ? (
            data.general.sepLimit
          ) : (
            <Fragment>
              <mark>0</mark>
            </Fragment>
          )}
          <br />
          Модуль кратности{" "}
          {data.general.sizeModule ? (
            data.general.sizeModule
          ) : (
            <Fragment>
              <mark>0.3</mark>
            </Fragment>
          )}{" "}
          м
        </Fragment>
      )}
      <br />
      {data.general.preparation ? (
        <Fragment>
          {data.general.variablePreparation ? (
            <Fragment>
              Под фундаментом подготовка переменной жесткости, расчётное
              сопротивление R под подошвой фундамента будет увеличено вдвое
              п.5.6.20 <a href="#source01">[1]</a><br/>
            </Fragment>
          ) : null}
          Учитывается толщина подготовки h<sub>p</sub> = {data.general.hp} м<br/>
        </Fragment>
      ) : null}
      Максимально допустимая осадка s<sub>max</sub> = {data.general.smax} см
      <br />
      Толщина i-го слоя при определении осадки h<sub>i</sub> =
      {data.general.soilThickness ? (
        data.general.soilThickness
      ) : (
        <Fragment>
          <mark>{(data.report[index].result.b * 0.4).toFixed(2)}</mark>
        </Fragment>
      )}{" "}
      м
    </article>
  );
};

export default GeneralRep;
