import React from 'react';

import theme from '../../util/theme';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Input from '@material-ui/core/Input';

const styles = {
  ...theme,
  tabInput: {
    marginTop: 0
  }
};

const TabInput = props => {
  const { classes, name, index, value, handleChange , error } = props;
  return (
    <div className={classes.tabInput}>
      <Input
        error={error ? true : false}
        id={name}
        name={name}
        type="number"
        value={value}
        onChange={e => handleChange(e, index, "soilsData")}
      />
    </div>
  );
};

export default withStyles(styles)(TabInput);
