import React from 'react';

import header from '../../util/soils-tab-headers';

import { Soil } from 'client/src/models/soil.model';

// Components
import SimpleTooltip from '../helpers/simple-tooltip.component';
import SoilsTableCell from './soils-table-cell.component';
import SoilsTableRow from './soils-table-row.component';

// Mui Stuff
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const SoilsTable = ({
    data,
    handleRemove,
    handleChange,
    handleChangeColor,
    errors
}: {
    data: Soil[];
    handleRemove: (index: number, key: string) => void;
    handleChange: (key: any, name: number, value: string) => void;
    handleChangeColor: (index: number) => void;
    errors: any;
    classes: any;
}) => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <SoilsTableCell></SoilsTableCell>
                    <SoilsTableCell>#</SoilsTableCell>
                    {header.map((element, index) => (
                        <SoilsTableCell key={`thc-${index}`}>
                            {element.field === 'Ee' || element.field === 'rmax' ? (
                                <SimpleTooltip titleKey={element.field} placement={'left-start'} text={element.title} />
                            ) : (
                                element.title
                            )}
                        </SoilsTableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((element, index) =>
                    SoilsTableRow(element, index, handleRemove, handleChange, handleChangeColor, errors)
                )}
            </TableBody>
        </Table>
    );
};

export default SoilsTable;
