import React from 'react';

import tooltips from '../../util/tooltips';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';

// icon
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const HelpTooltip = props => {
  const { titleKey } = props;
  return (
    <HtmlTooltip placement="top-start" title={tooltips[titleKey]}>
      <HelpOutlineIcon fontSize="small"
        color="action"
      />
    </HtmlTooltip>
  );
};

export default HelpTooltip;
