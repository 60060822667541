import { Draft } from 'client/src/models/draft/draft.model';

/* remove 16.09.2022 */
export const getLinePath = (y: number, column: number, draft: Draft, continuous?: boolean): string => {
    const { rec1, rec2, centerX, limit } = draft;
    let path = '';

    if (continuous || y < rec1.up || y > rec2.down) {
        path += `M ${centerX - (limit /*- column / 2*/)} ${y} ${centerX + (limit /*- column / 2*/)} ${y}`;
    } else if (y >= rec1.up && y < rec1.down) {
        path += `M ${centerX - (limit /*- column / 2*/)} ${y} ${rec1.left} ${y} M ${centerX + (limit /*- column / 2*/)} ${y} ${
            rec1.right
        } ${y}`;
    } else {
        path += `M ${centerX - (limit /*- column / 2*/)} ${y} ${rec2.left} ${y} M ${centerX + (limit /*- column / 2*/)} ${y} ${
            rec2.right
        } ${y}`;
    }
    return path;
};
