import React from 'react';

import theme from '../../util/theme';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Icons
import MenuIcon from '@material-ui/icons/Menu';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const styles = {
  ...theme,
  searchPanel: {
    display: 'flex',
    alignItem: 'center'
  },
  input: {
    marginLeft: 10,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
};

const options = ['датa создания', 'датa изменения', 'имя задачи'];

const TasksSearchbar = props => {
  const { classes, anchorEl, selectedIndex, loading, descending, searchBar} = props;
  return (
    <div className={classes.searchPanel}>
      <IconButton
        className={classes.iconButton}
        aria-controls="sort-menu"
        aria-haspopup="true"
        onClick={props.handleMenuClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="sort-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={props.handleClose}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            disabled={index === selectedIndex}
            selected={index === selectedIndex}
            onClick={e => props.handleMenuItemClick(e, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      <Tooltip placement="top" title="возрастание/убывание">
        <IconButton
          color="default"
          className={classes.iconButton}
          onClick={props.handleFilterToggle}
        >
          {descending ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
        </IconButton>
      </Tooltip>
      <InputBase
        className={classes.input}
        placeholder="Поиск"
        fullWidth
        onChange={props.handleChange}
        value={searchBar}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <Tooltip placement="top" title="обновить">
        <IconButton
          color={loading ? 'primary' : 'default'}
          className={classes.iconButton}
          onClick={props.handleRefreshTasksList}
        >
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default withStyles(styles)(TasksSearchbar);
