import { Task } from '../models/task.model';
import { SoilHatch } from '../models/draft/hatchs';

import { svgNS } from './draft/default.draft';
import { getDraft } from './draft/get-draft.draft';
import { getPath } from './draft/get-path.draft';
import { getLinePath } from './draft/get-line-path.draft';

import { clayHatch } from './svg/clay-hatch.svg';
import { trashHatch } from './svg/trash-hatch.svg';
import { sandHatch } from './svg/sand-hatch.svg';
import { grid } from './svg/grig.svg';
import { polyMaker } from './svg/polyline.svg';
import { pathMaker } from './svg/path.svg';
import { lineMaker } from './svg/line-maker.svg';
import { levelMark } from './svg/level-mark.svg';

import soilsDecoder from './soils-decoder';
import { getWaterRectangle } from './draft/get-water-rectangle.draft';

const renderDraft = (data: Task, id: string, index?: number): string => {
    const svg: SVGSVGElement | null = document.querySelector(`#${id}`);
    if (!svg) {
        return 'SVG элемент не найден';
    }

    const draft = getDraft(data, index, false); // get all coordinate
    const { centerX, centerY, bodyCode, scale } = draft;
    const { nl, dl, cl, fl, wl, al } = draft.levels;
    const { footing, column, height } = draft.foundation;
    const { offset } = draft.levelMark;
    const { well, soilsData } = data;
    const defs: SVGDefsElement = document.createElementNS(svgNS, 'defs');
    const heightSVG: number = svg.height.baseVal.value;
    const widthSVG: number = svg.width.baseVal.value;

    let line, path, pathBg, temp, g, title;

    if (height / scale < 0.3) {
        return 'высота фундамента слишком мала';
    }

    const soilsThickness: number = data.well.reduce((total, item) => total + Number(item.thickness), 0);
    const newHeight: number = soilsThickness * scale - nl + 150 + 30;
    svg.setAttribute('viewBox', `0 0 ${widthSVG} ${Math.max(newHeight, heightSVG)}`);

    svg.innerHTML = ''; // cleanup

    // Prepearing defs
    defs.appendChild(trashHatch());
    defs.appendChild(clayHatch());
    svg.appendChild(defs);

    if (draft.sandToggle) {
        // @@@ This condition is very interesting
        defs.appendChild(draft.sandHatch);
    } else {
        draft.sandHatch = sandHatch();
        defs.appendChild(draft.sandHatch);
        draft.sandToggle = true;
    }

    // Add to svg
    svg.appendChild(grid(widthSVG, Math.max(newHeight, heightSVG), draft)); // Grid
    // Body
    svg.appendChild(
        polyMaker({
            title: 'Фундамент',
            id: 'fBody1',
            class: 'fBody',
            points: bodyCode,
        })
    );
    temp = polyMaker({
        title: 'Фундамент',
        id: 'fBody2',
        class: 'fBody',
        points: bodyCode,
    });
    temp.setAttribute('transform', `scale(-1, 1) translate(${-centerX * 2},0)`);
    svg.appendChild(temp);

    // Soils
    draft.soils.push(centerY - nl);
    for (let i = 0; i < well.length; i++) {
        if (!well[i].thickness || (!well[i].soil && well[i].soil !== 0)) {
            continue;
        }
        g = document.createElementNS(svgNS, 'g');
        g.setAttribute('id', `soilGroup${i + 1}`);

        const { paths, down } = getPath(draft, well[i].thickness, i, draft.soils[i], i === well.length - 1);
        draft.soils.push(down);

        path = pathMaker({ title: ``, id: ``, class: '', d: paths[0] });
        if (soilsData[well[i].soil].type === 'clay') {
            path.setAttribute('fill', `url(#${SoilHatch.clayHatch})`);
        } else {
            path.setAttribute('fill', `url(#${SoilHatch.sandHatch})`);
        }
        g.appendChild(path);

        title = `Слой ${well[i].soil + 1}\n`;
        title += `${soilsDecoder(soilsData[well[i].soil].type)} ${soilsDecoder(
            soilsData[well[i].soil].structure
        )} ${soilsDecoder(soilsData[well[i].soil].moisture)}\n`;
        title += `${soilsData[well[i].soil].il ? 'Iₗ=' + soilsData[well[i].soil].il + '; ' : ''}E=${
            soilsData[well[i].soil].E
        } т/м²; e=${soilsData[well[i].soil].e}\n`;
        title += `C₂=${soilsData[well[i].soil].c2}т/м²; φ₂=${soilsData[well[i].soil].phi2}°; γ₂=${
            soilsData[well[i].soil].gamma2
        }т/м³\n`;
        title += `γₛ=${soilsData[well[i].soil].gammas}т/м³\n`;
        pathBg = pathMaker({ title: title, id: `soil${i}`, class: 'soils', d: paths[0] });
        pathBg.setAttribute('fill', soilsData[well[i].soil].color);
        g.appendChild(pathBg);
        if (paths[1]) {
            path = pathMaker({
                title: ``,
                id: ``,
                class: 'secondaryLine',
                d: paths[1],
            });
            g.appendChild(path);
        }
        g.setAttribute('fill', soilsData[data.well[i].soil].color);
        svg.appendChild(g);

        // levels
        if (i < well.length - 1) {
            const level = down - draft.soils[0] - nl;
            line = lineMaker({
                class: 'dimLine',
                x1: centerX - footing - 60,
                y1: centerY + level,
                x2: centerX - footing + 20,
                y2: centerY + level,
            });
            svg.appendChild(
                levelMark(
                    centerX - footing - 45,
                    centerY + level,
                    level / -scale,
                    '',
                    true,
                    true,
                    line,
                    draft.levelMark
                )
            );
        }
    }

    // CL
    line = lineMaker({
        class: 'dimLine',
        x1: centerX + column * 1.1,
        y1: centerY - cl,
        x2: centerX + footing + 60,
        y2: centerY - cl,
    });
    svg.appendChild(
        levelMark(centerX + footing + 45, centerY - cl + offset, cl / 100, 'CL', false, false, line, draft.levelMark)
    );

    // DL
    if (dl !== nl) {
        path = pathMaker({
            title: ``,
            id: ``,
            class: 'secondaryLine dlLine',
            d: getLinePath(centerY - dl, column, draft),
        });
        svg.appendChild(path);
        line = lineMaker({
            class: 'dimLine',
            x1: centerX - footing - 60,
            y1: centerY - dl,
            x2: centerX - footing + 20,
            y2: centerY - dl,
        });
        svg.appendChild(
            levelMark(
                centerX - footing - 45,
                centerY - dl + (dl > nl ? offset : -offset),
                dl / 100,
                'DL',
                dl > nl ? false : true,
                true,
                line,
                draft.levelMark
            )
        );
    }

    // NL
    line = lineMaker({
        class: 'dimLine',
        x1: centerX - footing - 60,
        y1: centerY - nl,
        x2: centerX - footing + 20,
        y2: centerY - nl,
    });
    svg.appendChild(
        levelMark(
            centerX - footing - 45,
            centerY - nl - (dl > nl ? offset : -offset),
            nl / 100,
            dl !== nl ? 'NL' : 'DL,NL',
            dl > nl ? true : false,
            true,
            line,
            draft.levelMark
        )
    );

    // FL
    line = lineMaker({
        class: 'dimLine',
        x1: centerX + (footing * 1.1) / 2,
        y1: centerY - fl,
        x2: centerX + footing + 60,
        y2: centerY - fl,
    });
    svg.appendChild(
        levelMark(
            centerX + footing + 45,
            centerY - fl + offset,
            fl / 100,
            'FL',
            wl ? fl < wl : false,
            false,
            line,
            draft.levelMark
        )
    );

    // WL && AL
    if (wl !== undefined && Math.abs(wl - nl) < soilsThickness * scale) {
        path = pathMaker({
            title: ``,
            id: ``,
            class: 'secondaryLine wlLine',
            d: getLinePath(centerY - wl, column, draft, true),
        });
        svg.appendChild(path);

        line = lineMaker({
            class: 'dimLine',
            x1: centerX + column * 2,
            y1: centerY - wl,
            x2: centerX + footing + 60,
            y2: centerY - wl,
        });

        svg.appendChild(
            levelMark(
                centerX + footing + 45,
                centerY - wl + offset,
                wl / 100,
                'WL',
                fl > wl,
                false,
                line,
                draft.levelMark
            )
        );

        if (al !== undefined && Math.abs(al - nl) < soilsThickness * scale) {
            path = pathMaker({
                title: ``,
                id: ``,
                class: 'secondaryLine wlLine',
                d: getLinePath(centerY - al, column, draft, true),
            });
            svg.appendChild(path);

            line = lineMaker({
                class: 'dimLine',
                x1: centerX + column * 2,
                y1: centerY - al,
                x2: centerX + footing + 60,
                y2: centerY - al,
            });
            svg.appendChild(
                levelMark(
                    centerX + footing + 45,
                    centerY - al + offset,
                    al / 100,
                    'AL',
                    true,
                    false,
                    line,
                    draft.levelMark
                )
            );
        }

        const bottomBorder = Math.min(al ? centerY - al : Infinity, centerY - nl + soilsThickness * scale);
        path = pathMaker({
            title: ``,
            id: ``,
            class: 'waterBg',
            d: getWaterRectangle(draft, wl, bottomBorder),
        });
        svg.appendChild(path);
    }

    // down of soils
    if (soilsThickness) {
        line = lineMaker({
            class: 'dimLine',
            x1: centerX - footing - 60,
            y1: centerY - nl + soilsThickness * scale,
            x2: centerX - footing + 20,
            y2: centerY - nl + soilsThickness * scale,
        });
        svg.appendChild(
            levelMark(
                centerX - footing - 45,
                centerY - nl + soilsThickness * scale + offset,
                (nl - soilsThickness * scale) / 100,
                '',
                false,
                true,
                line,
                draft.levelMark
            )
        );
    }

    // Batch calc
    if (data.levels.batchCalc && data.levels.AFL) {
        line = lineMaker({
            class: 'aflLine',
            x1: centerX - footing - 20,
            y1: centerY - data.levels.AFL * scale,
            x2: centerX + footing + 20,
            y2: centerY - data.levels.AFL * scale,
        });
        svg.appendChild(line);
        line = lineMaker({
            // AFL
            class: 'dimLine',
            x1: centerX + footing + 60,
            y1: centerY - data.levels.AFL * scale,
            x2: centerX + footing + 20,
            y2: centerY - data.levels.AFL * scale,
        });
        svg.appendChild(
            levelMark(
                centerX + footing + 45,
                centerY - data.levels.AFL * scale + offset,
                data.levels.AFL,
                'AFL',
                false,
                false,
                line,
                draft.levelMark
            )
        );
    }

    return '';
};

export default renderDraft;
