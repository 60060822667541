import axios from 'axios';
import { Task } from 'client/src/models/task.model';

import { getDefaultTask } from '../../util/default-task';
import { checkTaskBeforeCalc, checkTaskBeforeSave } from '../../util/task-validator';
import { AppDispatch } from '../store';
import { TaskAction, TaskListAction, UIAction } from '../types';
import store from '../store';
import { LocalStorageTaskKey } from '../../models/local-storage-task-key';
import { resetStorage } from '../local-storage';

export const getTask = (taskId: string) => (dispatch: AppDispatch) => {
    dispatch({ type: UIAction.StartLoading });
    axios
        .get(`/task/${taskId}`)
        .then(res => {
            dispatch({
                type: TaskAction.Set,
                payload: { ...res.data, taskId: taskId }
            });
            resetStorage(LocalStorageTaskKey.Task);
            dispatch({ type: UIAction.StopLoading });
        })
        .catch(err => console.error(err));
};

// TODO something with any
export const postTask =
    (taskData: Task, userHandle: string, history: any, isTaskNew: boolean) => (dispatch: AppDispatch) => {
        const { errors, valid } = checkTaskBeforeSave(taskData);
        if (!valid) {
            dispatch({
                type: UIAction.SetErrors,
                payload: errors
            });
            return;
        }

        // normalize
        dispatch({ type: UIAction.StartLoading });
        delete taskData.taskId;
        delete taskData.report;
        taskData.userHandle = userHandle;
        taskData.createdAt = new Date().toISOString();
        taskData.lastChanges = new Date().toISOString();
        taskData.numberOfChanges = 0;
        taskData.code = getDefaultTask().code;
        axios
            .post('/tasks', taskData)
            .then(res => {
                if (isTaskNew && store.getState().tasks.durty) {
                    dispatch({ type: TaskAction.Reset, payload: { isTaskNew } });
                    history.push(`/`);
                } else {
                    dispatch({
                        type: TaskAction.Post,
                        payload: { ...taskData, taskId: res.data }
                    });
                    history.push(`/task/${res.data}`);
                }

                dispatch({ type: TaskAction.SaveToggle });
                // TODO need to refactoring - add to existen tasks
                // TODO do something with any
                dispatch(getTasksList() as any);
                dispatch(clearErrors() as any);
                // return res;
            })
            .catch(err => {
                dispatch({
                    type: UIAction.SetErrors,
                    payload: err.response.data
                });
            });
    };

export const updateTask = (taskData: Task) => (dispatch: AppDispatch) => {
    const { errors, valid } = checkTaskBeforeSave(taskData);
    if (!valid) {
        dispatch({
            type: UIAction.SetErrors,
            payload: errors
        });
        return;
    }

    // normalize
    dispatch({ type: UIAction.StartLoading });
    const taskId = taskData.taskId;
    delete taskData.taskId;
    delete taskData.report;
    taskData.lastChanges = new Date().toISOString();
    taskData.numberOfChanges++;
    axios
        .post(`/task/${taskId}`, taskData)
        .then(() => {
            dispatch({
                type: TaskAction.Update,
                payload: { ...taskData, taskId: taskId }
            });
            dispatch({ type: TaskAction.UpdateToggle });
            // TODO need to refactoring - add to existen tasks
            // TODO do something with any
            dispatch(getTasksList() as any);
            dispatch(clearErrors() as any);
        })
        .catch(err => {
            dispatch({
                type: UIAction.SetErrors,
                payload: err.response.data
            });
        });
};

export const doTask = (taskData: Task, isTaskNew: boolean) => (dispatch: AppDispatch) => {
    const { errors, valid } = checkTaskBeforeCalc(taskData);
    if (!valid) {
        dispatch({
            type: UIAction.SetErrors,
            payload: errors
        });
        return;
    }
    dispatch({ type: UIAction.StartLoading });
    axios
        .post(`/calc/${taskData.code}`, taskData)
        .then(res => {
            dispatch({
                type: TaskAction.Do,
                payload: { report: res.data, isTaskNew }
            });
            dispatch({ type: UIAction.ReportDone });
            // TODO do something with any
            dispatch(clearErrors() as any);
        })
        .catch(err => {
            dispatch({
                type: UIAction.SetErrors,
                payload: err.response.data
            });
        });
};

export const deleteTask = (taskId: Task) => (dispatch: AppDispatch) => {
    axios
        .delete(`/task/${taskId}`)
        .then(() => {
            const existingTaskId = store.getState().tasks.task.taskId;
            if (taskId === existingTaskId) {
                dispatch({ type: TaskAction.Delete });
            }
            // TODO need to refactoring - delete from existen tasks
            // TODO do something with any
            dispatch(getTasksList() as any);
        })
        .catch(err => console.error(err));
};

export const getTasksList = () => (dispatch: AppDispatch) => {
    dispatch({ type: UIAction.StartLoading });
    axios
        .get(`/tasks`)
        .then(res => {
            dispatch({
                type: TaskListAction.Set,
                payload: res.data
            });
            dispatch({ type: UIAction.ClearErrors });
        })
        .catch(err => {
            dispatch({
                type: UIAction.SetErrors,
                payload: err.response.data
            });
        });
};

const clearErrors = () => (dispatch: AppDispatch) => {
    dispatch({
        type: UIAction.ClearErrors
    });
};
