export enum SoilType {
    coarseGrained = 'coarse',
    sand = 'sand',
    sandLoam = 'sandLoam',
    loam = 'loam',
    clay = 'clay',
    // fillUp = 'fillUp',
    // unknow = 'unknow'
};

export enum SpecificClay {
    clay25 = 'clay25',
    clay50 = 'clay50',
    clay100 = 'clay100'
}

export enum CoarseStructureType {
    sandy = 'sandy',
    clayly = 'clayly',
}

export enum SandStructureType {
    same = 'same',
    small = 'small',
    dust = 'dust',
    loose = 'loose'
}

export enum MoistureType {
    semiwet = 'semiwet',
    wet = 'wet'
}

export interface Soil {
    color: string;
    type: SoilType;
    c1: number;
    c2: number;
    phi1: number;
    phi2: number;
    gamma2: number;
    gammas: number;
    e: number;
    E: number;
    Ee?: number;
    rmax?: number;
    
    moisture?: MoistureType;
    structure?: SandStructureType | CoarseStructureType;
    il?: number;
}