import axios from 'axios';
import { getBugs } from './bugs.actions';
import { AppDispatch } from '../store';
import { AdminAction, TaskListAction, UIAction, UserAction } from '../types';

// TODO remove any
export const loginUser = (userData: any, history: any) => (dispatch: AppDispatch) => {
  dispatch({ type: UIAction.StartLoading });
  axios
    .post('/login', userData)
    .then(res => {
      setAuthorizationHeader(res.data.token);
      dispatch(getUserData() as any);
      history.push('/');
    })
    .catch(err => {
      dispatch({
        type: UIAction.SetErrors,
        payload: err.response.data
      });
    });
};

export const signupUser = (newUserData: any, history: any) => (dispatch: AppDispatch) => {
  dispatch({ type: UIAction.StartLoading });
  axios
    .post('/signup', newUserData)
    .then(res => {
      setAuthorizationHeader(res.data.token);
      dispatch(getUserData() as any);
      history.push('/');
    })
    .catch(err => {
      dispatch({
        type: UIAction.SetErrors,
        payload: err.response.data
      });
    });
};

export const logoutUser = () => (dispatch: AppDispatch) => {
  localStorage.removeItem('FBIdMeduzaToken');
  delete axios.defaults.headers.common['Authorization'];
  dispatch({ type: UserAction.SetUnAuth });
};

export const getUserData = () => (dispatch: AppDispatch) => {
  axios
    .get('/user')
    .then(res => {
      dispatch({
        type: UserAction.SetUser,
        payload: res.data.credentials
      });
      dispatch({
        type: TaskListAction.Set,
        payload: res.data.tasks
      });
      
      const admin = res.data.credentials.admin;
      if (admin) {
        dispatch({
          type: AdminAction.SetAllTakskList,
          payload: res.data.allTasks
        });
        dispatch({
          type: AdminAction.SetAllUsersList,
          payload: res.data.allUsers
        });
        dispatch(getBugs() as any);
      }
      dispatch({ type: UIAction.ClearErrors });
    })
    .catch(err => console.log(err));
};

export const setMenu = (status: boolean) => (dispatch: AppDispatch) => {
  dispatch({ type: UIAction.ClearErrors });
  dispatch({
    type: UIAction.SetMenu,
    payload: status
  });
};

const setAuthorizationHeader = (token: string) => {
  const FBIdMeduzaToken = `Bearer ${token}`;
  localStorage.setItem('FBIdMeduzaToken', FBIdMeduzaToken);
  axios.defaults.headers.common['Authorization'] = FBIdMeduzaToken;
};
