import React from 'react';

// Components
import ViewReport from './view-report/view-report.component';

import theme from '../../util/theme';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

// Icons
import Print from '@material-ui/icons/Print';
import { Task } from 'client/src/models/task.model';

const styles = {
    ...theme,
};

const Report = ({
    classes,
    task,
    user,
    index,
    reportDone,
}: {
    classes: any; // @@@ REFACTOR MUI AND GET RID OFF
    task: Task;
    user: any; // @@@ ADD MODEL AND MOVE TO STATE AND GET RID OFF
    index: number;
    reportDone: boolean;
}) => {
    const printReport = () => window.print(); // @@@ NEED TO GENERATE PDF

    return reportDone ? (
        <div className={classes.dataList}>
            <div id='printTitle'>
                <Typography component='span' variant='subtitle1'>
                    Отчёт
                </Typography>
                <IconButton onClick={printReport}>
                    <Print />
                </IconButton>
            </div>
            <div>
                <ViewReport data={task} user={user} index={index} />
            </div>
        </div>
    ) : (
        'Необходимо выполнить расчёт'
    );
};

export default withStyles(styles as any)(Report as any);
