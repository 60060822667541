import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import theme from '../util/theme';
//import AppIcon from '../images/app.png';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux stuff
import { connect } from 'react-redux';
import { signupUser } from '../redux/actions/user.actions';

const styles = {
  ...theme
};

class signup extends Component {
  constructor() {
    super();

    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      handle: '',
      errors: {}
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const newUserData = {
      email: this.state.email,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      handle: this.state.handle
    };
    this.props.signupUser(newUserData, this.props.history);
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    const { classes , UI: { loading } } = this.props;
    const { errors } = this.state;
    return (
      <Grid container className={classes.mainFormGrid}>
        <Grid item sm />
        <Grid item sm className={classes.formGrid}>
          <div>
            {/*<img className={classes.image} src={AppIcon} alt="Расчеты" />*/}
            <Typography variant="h2" className={classes.pageTitle}>
              Регистрация
            </Typography>
            <form noValidate onSubmit={this.handleSubmit}>
              <TextField
                className={classes.textField}
                id="email"
                name="email"
                type="email"
                label="Почта"
                autoComplete="email"
                fullWidth
                helperText={errors.email}
                error={errors.email ? true : false}
                value={this.state.email}
                onChange={this.handleChange}
              />
              <TextField
                className={classes.textField}
                id="password"
                name="password"
                type="password"
                label="Пароль"
                autoComplete="new-password"
                fullWidth
                helperText={errors.password}
                error={errors.password ? true : false}
                value={this.state.password}
                onChange={this.handleChange}
              />
              <TextField
                className={classes.textField}
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                label="Подтвердить пароль"
                autoComplete="new-password"
                fullWidth
                helperText={errors.confirmPassword}
                error={errors.confirmPassword ? true : false}
                value={this.state.confirmPassword}
                onChange={this.handleChange}
              />
              <TextField
                className={classes.textField}
                id="handle"
                name="handle"
                type="text"
                label="Имя пользователя"
                autoComplete="username"
                fullWidth
                helperText={errors.handle}
                error={errors.handle ? true : false}
                value={this.state.handle}
                onChange={this.handleChange}
              />
              {errors.general && (
                <Typography variant="body2" className={classes.customError}>
                  {errors.general}
                </Typography>
              )}
              <Button
                className={classes.button}
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Регистрация
                {loading && (
                  <CircularProgress size={25} className={classes.progress} />
                )}
              </Button>
              <br />
              <small>
                уже есть профиль? <Link to="/login">заходи</Link>
              </small>
            </form>
          </div>
        </Grid>
        <Grid item sm />
      </Grid>
    );
  }
}

signup.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  signupUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  UI: state.UI
});

export default connect(mapStateToProps, { signupUser })(
  withStyles(styles)(signup)
);
