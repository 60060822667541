import { DotMaker } from 'client/src/models/draft/dot.model';
import { svgNS } from '../draft/default.draft';
import { tooltip } from './tooltip.svg';

export const dotMaker = (data: DotMaker): SVGLineElement => {
    const line: SVGLineElement = document.createElementNS(svgNS, 'line');
    const title: SVGTitleElement = tooltip(data.title);
    line.setAttribute('class', data.class);
    line.setAttribute('x1', `${data.x1}`);
    line.setAttribute('y1', `${data.y1}`);
    line.setAttribute('x2', `${data.x2}`);
    line.setAttribute('y2', `${data.y2}`);
    line.appendChild(title);

    return line;
};
