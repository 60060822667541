import { TaskInputChangeAP } from '../actions/change-task.actions';
import { getFromLocalStorage, resetStorage, saveToLocalStorage } from '../local-storage';
import { CurrentTask as NewTask } from '../state.model';
import { TaskAction } from '../types';
import { getDefaultTask } from '../../util/default-task';
import { inputChange, addRow, changeColor, deleteRow, tableChange } from './helper/task-helper';
import { Task } from '../../models/task.model';
import { LocalStorageTaskKey } from '../../models/local-storage-task-key';

const initialState: NewTask = {
    task: getDefaultTask(),
    durty: false,
    ...getFromLocalStorage(LocalStorageTaskKey.NewTaks)
};

export default (state: NewTask = initialState, action: TaskInputChangeAP) => {
    const { key, name, value, index, isTaskNew, report } = action.payload ?? {};

    if (!isTaskNew) {
        return state;
    }

    let task: Task, durty: boolean;

    switch (action.type) {
        case TaskAction.InputChange:
            ({ task, durty } = inputChange(state.task, key, name, value));
            break;
        case TaskAction.AddRow:
            ({ task, durty } = addRow(state.task, key));
            break;
        case TaskAction.DeleteRow:
            ({ task, durty } = deleteRow(state.task, key, index));
            break;
        case TaskAction.TableChange:
            ({ task, durty } = tableChange(state.task, key, name, index, value));
            break;
        case TaskAction.ColorChange:
            ({ task, durty } = changeColor(state.task, index));
            break;
        case TaskAction.Reset:
            resetStorage(LocalStorageTaskKey.NewTaks);
            return { task: getDefaultTask(), durty: false };
        case TaskAction.Do:
            return {
                ...state,
                task: {
                    ...state.task,
                    report
                }
            };
        default:
            return state;
    }

    saveToLocalStorage(task, durty, LocalStorageTaskKey.NewTaks);
    return { task, durty };
};
