import React, { useState } from 'react';

// Mui Stuff
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextField from '@material-ui/core/TextField';

interface CurrentProps {
    open: boolean;
    handleAgreed: (description: string) => void;
    handleClose: () => void;
}

// TODO add react-draggable

const ReportBugDialog = (props: CurrentProps) => {
    const [description, setDescription] = useState('');
    const { open, handleAgreed, handleClose } = props;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setDescription(value);
    };

    return (
        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
            <DialogTitle>Отправить сообщение об ошибке</DialogTitle>
            <DialogContent>
                <TextField
                    name='description'
                    label='Описание ошибки'
                    multiline
                    rows='4'
                    rowsMax='8'
                    fullWidth
                    value={description}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant='contained' onClick={handleClose} color='primary'>
                    Отменить
                </Button>
                <Button autoFocus variant='contained' onClick={() => handleAgreed(description)} color='secondary'>
                    Подтвердить
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ReportBugDialog;
