import React from 'react';

import MathJax from 'react-mathjax';

import { leaningI, leaningD, leaningDAvg, leaningN } from '../../../helpers/report/formulas';

const LeaningReport = ({ leaning }) => {
    const { D, kex, key, /*nu,*/ N, ex, ey, b, l, ix, iy, h, gammaMt, n, eta } = leaning;
    return (
        <article style={{ textAlign: 'left' }}>
            {!leaning.ix && !leaning.iy ? (
                <div>Крен фундамента отсутствует</div>
            ) : (
                <div>
                    Вычисление крена выполняется по формуле 5.24 <a href='#source01'>[1]</a>
                    {leaning.hasOneLayer ? (
                        <div>
                            Так как в пределах сжимаемой толщи однородное основание, то
                            <MathJax.Node formula={leaningD(D)} />
                        </div>
                    ) : (
                        <div>
                            Так как в пределах сжимаемой толщи неоднородное основание, то
                            <MathJax.Node formula={leaningDAvg(D)} />
                        </div>
                    )}
                    <MathJax.Node formula={leaningN(n, gammaMt, h, l, b, N)} />
                    <center><em>&eta; = l/b = {eta.toFixed(2)} </em></center>
                    <br/>
                    {leaning.ix ? (
                        <div>
                            Крен относительно оси X:
                            <br/>
                            <em> e = {ex.toFixed(2)} м </em>,
                            <em> a = l = {l} м </em>,
                            <em> k<sub>e</sub> = {kex.value} </em>
                            <MathJax.Node formula={leaningI('x', D.value, kex.value, N, ex, l, ix)} />
                        </div>
                    ) : null}
                    {leaning.iy ? (
                        <div>
                            Крен относительно оси Y:
                            <br/>
                            <em> e = {ey.toFixed(2)} м </em>,
                            <em> a = b = {b} м </em>,
                            <em> k<sub>e</sub> = {key.value} </em>
                            <MathJax.Node formula={leaningI('y', D.value, key.value, N, ey, b, iy)} />
                        </div>
                    ) : null}
                </div>
            )}
        </article>
    );
};

export default LeaningReport;
