import { Task } from '../models/task.model';

import randomColor from './randomColor';

export const getDefaultTask = (): Task => {
    const task: Task = {
        taskId: undefined,
        userHandle: '' as any,
        title: '' as any,
        description: '' as any,
        code: '07',
        createdAt: '' as any,
        lastChanges: '' as any,
        numberOfChanges: '' as any,
        general: {
            schema: false,
            lSize: undefined,
            hSize: undefined,
            basement: false,
            hs: undefined,
            hcf: undefined,
            gammacf: undefined,
            checkExisting: false,
            lExisting: undefined,
            bExisting: undefined,
            lb: undefined,
            sepLimit: '' as any,
            sizeModule: undefined,
            roundFoot: false,
            diam: undefined,
            column: false,
            bColumn: undefined,
            lColumn: undefined,
            foundationPit: false,
            preparation: false,
            variablePreparation: undefined,
            hp: undefined,
            smax: '' as any,
            imax: '' as any,
            soilThickness: '' as any
        },
        soilsData: [
            {
                color: randomColor(),
                type: '' as any,
                structure: undefined,
                moisture: undefined,
                il: undefined,
                c1: '' as any,
                phi1: '' as any,
                c2: '' as any,
                phi2: '' as any,
                gamma2: '' as any,
                gammas: '' as any,
                e: '' as any,
                E: '' as any,
                Ee: undefined,
                rmax: undefined
            }
        ],
        tableValues: false,
        levels: {
            NL: '' as any,
            DL: '' as any,
            CL: '' as any,
            FL: '' as any,
            WL: undefined,
            AL: undefined,
            batchCalc: false,
            AFL: undefined,
            fStep: undefined
        },
        loads: {
            n: '' as any,
            my: '' as any,
            mx: '' as any,
            qy: '' as any,
            qx: '' as any
        },
        well: [
            {
                soil: '' as any,
                thickness: '' as any
            }
        ],
        report: []
    };
    return task;
};
