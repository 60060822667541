import React, { Fragment } from 'react';
import MathJax from 'react-mathjax';
import {
    sFormula,
    sigmazgamma0,
    sigmazgamma,
    sigmazg0,
    sigmazg,
    sigmazp,
    uz,
    hBigSoilE,
} from '../../../helpers/report/formulas';

const SettlementReport = props => {
    const { data, report } = props;
    return (
        <div>
            Вычисление осадки выполняется по формуле 5.16 <a href='#source01'>[1]</a>
            {data.general.foundationPit && !report.settlement.pSigmazgCondition ? (
                <Fragment>
                    , с учётом пункта п.5.6.34<a href='#source01'>[1]</a> (котлован глубиной менее 5 м.)
                </Fragment>
            ) : (
                ''
            )}
            <MathJax.Node formula={sFormula(data.general.foundationPit, report.settlement.pSigmazgCondition)} />
            Вертикальное напряжение от внешней нагрузки на глубине z от подошвы фундамента по вертикали, проходящей
            через центр подошвы, вычисляется по формуле 5.17 <a href='#source01'>[1]</a>
            <MathJax.Node formula={sigmazp()} />
            Вертикальное напряжение от собственного веса грунта, выбранного при отрывке котлована, на отметке подошвы
            фундамента
            <MathJax.Node formula={sigmazgamma0()} />, на глубине z от подошвы фундамента определяется по формуле 5.18{' '}
            <a href='#source01'>[1]</a>
            <MathJax.Node formula={sigmazgamma()} />
            Вертикальное эффективное напряжение от собственного веса грунта до начала строительства в точке основания на
            глубине z от подошвы фундамента определяется по формуле 5.23 <a href='#source01'>[1]</a>
            <MathJax.Node formula={sigmazg()} />
            Поровое давление в рассматриваемой точке до начала строительства на глубине z от подошвы предполагаемого
            фундамента определяется по формуле Б.2 приложения Б <a href='#source02'>[2]</a>
            <MathJax.Node formula={uz()} />
            Вертикальное напряжение от собственного веса грунта на отметке подошвы фундамента
            <MathJax.Node
                formula={sigmazg0(
                    data.levels.NL,
                    data.levels.DL,
                    report.gamma2s.value,
                    report.d.value,
                    report.dn.value,
                    report.settlement.sigmazg0
                )}
            />
            <em>&beta;</em> - безразмерный коэффициент, равный 0.8
            <br />
            <br />
            Для определение коэффициента &alpha; по таблице 5.8 <a href='#source01'>[1]</a>:<br />
            <em>&eta; = l / b = {report.settlement.eta}</em>
            <br />
            <em>
                &zeta; = 2 z<sub>i</sub> / b
            </em>
            <br />
            Минимальная глубина сжимаемой толщи определяется по п. 5.6.41
            <a href='#source02'>[1]</a>
            <br />
            <em>
                H<sub>min</sub> =
            </em>{' '}
            {report.result.b / 2 <= 10 ? (
                <Fragment>
                    <em>b/2 = {(report.result.b / 2).toFixed(2)} м</em>, так как <em>b ≤10 м</em>
                </Fragment>
            ) : report.result.b / 2 <= 60 ? (
                <Fragment>
                    <em>(4 + 0.1b) = {4 + 0.1 * report.result.b} м</em>, так как <em>10 &lt; b ≤ 60 м</em>
                </Fragment>
            ) : (
                <Fragment>
                    <em>10 м</em>, так как <em>b &gt; 60 м</em>
                </Fragment>
            )}
            <br />
            Данные расчёта сведены в таблицу, см. приложение.
            <br />
            {(report.settlement.sAttention.k02 && report.settlement.hcCondition === 'zeroTwo') ||
            (report.settlement.sAttention.k05 && report.settlement.hcCondition === 'zeroFive') ? (
                <div className='reportAttention'>
                    Геология недостаточна. Значение характеристик приняты по последнему слою
                </div>
            ) : null}
            <div className={report.settlement.smaxCondition ? 'reportResultNegative' : 'reportResult'}>
                Осадка фундамента <b>{(report.settlement.smax / 10)?.toFixed(3)}</b> см, из них по слоям:
                <ul>
                    {report.settlement.reportTab.map((item, index) => {
                        return (
                            <li key={`srd${index}`}>
                                слой {item.soil + 1} - {(item.total / 10)?.toFixed(3)} см
                            </li>
                        );
                    })}
                </ul>
                Глубина сжимаемой толщи H<sub>c</sub> = <b>{report.settlement.hc?.toFixed(2)}</b> м
                {report.settlement.hminAttention ? (
                    <Fragment>
                        , так как расчётное значение меньше h<sub>min</sub>
                    </Fragment>
                ) : report.settlement.hcCondition === 'down' ? (
                    <Fragment>
                        <br />
                        Так как нижняя граница сжимаемой толщи ({report.settlement.hc?.toFixed(2)} м) находится в слое грунта с
                        модулем деформации E {'≤'} 700 т/м
                        <sup>2</sup> или такой слой залегает непосредственно ниже глубины H<sub>c</sub>, то он
                        включается в сжимаему толщу (глубина, на которой выполняется условие σ<sub>zp</sub> = 0.2σ
                        <sub>zg</sub>, больше глубины слоя)
                    </Fragment>
                ) : report.settlement.hcCondition === 'zeroTwo' ? (
                    <Fragment>
                        <br />
                        Так как нижняя граница сжимаемой толщи ({report.settlement.hc?.toFixed(2)} м) находится в слое грунта с
                        модулем деформации E {'≤'} 700 т/м
                        <sup>2</sup> или такой слой залегает непосредственно ниже глубины H<sub>c</sub>, в сжимаемую
                        толщу включают глубину, где выполняется условие σ<sub>zp</sub> = 0.2σ<sub>zg</sub> (глубина слоя
                        больше глубины, на которой выполняется условие)
                    </Fragment>
                ) : report.settlement.hcCondition === 'up' ? (
                    <Fragment>
                        <br />В пределах глубины H<sub>c</sub> ({report.settlement.hc?.toFixed(2)} м) находится слой грунта с
                        модулем деформации E {'>'} 10000 т/м<sup>2</sup>. 
                        {!report.settlement.bigEAttention && (
                            <dvi>
                                <br/>
                                Сжимаемую толщу принимаем до кровли трудносжимаемого грунта, так как выполняется условие по формуле 32(6) <a href='#source03'>[3]</a>
                                <MathJax.Node formula={hBigSoilE(report.settlement.bigEFormula)} /> 
                            </dvi>
                        )}
                    </Fragment>
                ) : null}
                <br />
                Нижнаяя граница на отм <b>{report.settlement.levelhc?.toFixed(2)}</b> м
            </div>
            {report.settlement.bigEAttention && <div className='reportAttention'>
                У слоя с модулем деформации, большим 100 МПа, отсутсвует подстилающий слой. Условие проверки выполнить невозможно
            </div>}
        </div>
    );
};

export default SettlementReport;
