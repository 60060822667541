import { RResult } from '../models/r-result.model';
import { svgNS } from './draft/default.draft';

const tempCoeff = 1.5;
const svgWidth = 600;
// const svgHeight = 450;

export const graphPRender = (data: RResult) => {
    const svg: SVGSVGElement | null = document.querySelector('#graphP');
    if (!svg) {
        return 'SVG элемент не найден';
    }

    const angel = (20 * Math.PI) / 180;
    const cX = 30;
    const cY = 270;
    const scale = 1 / ((1.1* (data.b + data.l)) / svgWidth);
    const b = data.b * scale;
    const l = data.l * scale * Math.sin(angel);
    const pCoeff1 = 3 / Math.abs(Math.max(b, l) / Math.max(Math.abs(data.p1), Math.abs(data.p2), Math.abs(data.p3), Math.abs(data.p4)));
    const pCoeff2 = Math.ceil(Math.max(Math.abs(data.p1), Math.abs(data.p2), Math.abs(data.p3), Math.abs(data.p4)) / 30);
    const k = -1;
    const p1 = (data.p1 * 5 * k * pCoeff1) / pCoeff2;
    const p2 = (data.p2 * 5 * k * pCoeff1) / pCoeff2;
    const p3 = (data.p3 * 5 * k * pCoeff1) / pCoeff2;
    const p4 = (data.p4 * 5 * k * pCoeff1) / pCoeff2;
    const pmin = Math.abs(Math.min(data.p1, data.p2, data.p3, data.p4));

    let path, d, text;
    let height = svg.height.baseVal.value;
    let width = svg.width.baseVal.value;
    svg.innerHTML = '';

    if (data.b * data.l > 7) {
        let i = Math.ceil((data.b * data.l - 7) / 1.5);
        width += 20 * i;
        svg.setAttribute('width', `${width}`);
        svg.setAttribute('viewBox', `0 0 ${width} ${height}`);
    }

    if (pmin > 15) {
        let i = Math.ceil((pmin - 15) / 5);
        height += 20 * i;
        svg.setAttribute('height', `${height}`);
        svg.setAttribute('viewBox', `0 0 ${width} ${height}`);
    }

    // deft statment
    const defs = document.createElementNS(svgNS, 'defs');
    const marker = document.createElementNS(svgNS, 'marker');
    marker.setAttribute('id', 'arrow');
    marker.setAttribute('class', 'axiosMarker');
    marker.setAttribute('viewBox', '0 0 10 10');
    marker.setAttribute('refX', '5');
    marker.setAttribute('refY', '5');
    marker.setAttribute('markerWidth', '6');
    marker.setAttribute('markerHeight', '6');
    marker.setAttribute('orient', 'auto-start-reverse');
    path = document.createElementNS(svgNS, 'path');
    path.setAttribute('d', 'M 0 0 L 10 5 L 0 10 z');
    marker.appendChild(path);
    defs.appendChild(marker);
    svg.appendChild(defs);

    text = document.createElementNS(svgNS, 'text');
    text.setAttribute('class', 'textP');
    text.textContent = '1';
    text.setAttribute('x', `${cX - 20 + b}`);
    text.setAttribute('y', `${cY - l}`);
    svg.appendChild(text);

    text = document.createElementNS(svgNS, 'text');
    text.setAttribute('class', 'textP');
    text.textContent = '2';
    text.setAttribute('x', `${cX + 10 + b * tempCoeff}`);
    text.setAttribute('y', `${cY - l}`);
    svg.appendChild(text);
    text = document.createElementNS(svgNS, 'text');
    text.setAttribute('class', 'textP');
    text.textContent = '3';
    text.setAttribute('x', `${cX + 20 + b}`);
    text.setAttribute('y', `${cY + 10}`);
    svg.appendChild(text);
    text = document.createElementNS(svgNS, 'text');
    text.setAttribute('class', 'textP');
    text.textContent = '4';
    text.setAttribute('x', `${cX - 20}`);
    text.setAttribute('y', `${cY + 10}`);
    svg.appendChild(text);

    path = document.createElementNS(svgNS, 'path');
    path.setAttribute('class', 'footing');
    d = `M ${cX},${cY} L ${cX + b},${cY} `;
    d += `L ${cX + b * tempCoeff},${cY - l} `;
    d += `L ${cX + b},${cY - l} z`;
    path.setAttribute('d', d);
    svg.appendChild(path);

    path = document.createElementNS(svgNS, 'path'); // 4 - 1
    path.setAttribute('class', 'graphP');
    d = `M ${cX},${cY} L ${cX},${cY + p4} L ${cX + b},${cY - l + p1} L ${cX + b},${cY - l} z`;
    path.setAttribute('d', d);
    svg.appendChild(path);
    path = document.createElementNS(svgNS, 'path'); // 4 - 3
    path.setAttribute('class', 'graphP');
    d = `M ${cX},${cY} L ${cX},${cY + p4} L ${cX + b},${cY + p3} L ${cX + b},${cY} z`;
    path.setAttribute('d', d);
    svg.appendChild(path);
    path = document.createElementNS(svgNS, 'path'); // 1 - 2
    path.setAttribute('class', 'graphP');
    d = `M ${cX + b},${cY - l} L ${cX + b},${cY - l + p1} L ${cX + b * tempCoeff},${cY - l + p2} L ${cX + b * tempCoeff},${cY - l} z`;
    path.setAttribute('d', d);
    svg.appendChild(path);
    path = document.createElementNS(svgNS, 'path'); // 3 - 2
    path.setAttribute('class', 'graphP');
    d = `M ${cX + b},${cY} L ${cX + b},${cY + p3} L ${cX + b * tempCoeff},${cY - l + p2} L ${cX + b * tempCoeff},${cY - l} z`;
    path.setAttribute('d', d);
    svg.appendChild(path);
    path = document.createElementNS(svgNS, 'path'); // 4 - 1 - 2 - 3
    path.setAttribute('class', 'graphPMain');
    d = `M ${cX},${cY + p4} L ${cX + b},${cY - l + p1} `;
    d += `L ${cX + b * tempCoeff},${cY - l + p2} L ${cX + b},${cY + p3} z`;
    path.setAttribute('d', d);
    svg.appendChild(path);
};
