import React from 'react';

import theme from '../../util/theme';
import NumberInput from '../helpers/NumberInput';
import loadsSVG from '../../images/loads.svg';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

const styles = {
  ...theme,
  loadsInput: {
    minWidth: 500,
    '& input': {
      width: '250px'
    }
  },
  loadsSvg: {
    paddingTop: 60,
  }
};

const LoadsData = (props: any) => {
  const handleChange = (e: any) => {
    // TODO same in all other components
    const name = e.target.name;
    const value = e.target.value;
    props.handleChange('loads', name, value);
  };

    const { classes, state : { n, my, mx, qy, qx  } } = props;
    return (
      <div className={classes.loadsList}>
        <div className={classes.loadsInput}>
          <Typography variant="subtitle1">Нагрузки</Typography>
          <div className={classes.inputGroup}>
            <NumberInput name={'n'} value={n} units={'т'} handleChange={handleChange} />
            <NumberInput name={'mx'} value={mx} units={'т⋅м'} handleChange={handleChange} />
            <NumberInput name={'qy'} value={qy} units={'т'} handleChange={handleChange} />
            <NumberInput name={'my'} value={my} units={'т⋅м'} handleChange={handleChange} />
            <NumberInput name={'qx'} value={qx} units={'т'} handleChange={handleChange} />
          </div>
        </div>
        <div className={classes.loadsSvg}>
            <img src={loadsSVG} alt="схема нагрузок на фундамент" width="88%"/>
        </div>
      </div>
    );
}

export default withStyles(styles as any)(LoadsData);
