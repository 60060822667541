import { ObliqueMarker } from '../../models/draft/marker.model';
import { svgNS } from '../draft/default.draft';

export const getHorizontalDimension = (
    xLeft: number,
    xRight: number,
    y: number,
    yOffset: number,
    dim: string
): SVGGElement => {
    const g: SVGGElement = document.createElementNS(svgNS, 'g');
    const text: SVGTextElement = document.createElementNS(svgNS, 'text');

    let line: SVGLineElement = document.createElementNS(svgNS, 'line');
    line.setAttribute('class', 'dimLine');
    line.setAttribute('x1', `${xLeft}`);
    line.setAttribute('y1', `${y}`);
    line.setAttribute('x2', `${xLeft}`);
    line.setAttribute('y2', `${y + yOffset + 5}`);
    g.appendChild(line);

    line = document.createElementNS(svgNS, 'line');
    line.setAttribute('class', 'dimLine');
    line.setAttribute('x1', `${xRight}`);
    line.setAttribute('y1', `${y}`);
    line.setAttribute('x2', `${xRight}`);
    line.setAttribute('y2', `${y + yOffset + 5}`);
    g.appendChild(line);

    line = document.createElementNS(svgNS, 'line');
    line.setAttribute('class', 'dimLine');
    line.setAttribute('x1', `${xLeft}`);
    line.setAttribute('y1', `${y + yOffset}`);
    line.setAttribute('x2', `${xRight}`);
    line.setAttribute('y2', `${y + yOffset}`);
    line.setAttribute('marker-start', `url(#${ObliqueMarker.obliqueH})`);
    line.setAttribute('marker-end', `url(#${ObliqueMarker.obliqueH})`);
    g.appendChild(line);

    text.textContent = dim;
    text.setAttribute('class', 'dimText');
    text.setAttribute('x', `${xLeft + (xRight - xLeft) / 2 - 20}`);
    text.setAttribute('y', `${y + yOffset - 5}`);
    g.appendChild(text);

    return g;
};
