import React, { Fragment } from 'react';

const informations = (isS) => ({
    smax: [
        <Fragment>
            СП 22.13330.2016, приложение Г, таблица Г.1 (часть, { isS ? <Fragment>s<sub>max</sub></Fragment> : <Fragment>i<sub>max</sub></Fragment>})
        </Fragment>,
        <Fragment>
            <ol>
                <li>
                    Производственные и гражданские одноэтажные здания с полным каркасом:
                    <ul style={{ listStyle: 'none' }}>
                        <li>железобетонным - {isS ? '10 см' : ''}</li>
                        <li>
                            то же, с устройстовм железобетонных поясов или монолитных перекрытий, а также здания
                            монолитной конструкции - {isS ? '15 см' : ''}
                        </li>
                        <li>стальным - {isS ? '15 см' : ''}</li>
                        <li>то же, с устройством железобетонных поясов или монолитных перекрытий - {isS ? '18 см' : ''}</li>
                    </ul>
                </li>
                <li>Здания и сооружкния, в конструкциях которых не возникают усилия от неравномерных осадок - {isS ? '20 см' : ''}</li>
                <li>
                    Многоэтажные бескаркасные здания с несущеми стенами из:
                    <ul style={{ listStyle: 'none' }}>
                        <li>крупных панелей - {isS ? '12 см' : ''}</li>
                        <li>крупных блоков или кирпичной кладки без армирования - {isS ? '12 см' : ''}</li>
                        <li>
                            то же, с армированием, в том числе с устройством железобетонных поясов или монолитных
                            перекрытий, а также здания монолитной конструкции - {isS ? '18 см' : ''}
                        </li>
                    </ul>
                </li>
                <li>
                    Сооружения элеваторов из железобетонных конструкций:
                    <ul style={{ listStyle: 'none' }}>
                        <li>
                            рабочее здание и силосный корпус монолитной конструкции на одной фундаментной плите - {isS ? '40 см' : '0.003'}
                        </li>
                        <li>то же, сборной конструкции - {isS ? '30 см' : '0.003'}</li>
                        <li>отдельно стоящий силосный корпус монолитной конструкции - {isS ? '40 см' : '0.004'}</li>
                        <li>то же, сборной конструкции - {isS ? '30 см' : '0.004'}</li>
                    </ul>
                </li>
                <li>
                    Дымовые трубы высотой H, м:
                    <ul style={{ listStyle: 'none' }}>
                        <li>Н ≤ 100 - {isS ? '40 см' : '0.005'}</li>
                        <li>100 &lt; H ≤ 200 - {isS ? '30 см' : '1/(2H)'}</li>
                        <li>200 &lt; H ≤ 300 - {isS ? '20 см'  : '1/(2H)'}</li>
                        <li>H &gt; 300 - {isS ? '10 см' : '1/(2H)'}</li>
                    </ul>
                </li>
                <li>Жесткие сооружения высотой до 100 м, кроме указанных в пунктах 4 и 5 - 20 см</li>
                <li>
                    Антенные сооружения связи:
                    <ul style={{ listStyle: 'none' }}>
                        <li>стволы мачт заземлённые - {isS ? '20 см' : '1/(2H)'}</li>
                        <li>то же, электрически изолированные - {isS ? '10 см' : '1/(2H)'}</li>
                        <li>башни радио -</li>
                        <li>башни коротковолновых радиостанций -</li>
                        <li>башни (отдельные блоки) -</li>
                    </ul>
                </li>
                <li>
                    Опоры воздушных линий электропередачи:
                    <ul style={{ listStyle: 'none' }}>
                        <li>промежуточные прямые -</li>
                        <li>анкерные и анкерно-угловые -</li>
                        <li>
                            промежуточные угловые, концевые, порталы открытых распределительных устройств специальные
                            переходные -
                        </li>
                    </ul>
                </li>
            </ol>
        </Fragment>,
    ]
});

export default informations;
