import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import theme from '../../util/theme';

// Components
import UserMenu from '../helpers/UserMenu';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
// import IconButton from '@material-ui/core/IconButton';
// import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
//import Snackbar from '@material-ui/core/Snackbar';

// Icons
// import NotificationsIcon from '@material-ui/icons/Notifications';

// Redux stuff
import { connect } from 'react-redux';
import { logoutUser, setMenu } from '../../redux/actions/user.actions';

const styles = {
    ...theme,
    appbarEntire: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    backHome: {
        color: 'white'
    }
};

const Navbar = props => {
    const history = useHistory();
    const menu = useSelector(state => state.UI.menu);
    const title = useSelector(state => state.tasks.task.title);
    const taskId = useSelector(state => state.tasks.task.taskId);

    const {
        classes,
        user: { authenticated, credentials },
        // tasks: {
        //     task: { title, taskId }
        // },
        admin: { allTasks, allUsers },
        // UI: { menu },
        // logoutUser,
        setMenu
    } = props;

    const handleLogout = () => {
        props.logoutUser();
    };

    useEffect(() => {
      const pathName = history.location.pathname;
      const status = pathName.split('/').pop();
      setMenu(status)
    }, [history.location.pathname]);

    return (
        <AppBar>
            <Toolbar className={classes.appbarEntire}>
                <div className={classes.appbarLeft}>
                    <Typography variant='h5' noWrap>
                        <Link className={classes.backHome} to='/'>
                            &mu;eduza{' '}
                        </Link>
                        <em>{!credentials.handle ? null : `- ${credentials.handle}`}</em>
                    </Typography>
                </div>
                {/* {authenticated === true ? ( */}
                <div className={classes.appbarCenter}>
                    {title ? (
                        <Button
                            color='inherit'
                            variant={menu === taskId ? 'outlined' : 'text'}
                            component={Link}
                            to={`/task/${taskId}`}
                        >
                            {title}
                        </Button>
                    ) : null}
                    <Button
                        color='inherit'
                        variant={menu === 'newtask' ? 'outlined' : 'text'}
                        component={Link}
                        to={`/task/newtask`}
                    >
                        новая задача
                    </Button>
                    <Button 
                      color='inherit' 
                      variant={menu === '' ? 'outlined' : 'text'} 
                      component={Link} 
                      to='/'>
                        домой
                    </Button>
                </div>
                {/* ) : null} */}
                {credentials.admin ? (
                    <div>
                        Всего пользователей: {allUsers?.length} / Всего задач: {allTasks?.length}
                    </div>
                ) : null}
                {authenticated === true ? (
                    <div className={classes.appbarRight}>
                        {/* <IconButton aria-label="Уведомления" color="inherit">
                <Badge badgeContent={13} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton> */}
                        <UserMenu handleLogout={handleLogout} />
                    </div>
                ) : (
                    <div className={classes.appbarRight}>
                        <Button 
                          color='inherit' 
                          variant={menu === 'signup' ? 'outlined' : 'text'}
                          component={Link} 
                          to='/signup'>
                            Регистрация
                        </Button>
                        <Button 
                          color='inherit' 
                          variant={menu === 'login' ? 'outlined' : 'text'}
                          component={Link} 
                          to='/login'>
                            Вход
                        </Button>
                    </div>
                )}
            </Toolbar>
            {/*authenticated ? (
          <Snackbar
            open={true}
            TransitionComponent={'Fade'}
            message={<span id="message-id">!!!!</span>}
          ></Snackbar>
        ) : null*/}
        </AppBar>
    );
};

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user,
    tasks: state.tasks,
    UI: state.UI
});

export default connect(mapStateToProps, { logoutUser, setMenu })(withStyles(styles)(Navbar));
