import React, { ChangeEvent } from 'react';

import theme from '../../util/theme';

// components
import NumberInput from '../helpers/NumberInput';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

const styles = {
    ...theme
};

// TODO any
const LevelsData = (props: any) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        // TODO same in all other components
        const name = e.target.name;
        const value = e.target.name === 'batchCalc' ? e.target.checked : e.target.value;
        props.handleChange('levels', name, value);
    };

    const { classes, errors } = props;
    const { NL, DL, CL, FL, WL, AL, afl, fStep, batchCalc } = props.state;

    return (
        <div className={classes.dataList}>
            <Typography variant='subtitle1'>Отметки</Typography>
            <br/>
            <Typography variant='body1'>Все отметки задаются относительно планировочной отметки земли DL</Typography>
            <br/>
            <div className={classes.inputGroup}>
                <NumberInput name={'NL'} value={NL} handleChange={handleChange} units={'м'} tooltip={true} />
                <NumberInput name={'DL'} value={DL} units={'м'} handleChange={handleChange} error={errors.DL} />
                <NumberInput name={'CL'} value={CL} units={'м'} handleChange={handleChange} error={errors.CL} />
                <NumberInput name={'FL'} value={FL} units={'м'} handleChange={handleChange} error={errors.FL} />
                <NumberInput
                    name={'WL'}
                    value={WL}
                    units={'м'}
                    handleChange={handleChange}
                    error={errors.WL}
                    tooltip={true}
                />
                {WL !== undefined ? (
                    <NumberInput
                        name={'AL'}
                        value={AL}
                        units={'м'}
                        handleChange={handleChange}
                        error={errors.AL}
                        tooltip={true}
                    />
                ) : null}
                {/* <NewCheckbox name={'batchCalc'} value={batchCalc} handleChange={this.handleChange} label={'Пакетный расчёт'} /> */}
                {batchCalc ? (
                    <div>
                        <NumberInput
                            name={'afl'}
                            value={afl}
                            units={'м'}
                            handleChange={handleChange}
                            error={errors.afl}
                            tooltip={true}
                        />
                        <NumberInput
                            name={'fStep'}
                            value={fStep}
                            units={'м'}
                            handleChange={handleChange}
                            error={errors.fStep}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default withStyles(styles as any)(LevelsData);
// 124
