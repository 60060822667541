import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import axios from 'axios';

import './App.css';
import themeStyle from './util/theme';

// Redux
import { Provider } from 'react-redux';
import store from './redux/store';
import { logoutUser, getUserData } from './redux/actions/user.actions';

// Components
import Navbar from './components/navbar/navbar';
import AuthRoute from './util/AuthRoute';

// Mui Stuff
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

// Pages
import home from './pages/home/home.page';
import login from './pages/login';
import signup from './pages/signup';
import task from './pages/task';
import { getNews } from './redux/actions/ui.actions';

const theme = createMuiTheme(themeStyle);

axios.defaults.baseURL = //'http://localhost:5000/meduza-778/europe-west1/api';
 'https://europe-west1-meduza-778.cloudfunctions.net/api';

// TODO change strin to enum
const token = localStorage.FBIdMeduzaToken;
if (token) {
    store.dispatch({ type: '[UI] START LOADING' });
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
        store.dispatch(logoutUser());
        window.location.href = '/login';
    } else {
        store.dispatch({ type: '[USER] SET AUTH' });
        axios.defaults.headers.common['Authorization'] = token;
        store.dispatch(getUserData());
    }
}

store.dispatch(getNews());

const App = (props) => {
    const version = store.getState().UI.version;
    return (
        <MuiThemeProvider theme={theme}>
            <Provider store={store}>
                <Router>
                    <Navbar />
                    <div className='temp-navbar'></div>
                    <div className='mainContainer'>
                        <Switch>
                            <Route exact path='/' component={home} />
                            <AuthRoute path='/login' component={login} />
                            <AuthRoute path='/signup' component={signup} />
                            <Route path='/task/:taskId' component={task} />
                            <Route path='/user/:user' component={home} />
                            <Redirect from='*' to='/' />
                        </Switch>
                    </div>
                    <div className='footer'>
                        {version}
                        <a href='mailto:meduzaproapp@gmail.com' className="mail">
                            meduzaproapp@gmail.com
                        </a>
                    </div>
                </Router>
            </Provider>
        </MuiThemeProvider>
    );
};

export default App;
