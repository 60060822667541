export default {
  palette: {
    primary: {
      //light: '#954bb4',
      light: '#7f46c5',
      //main: '#7b1fa2',
      main: '#2e2556',
      //dark: '#561571',
      dark: '#2c1230',
      contrastText: '#fff'
    },
    secondary: {
      //light: '#faf2a0',
      light: '#00b67b',
      //main: '#ffea00',
      main: '#69f9e8',
      //dark: '#b2a300',
      dark: '#0054b5',
      contrastText: '#000'
    }
  },
  mainGrid: {
    height: '100%'
  },
  mainFormGrid: {
    // height: '100%'
  },
  itemGrid: {    
    // height: '100%'
  },
  paperSheet: {
    padding: '10px',
    contain: 'strict',
    height: '100%',
  },
  taskList: {
    padding: '10px 10px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  dataList: {
    overflow: 'auto'
  },
  loadsList: {
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '95%',
    width: '100%'
  },
  soilsList: {
    minWidth: '850px',
    maxHeight: '95%',
    overflow: 'auto'
  },
  drawList: {
    minWidth: '850px',
    maxHeight: '95%'
  },
  formGrid: {
    // paddingBottom: 80,
    margin: 'auto',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignContent: 'center',
    textAlign: 'center'
  },
  image: {
    width: 200,
    height: 'auto'
  },
  pageTitle: {
    margin: '10px auto'
  },
  textField: {
    marginBottom: 10
  },
  button: {
    margin: '10px 0px',
    postition: 'relative'
  },
  customError: {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: 10
  },
  taskDataError: {
    color: 'red',
    fontSize: '0.8rem',
  },
  progress: {
    position: 'absolute'
  },
  inputGroup: {
    marginLeft: 20,
    maxWidth: '50%'
  },
  singleInput: {
    marginTop: 10
  }
};
