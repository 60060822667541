import { SoilHatch } from '../../models/draft/hatchs';
import { svgNS } from '../draft/default.draft';

export const clayHatch = (): SVGPatternElement => {
    let line: SVGLineElement;
    const g: SVGGElement = document.createElementNS(svgNS, 'g');
    const hatch: SVGPatternElement = document.createElementNS(svgNS, 'pattern');
    hatch.setAttribute('id', SoilHatch.clayHatch);
    hatch.setAttribute('width', '20px');
    hatch.setAttribute('height', '7px');
    hatch.setAttribute('patternUnits', 'userSpaceOnUse');

    line = document.createElementNS(svgNS, 'line');
    line.setAttribute('x1', '0');
    line.setAttribute('y1', '3');
    line.setAttribute('x2', '20');
    line.setAttribute('y2', '3');
    g.appendChild(line);

    g.setAttribute('class', SoilHatch.clayHatch);

    hatch.appendChild(g);

    return hatch;
};
