import { Draft } from 'client/src/models/draft/draft.model';

export const getWaterRectangle = (draft: Draft, wl: number, bottomBorder: number): string => {
    const { centerX, centerY, limit } = draft;
    const rect = `M ${centerX + limit} ${centerY - wl} ${centerX - limit} ${centerY - wl} ${
        centerX - limit
    } ${bottomBorder} ${centerX + limit} ${bottomBorder} Z`;

    return rect;
};
