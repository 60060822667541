import { Task } from '../../models/task.model';
import { LocalStorageTaskKey } from '../../models/local-storage-task-key';
import { getFromLocalStorage, saveToLocalStorage } from '../local-storage';
import { TaskAction, TaskListAction } from '../types';
import { addRow, changeColor, deleteRow, inputChange, tableChange } from './helper/task-helper';

const initialState = {
    task: {} as any,
    durty: false,
    tasks: [],
    taskUpdated: false,
    taskSaved: false,
    ...getFromLocalStorage(LocalStorageTaskKey.Task)
};

export default (state = initialState, action: any) => {
    switch (action.type) {
        case TaskAction.Set:
            return {
                ...state,
                task: action.payload,
                durty: false
            };
        case TaskAction.Post:
            return {
                ...state,
                task: action.payload,
                taskSaved: !state.taskSaved,
                durty: false
            };
        case TaskAction.Update:
            return {
                ...state,
                task: action.payload,
                taskUpdated: !state.taskUpdated,
                durty: false
            };
        case TaskAction.Delete:
            return {
                ...state,
                task: {},
                durty: false
            };
        case TaskListAction.Set:
            return {
                ...state,
                tasks: action.payload
            };
        case TaskAction.UpdateToggle:
            return {
                ...state,
                taskUpdated: !state.taskUpdated
            };
        case TaskAction.SaveToggle:
            return {
                ...state,
                taskSaved: !state.taskSaved
            };
    }

    const { key, name, value, index, isTaskNew, report } = action.payload ?? {};

    if (isTaskNew) {
        return state;
    }

    let task: Task, durty: boolean;

    switch (action.type) {
        case TaskAction.InputChange:
            ({ task, durty } = inputChange(state.task, key, name, value));
            break;
        case TaskAction.AddRow:
            ({ task, durty } = addRow(state.task, key));
            break;
        case TaskAction.DeleteRow:
            ({ task, durty } = deleteRow(state.task, key, index));
            break;
        case TaskAction.TableChange:
            ({ task, durty } = tableChange(state.task, key, name, index, value));
            break;
        case TaskAction.ColorChange:
            ({ task, durty } = changeColor(state.task, index));
            break;
        case TaskAction.Reset:
            ({ task, durty } = { task: {} as any, durty: false });
            break;
        case TaskAction.Do:
            return {
                ...state,
                task: {
                    ...state.task,
                    report: report
                }
            };
        default:
            return state;
    }

    saveToLocalStorage(task, durty, LocalStorageTaskKey.Task);
    return { ...state, task, durty };
};
