import { PathMaker } from 'client/src/models/draft/path.model';
import { svgNS } from '../draft/default.draft';
import { tooltip } from './tooltip.svg';

export const pathMaker = (data: PathMaker): SVGPathElement => {
    const tt: SVGTitleElement = tooltip(data.title);
    const path: SVGPathElement = document.createElementNS(svgNS, 'path');
    path.setAttribute('id', data.id);
    path.setAttribute('class', data.class);
    path.setAttribute('d', data.d);
    data.title && path.appendChild(tt);

    return path;
};
