import { UIAction } from "../types";

const initialState = {
  loading: false,
  errors: {},
  menu: "",
  news: [],
  reportDone: 0,
  version: "2019 - 2023, © ak, 0.16.8"
};

// TODO remove any
export default function(state = initialState, action: any) {
  switch (action.type) {
    case UIAction.SetErrors:
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    case UIAction.ClearErrors:
      return {
        ...state,
        loading: false,
        errors: {}
      };
    case UIAction.StartLoading:
      return {
        ...state,
        loading: true
      };
    case UIAction.StopLoading:
      return {
        ...state,
        loading: false
      };
    case UIAction.SetMenu:
      return {
        ...state,
        menu: action.payload
      };
    case UIAction.SetNews:
      return {
        ...state,
        news: action.payload
      };
    case UIAction.ReportDone:
      return {
        ...state,
        reportDone: Date.now()
      }
    default:
      return state;
  }
}
