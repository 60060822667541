import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ru';

import theme from '../../util/theme';

// Mui Styff
import withStyles from '@material-ui/core/styles/withStyles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

const styles = {
  ...theme
};

const SingleTask = props => {
  dayjs.extend(relativeTime);
  dayjs.locale('ru');

  const { taskId, bugId, title, createdAt, lastChange, description, userHandle } = props.task;
  const { userId } = props;
  const backgroundColor = bugId ? '#f0dedd' : userId !== userHandle ? '#f0f0eb' : 'transparent';

  return (
    <ListItem button component={Link} to={`/task/${taskId}`} onContextMenu={e => props.handleClick(e, props.task)} style={{ backgroundColor }}>
      <ListItemText
        primary={<Typography component="span" display="block" noWrap>
        {title} 
      </Typography>}
        secondary={
          <Fragment>
            <Typography component="span" display="block" variant="body2" color="textPrimary" noWrap>
              {dayjs(createdAt).fromNow() + (lastChange ? ' / ' + dayjs(lastChange).fromNow() : '')} 
            </Typography>
            <Typography component="span" display="block" variant="body2" color="textSecondary" noWrap>
            {description} 
            </Typography>
          </Fragment>
        }
      />
    </ListItem>
  );
};

export default withStyles(styles)(SingleTask);
