import { Soil } from '../models/soil.model';
import randomColor from './randomColor';

export const getDefaultSoil = (): Soil => {
    return {
        color: randomColor(),
        type: undefined as any,
        structure: undefined,
        moisture: undefined,
        il: undefined,
        c1: undefined as any,
        phi1: undefined as any,
        c2: undefined as any,
        phi2: undefined as any,
        gamma2: undefined as any,
        gammas: undefined as any,
        e: undefined as any,
        E: undefined as any,
        Ee: undefined,
        rmax: undefined
    };
};
