import React, { Fragment } from 'react';

import { Task } from 'client/src/models/task.model';

const LevelsRep = ({ data, index }: { data: Task; index: number }) => {
    const { NL, DL, CL, FL, WL, AL } = data.levels;
    return (
        <article style={{ textAlign: 'left' }}>
            Отметка уровня земли, NL: {NL ? NL : DL} м<br />
            Отметка уровня земли, DL: {DL} м<br />
            Отметка обреза подколонника, CL: {CL} м<br />
            Отметка подошвы, FL: {data.report?.[index].newFL ? data.report[index].newFL : FL} м<br/>
            {WL !== undefined && <Fragment>Отметка уровня грунтовых вод, WL: {WL} м<br /></Fragment>}
            {WL !== undefined && <Fragment>Отметка водоупора, AL: {AL} м</Fragment>}
        </article>
    );
};

export default LevelsRep;
