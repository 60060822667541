import React, { Fragment } from 'react';
import MathJax from 'react-mathjax';

import soilsDecoder from '../../../../util/soils-decoder';
import { d, dn, d1, db, footSize, a, w, r, waterInGamma } from '../../../helpers/report/formulas';

const RReport = props => {
  const { data, report } = props;

  return (<div>
      Расчётное сопротивление грунта основания вычисляется по формуле 5.7 <a href="#source01">[1]</a><br/>
      В основании {soilsDecoder(data.soilsData[report.soilUnder.idx].type)}{' '}{soilsDecoder(data.soilsData[report.soilUnder.idx].structure)}
      {' '}{soilsDecoder(data.soilsData[report.soilUnder.idx].moisture)}<br/>
      Коэффициенты &gamma;<sub>c1</sub> и &gamma;<sub>c2</sub> подбираются по таб. 5.4 <a href="#source01">[1]</a><br/>
      {report.gammaC1.comment === report.gammaC2.comment ?
        (
          <Fragment>
              &gamma;<sub>c1</sub> =
              &gamma;<sub>c2</sub> = {report.gammaC1.value} {report.gammaC1.comment}<br/>
              <div className="reportAttention">
              Расчётное сопротивление оснований R, сложенных рыхлыми песками, следует выполнять
              на основании специальных исследований. Значение R, найденное для рыхлых песков
              следует уточнить по результатам испытаний штампа. п.5.6.8 <a href="#source01">[1]</a>
              </div>
            </Fragment>
        ) : (
          <Fragment>
            &gamma;<sub>c1</sub> = {report.gammaC1.value} {report.gammaC1.comment}<br/>
            &gamma;<sub>c2</sub> = {report.gammaC2.value} {report.gammaC2.comment}<br/>
          </Fragment>
        )}
      k = {report.k.value}{' '}{report.k.comment} п.5.6.7 <a href="#source01">[1]</a><br/>
      Для {report.soilUnder.average && 'осреднённого'} угла внутреннего трения &phi;<sub>2</sub> ={' '}
        {report.soilUnder.values.phi2}&deg;<br/>
        Коэффициенты M подбираются по таб. 5.5 <a href="#source01">[1]</a><br/>
      M<sub>&gamma;</sub> = {report.Mg.value}<br/>
      M<sub>q</sub> = {report.Mq.value}<br/>
      M<sub>c</sub> = {report.Mc.value}<br/>
      k<sub>z</sub> = {report.kz.value}{' '}{report.kz.comment}<br/>
      &gamma;<sub>II</sub>' = {report.gamma2s.value} т/м<sup>3</sup> осреднённый вес грунтов выше подошвы<br/>
      &gamma;<sub>II</sub> = {report.soilUnder.values.gamma2} т/м<sup>3</sup> {report.soilUnder.average && 'осреднённый'} вес грунта ниже подошвы<br/>
      с<sub>II</sub> = {report.soilUnder.values.c2}{' '}
        т/м<sup>2</sup> {report.soilUnder.average && 'осреднённое'} удельное сцепление грунта ниже подошвы<br/>
      {report.soilUnder.average ?
        report.result.b < 10 ?
        (
          <Fragment>
            <div className="reportAttention">
              Характеристики &phi;<sub>2</sub>, c<sub>2</sub> и &gamma;<sub>2</sub> определены как
              средневзвешенные значения характеристик грунтов, залегающих на расстоянии
              z = b/2 = {(report.result.b/2).toFixed(2)} м ниже подошвы фундаменты п.5.6.10
              <a href="#source01">[1]</a><br/>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="reportAttention">
              Характеристики &phi;<sub>2</sub>, c<sub>2</sub> и &gamma;<sub>2</sub> определены как
              средневзвешенные значения характеристик грунтов, залегающих на расстоянии
              z = z<sub>1</sub>+0.1b = {4 + 0.1*report.result.b.toFixed(2)} м (где z<sub>1</sub> = 4 м)
              ниже подошвы фундаменты п.5.6.10 <a href="#source01">[1]</a><br/>
            </div>
          </Fragment>
        ) : report.result.b < 10 ?
            (
              <Fragment>
                <div className="reportAttention">
                  Характеристики &phi;<sub>2</sub>, c<sub>2</sub> и &gamma;<sub>2</sub> соответствуют
                  характеристикам грунта номер {report.soilUnder.idx + 1}, так как на расстоянии
                  z = b/2 = {(report.result.b/2).toFixed(2)} м ниже подошвы фундаменты иные грунты не встречены, п.5.6.10
                  <a href="#source01">[1]</a><br/>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className="reportAttention">
                  Характеристики &phi;<sub>2</sub>, c<sub>2</sub> и &gamma;<sub>2</sub> соответствуют
                  характеристикам грунта номер {report.soilUnder.value+1}, так как на расстояниии
                  z = z<sub>1</sub>+0.1b = {4 + 0.1*report.result.b.toFixed(2)} м (где z<sub>1</sub> = 4 м)
                  ниже подошвы фундаменты иные грунты не встречены, п.5.6.10 <a href="#source01">[1]</a><br/>
                </div>
              </Fragment>)
      }
      {report.soilUnder.comment && report.gamma2s.comment 
        ? <div className="reportAttention">{`Выше и ниже подошвы ${report.soilUnder.comment}`} <MathJax.Node formula={waterInGamma()}/></div> 
        : report.gamma2s.comment 
          ? <div className="reportAttention">{`Выше подошвы ${report.soilUnder.comment}`} <MathJax.Node formula={waterInGamma()}/></div> 
          : report.soilUnder.comment 
            ? <div className="reportAttention">{`Ниже подошвы ${report.soilUnder.comment}`} <MathJax.Node formula={waterInGamma()}/></div> 
            : null}
      Глубина заложения от уровня планировки<br/>
      <MathJax.Node formula={d(report.newFl || data.levels.FL, data.levels.DL, report.d.value)}/>
      Глубина заложения от уровня природного рельефа<br/>
      <MathJax.Node formula={dn(report.newFl || data.levels.FL, data.levels.NL, report.dn.value)}/>
      {data.general.basement ?
        (
          <Fragment>
            Для сооружений с подвалом d<sub>1</sub> определяется по формуле 5.8 <a href="#source01">[1]</a><br/>
            <MathJax.Node formula={d1(data.general.hs, data.general.hcf, data.general.gammacf, report.gamma2s.value, report.d1.value,
                data.general.preparation, data.general.hp, report.d.value, report.db.value,  data.general.basement)} />
            Расстояние от уровня планировки до пола подвала<br/>
            <MathJax.Node formula={db(report.d.value, report.db.value, data.general.hs, data.general.hcf,
                report.db.comment, report.d1.value)} />
            {report.db.value === 0 ?
              (
                <div className="reportAttention">
                  <Fragment>
                    Так как d<sub>1</sub> {'>'} d, принято d<sub>1</sub> = d и d<sub>b</sub> = 0 п.5.6.7 <a href="#source01">[1]</a><br/>
                  </Fragment>
                </div>
              ) : null }
            {report.d1.comment ?
              (
                <div className="reportAttention">
                  <Fragment>
                    Значение d<sub>1</sub> {report.d1.comment}
                  </Fragment>
                </div>
              ) : null}
            {report.db.comment ?
              (
                <div className="reportAttention">
                  <Fragment>
                    Значение d<sub>b</sub> принято{report.db.comment}<a href="#source01">[1]</a>
                  </Fragment>
                </div>
              ) : null}
            {(parseFloat(data.general.hs) + parseFloat(data.general.hcf)) < 0.5 ?
              (
                <Fragment>
                  <div className="reportAttention">Глубина заложения фундамента от пола подвала менее 0.5
                    {' '}п. 5.6.9 <a href="#source01">[1]</a></div>
                </Fragment>
              ) : null}
          </Fragment>
        ) : (
          <Fragment>
            <MathJax.Node formula={d1(data.general.hs, data.general.hcf, data.general.gammacf, report.gamma2s.value, report.d1.value,
              data.general.preparation, data.general.hp, report.d.value, report.db.value, data.general.basement)} />
            Для бесподвальных сооружений d<sub>1</sub> = d, d<sub>b</sub> = 0<br/>
            {data.general.preparation ?
              (
                <div className="reportAttention">
                  <Fragment>
                    Значение d<sub>1</sub> {report.d1.comment}
                  </Fragment>
                </div>
              ) : null}
          </Fragment>
        )}

        <div className="page-break"></div>

        <div className={data.general.checkExisting ? null : "reportResult"}>
          Габариты подошвы:<br/>
          <MathJax.Node formula={footSize(report.result.b, report.result.l, data.general.roundFoot, data.general.checkExisting,
            data.general.diam, report.result.diam)}/>
        </div>
        Геометрические характеристики:<br/>
        <MathJax.Node formula={a(report.result.b, report.result.l, report.result.a, data.general.roundFoot, data.general.checkExisting,
          data.general.diam, report.result.diam)} />
        <MathJax.Node formula={w(report.result.b, report.result.l, report.result.wy, report.result.wx,
          data.general.roundFoot, data.checkExisting, data.general.diam, report.result.diam)} />
        <div className="reportResult">
          Расчётное сопротивление грунта:<br/>
          <MathJax.Node
            formula={r(report.gammaC1.value, report.gammaC2.value, report.k.value,
                        report.Mg.value, report.Mq.value, report.Mc.value, report.kz.value, report.result.b, report.soilUnder.values.gamma2,
                        report.gamma2s.value, report.d1.value, report.db.value, report.soilUnder.values.c2, report.result.r,
                        report.result.rVariable, report.result.rmax, data.soilsData[report.soilUnder.idx].rmax, report.result.rOrigin)}
          />
        </div>
      </div>
    )
}

export default RReport;
