import { Task } from '../models/task.model';
import { SoilType, SandStructureType } from '../models/soil.model';
import { ErrorMessages } from './error-messages';

export const checkTaskBeforeSave = (data: Task): { errors: any; valid: boolean } => {
    const errors: any = {};
    if (!data.title) errors.title = ErrorMessages.required;

    return {
        errors,
        valid: Object.keys(errors).length === 0 ? true : false
    };
};

export const checkTaskBeforeCalc = (data: Task): { errors: any; valid: boolean } => {
    const errors: any = {};
    let count: number = 0;
    let currentCount: number = 0;
    // general
    if (!data.title) errors.title = ErrorMessages.required;
    if (data.general.schema) {
        if (!data.general.lSize) errors.lSize = ErrorMessages.required;
        if (!data.general.hSize) errors.hSize = ErrorMessages.required;
    }
    if (data.general.basement) {
        if (!data.general.hs) errors.hs = ErrorMessages.required;
        if (!data.general.hcf) errors.hcf = ErrorMessages.required;
        if (!data.general.gammacf) errors.gammacf = ErrorMessages.required;
    }
    if (data.general.checkExisting) {
        if (data.general.roundFoot) {
            if (!data.general.diam) errors.diam = ErrorMessages.required;
        } else {
            if (!data.general.lExisting) errors.lExisting = ErrorMessages.required;
            if (!data.general.bExisting) errors.bExisting = ErrorMessages.required;
        }
    }
    if (data.general.preparation) {
        if (!data.general.hp) errors.hp = ErrorMessages.required;
    }
    if (data.general.column) {
        if (!data.general.bColumn) errors.bColumn = ErrorMessages.required;
        if (!data.general.lColumn) errors.lColumn = ErrorMessages.required;
    }
    if (!data.general.smax) {
        errors.smax = ErrorMessages.required;
    }
    currentCount = Object.keys(errors).length;
    if (currentCount > count) {
        errors.general = ErrorMessages.notEnoughData;
        count = currentCount + 1;
    }
    // soils
    const soils = data.soilsData.map(soil => {
        const soilsErrors: any = {};
        if (!soil.type) soilsErrors.type = ErrorMessages.required;
        if (soil.type === SoilType.sand) {
            if (!soil.structure) soilsErrors.structure = ErrorMessages.required;
            if (soil.structure === SandStructureType.dust) {
                if (!soil.moisture) soilsErrors.moisture = ErrorMessages.required;
            }
        }

        if (soil.type === SoilType.coarseGrained) {
            if (!soil.structure) soilsErrors.structure = ErrorMessages.required;
        }

        if (soil.type === SoilType.clay || soil.type === SoilType.sandLoam || soil.type === SoilType.loam) {
            if (!soil.il) soilsErrors.il = ErrorMessages.required;
        }
        if (!soil.c1) soilsErrors.c1 = ErrorMessages.required;
        if (!soil.phi1) soilsErrors.phi1 = ErrorMessages.required;
        if (!soil.c2) soilsErrors.c2 = ErrorMessages.required;
        if (!soil.phi2) soilsErrors.phi2 = ErrorMessages.required;
        if (!soil.gamma2) soilsErrors.gamma2 = ErrorMessages.required;
        if (!soil.gammas) soilsErrors.gammas = ErrorMessages.required;
        if (!soil.e) soilsErrors.e = ErrorMessages.required;
        if (!soil.E) soilsErrors.E = ErrorMessages.required;
        return Object.keys(soilsErrors).length === 0 ? null : soilsErrors;
    });

    if (soils.some(element => element !== null)) {
        errors.soils = soils;
        errors.soilsData = ErrorMessages.notEnoughData;
        count += 2 + (errors.gamma2s ? 1 : 0);
    }
    // levels
    if (!data.levels.DL && data.levels.DL !== 0) errors.DL = ErrorMessages.required;
    if (!data.levels.CL && data.levels.CL !== 0) errors.CL = ErrorMessages.required;
    if (!data.levels.FL && data.levels.FL !== 0) errors.FL = ErrorMessages.required;
    if (data.levels.batchCalc && !data.levels.AFL) errors.AFL = ErrorMessages.required;
    if (data.levels.batchCalc && !data.levels.fStep) errors.fStep = ErrorMessages.required;

    currentCount = Object.keys(errors).length;
    if (currentCount > count) {
        errors.levels = ErrorMessages.notEnoughData;
        count = currentCount + 1;
    } else {
        const levelErrors = [];
        if (Number(data.levels.FL) >= Number(data.levels.CL)) {
            levelErrors.push(ErrorMessages.wrongLevels2);
            errors.FL = ErrorMessages.wrongLevels1;
            errors.CL = ErrorMessages.wrongLevels1;
        }
        if (Number(data.levels.FL) >= Number(data.levels.DL)) {
            levelErrors.push(ErrorMessages.wrongLevels3);
            errors.FL = ErrorMessages.wrongLevels1;
            errors.DL = ErrorMessages.wrongLevels1;
        }
        if (Math.abs(Number(data.levels.FL) - Number(data.levels.CL)) < 0.3) {
            levelErrors.push(ErrorMessages.baseHeightTooLow);
            errors.FL = ErrorMessages.wrongLevels1;
            errors.DL = ErrorMessages.wrongLevels1;
        }

        const thickness = data.well.reduce((total, curr) => total + Number(curr.thickness), 0);
        if (Number.isFinite(Number(data.levels.WL))) {
            if (Number(data.levels.WL) < Number(data.levels.NL) - thickness) {
                levelErrors.push(ErrorMessages.waterLevelBelowLastSoil);
                errors.WL = ErrorMessages.wrongLevels1;
            }
            if (data.levels.AL) {
                if (Number(data.levels.AL) < Number(data.levels.NL) - thickness) {
                    levelErrors.push(ErrorMessages.aquicludeLevelBelowLastSoil);
                    errors.AL = ErrorMessages.wrongLevels1;
                }
                if (Number(data.levels.WL) < Number(data.levels.AL)) {
                    levelErrors.push(ErrorMessages.waterLevelBelowAquicludeLevel);
                    errors.WL = ErrorMessages.wrongLevels1;
                    errors.AL = ErrorMessages.wrongLevels1;
                }
            }
        }

        if (data.levels.batchCalc) {
            if (Number(data.levels.AFL) >= Number(data.levels.FL)) {
                levelErrors.push(ErrorMessages.wrongLevels4);
                errors.AFL = ErrorMessages.wrongLevels1;
                errors.FL = ErrorMessages.wrongLevels1;
            }
            //   if (Number(data.levels.FL) - Number(data.levels.AFL) < data.levels.fStep) {
            //     levelErrors.push(ErrorMessages.wrongFStep1);
            //     errors.fStep = ErrorMessages.wrongFStep2;
            //   }
        }
        if (levelErrors.length !== 0) {
            errors.levels = '';
            levelErrors.forEach(
                (error, index) => (errors.levels += error + (index === levelErrors.length - 1 ? '' : ', '))
            );
            currentCount = Object.keys(errors).length;
            if (currentCount > count) {
                count = currentCount;
            }
        }
    }
    // well
    if (data.well.length > 0) {
        const h = data.well.reduce((total, item) => total + Number(item.thickness), 0);
        if (h < Math.abs(Number(data.levels.FL) - Number(data.levels.DL))) {
            errors.well = ErrorMessages.notEnoughGeology;
        }
    } else {
        errors.well = ErrorMessages.notEnoughGeology;
    }

    const well = data.well.map(soil => {
        const wellErrors: Record<string, string> = {}
        if (!soil.soil && soil.soil !== 0) {
            wellErrors.soil = ErrorMessages.required; 
        }
        if (!soil.thickness || soil.thickness === 0) {
            wellErrors.thickness = soil.thickness === 0 ? ErrorMessages.canBeEqualToZero : ErrorMessages.required
        }

        return Object.keys(wellErrors).length ? wellErrors : null;
    });


    if (well.some(error => error !== null)) {
        errors.well = ErrorMessages.notEnoughData;
        errors.wellSoils = well;
    }

    return {
        errors,
        valid: Object.keys(errors).length === 0 ? true : false
    };
};
