import { LineMaker } from 'client/src/models/draft/line.model';
import { svgNS } from '../draft/default.draft';

export const lineMaker = (data: LineMaker): SVGLineElement => {
    const line = document.createElementNS(svgNS, 'line');
    line.setAttribute('class', data.class);
    line.setAttribute('x1', `${data.x1}`);
    line.setAttribute('y1', `${data.y1}`);
    line.setAttribute('x2', `${data.x2}`);
    line.setAttribute('y2', `${data.y2}`);
    return line;
};
