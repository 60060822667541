import { Draft } from 'client/src/models/draft/draft.model';

import { svgNS } from '../draft/default.draft';

import { cross } from './cross.svg';
import { lineMaker } from './line-maker.svg';

export const grid = (width: number, height: number, draft: Draft): SVGGElement => {
    const { centerX, centerY, gridStep, crossStep, crossSize } = draft;
    const g: SVGGElement = document.createElementNS(svgNS, 'g');
    let line: SVGLineElement, path, className;
    g.setAttribute('id', 'grid');

    for (let i = centerX; i < width; i += gridStep) {
        className = Math.round(i - centerX) % crossStep === 0 ? 'gridPrimaryLine' : 'gridSecondaryLine';
        line = lineMaker({ class: className, x1: i, y1: 0, x2: i, y2: height });
        g.appendChild(line);
        line = lineMaker({
            class: className,
            x1: centerX - (i - centerX),
            y1: 0,
            x2: centerX - (i - centerX),
            y2: height
        });
        g.appendChild(line);
    }

    for (let i = centerY; i < height; i += gridStep) {
        className = Math.round(i - centerY) % crossStep === 0 ? 'gridPrimaryLine' : 'gridSecondaryLine';
        line = lineMaker({ class: className, x1: 0, y1: i, x2: width, y2: i });
        g.appendChild(line);
        line = lineMaker({
            class: className,
            x1: 0,
            y1: centerY - (i - centerY),
            x2: width,
            y2: centerY - (i - centerY),
        });
        g.appendChild(line);
    }

    for (let i = centerX; i <= width; i += gridStep) {
        for (let k = centerY; k <= height; k += gridStep) {
            if (Math.round(i - centerX) % crossStep === 0 && Math.round(k - centerY) % crossStep === 0) {
                path = cross(i, k, crossSize);
                g.appendChild(path);
                path = cross(centerX - (i - centerX), k, crossSize);
                g.appendChild(path);
                path = cross(i, centerY - (k - centerY), crossSize);
                g.appendChild(path);
                path = cross(centerX - (i - centerX), centerY - (k - centerY), crossSize);
                g.appendChild(path);
            }
        }
    }

    return g;
};
