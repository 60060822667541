import { PolylineMaker } from 'client/src/models/draft/polyline-maker';
import { svgNS } from '../draft/default.draft';
import { tooltip } from './tooltip.svg';

export const polyMaker = (data: PolylineMaker): SVGPolylineElement => {
    const tt: SVGTitleElement = tooltip(data.title);
    const poly: SVGPolygonElement = document.createElementNS(svgNS, 'polyline');
    poly.setAttribute('id', data.id);
    poly.setAttribute('class', data.class);
    poly.setAttribute('points', data.points);
    poly.appendChild(tt);
    
    return poly;
};
