// User
export enum UserAction  {
    SetAuth = '[USER] SET AUTH',
    SetUnAuth = '[USER] SET UN AUTH',
    SetUser = '[USER] SET USER'
}

// UI
export enum UIAction {
    SetErrors = '[UI] SET ERRORS',
    ClearErrors = '[UI] CLEAR ERRORS',
    StartLoading = '[UI] START LOADING',
    StopLoading = '[UI] STOP LOADING',
    SetMenu = '[UI] SET MENU',
    SetNews = '[UI] SET NEWS',
    ReportDone = '[UI] REPORT DONE'
}

// Admin
export enum AdminAction {
    SetAllTakskList = '[ADMIN] SET ALL TASKSLIST',
    SetAllUsersList = '[ADMIN] SET ALL USERLIST'
}

// Bugs report
export enum BugReportAction {
    PostBug = '[BUGS] POST',
    SetBugs = '[BUGS] SET',
    ArchiveBug = '[BUGS] ARCHIVE'
}

// Task
export enum TaskAction {
    InputChange = '[TASK] INPUT_CHANGE',
    TableChange = '[TASK] TABLE_CHANGE',
    ColorChange = '[TASK] COLOR_CHANGE',
    AddRow = '[TASK] ADD_ROW',
    DeleteRow = '[TASK] DELETE_ROW',
    Reset = '[TASK] RESET',

    Set = '[TASK] SET',
    Post = '[TASK] POST',
    Update = '[TASK] UPDAET',
    Delete = '[TASK] DELETE',
    Do = '[TASK] DO',

    UpdateToggle = '[TASK] UPDAET_TOGGLE',
    SaveToggle = '[TASK] SAVE_TOGGLE'
}

export enum TaskListAction {
    Set = '[TASKLIST] SET',
    Add = '[TASKLIST] ADD_TASK'
}
