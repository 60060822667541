import React from 'react';
import { Link } from 'react-router-dom';

import theme from '../util/theme';
import fblogo from '../images/fb.png';
import vklogo from '../images/vk.png';
import oklogo from '../images/ok.png';
import twlogo from '../images/tw.png';
import instalogo from '../images/insta.png';
import telegram from '../images/telegram.png';
import youtube from '../images/youtube.png';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

// Redux Stuff
import { connect } from 'react-redux';

const styles = {
  ...theme,
  socialImages: {
    width: 45
  }
};

const SocialLinks = props => {
  const {
    classes,
    UI: { loading }
  } = props;
  return (
    <Paper className={classes.paperSheet}>
      {loading ? (
        <div>...загрузка</div>
      ) : (
        <div>
          <Typography variant="h6" noWrap></Typography>
          <br />
          <List className={classes.tasksList}>
            <ListItem style={{ justifyContent: 'center' }}>
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                component={Link}
                to="/signup"
              >
                Регистрация
              </Button>
            </ListItem>
            <ListItem style={{ justifyContent: 'center' }}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                component={Link}
                to="/login"
              >
                Вход
              </Button>
            </ListItem>
            <ListItem style={{ justifyContent: 'center' }}></ListItem>
            <Divider />
            <ListItem style={{ justifyContent: 'center', flexWrap: 'wrap' }}>
              <a
                href="https://www.facebook.com/meduzaprocom/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.socialImages}
                  src={fblogo}
                  alt="facebook"
                />
              </a>
              <a
                href="https://vk.com/club189948174"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className={classes.socialImages} src={vklogo} alt="vk" />
              </a>
              <a
                href="https://ok.ru/group/70000000063141"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className={classes.socialImages} src={oklogo} alt="ok" />
              </a>
              <a
                href="https://twitter.com/meduza_pro"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.socialImages}
                  src={twlogo}
                  alt="twitter"
                />
              </a>
              <a
                href="https://www.instagram.com/meduzaprocom/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.socialImages}
                  src={instalogo}
                  alt="instagram"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UC5vK37T1Pai2aLyUnp3LhDg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.socialImages}
                  src={youtube}
                  alt="youtube"
                />
              </a>
              <a
                href="https://t.me/beezduke"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.socialImages}
                  src={telegram}
                  alt="telegram"
                />
              </a>
            </ListItem>
          </List>
        </div>
      )}
    </Paper>
  );
};

const mapStateToProps = state => ({
  user: state.user,
  UI: state.UI
});

export default connect(mapStateToProps)(withStyles(styles)(SocialLinks));
