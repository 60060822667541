import { BugReportAction, UIAction } from '../types';

import { BugStatus } from '../../models/bug-report.model';

import { AppDispatch } from '../store';

import axios from 'axios';

export const postBug = (userId: string, taskId: string, title: string, description: string) => (dispatch: AppDispatch) => {
    dispatch({ type: UIAction.StartLoading });
    const req = {
        userId,
        taskId,
        title,
        description,
        status: BugStatus.Active
    };

    axios
        .post('/bugs', req)
        .then(res => {
            dispatch({ type: BugReportAction.PostBug });
            dispatch({ type: UIAction.StopLoading });
        })
        .catch(err => console.error(err));
};

export const getBugs = () => (dispatch: AppDispatch) => {
    dispatch({ type: UIAction.StartLoading });
    axios
        .get(`/bugs`)
        .then(res => {
            dispatch({
                type: BugReportAction.SetBugs,
                payload: res.data
            });
            dispatch({ type: UIAction.StopLoading });
        })
        .catch(err => console.error(err));
};

export const archiveBug = (taskId: string) => (dispatch: AppDispatch) => {
    axios
        .post(`/bugs/${taskId}`)
        .then(res => {
            dispatch({ type: BugReportAction.ArchiveBug });
            dispatch({ type: UIAction.StopLoading });
        })
        .catch(err => console.error(err));
};
