import { MoistureType, SoilType, SandStructureType, CoarseStructureType } from '../models/soil.model';

const soilsDecoder = (data: SoilType | SandStructureType | CoarseStructureType | MoistureType | undefined, cut?: boolean) => {
    switch (data) {
      case SoilType.coarseGrained:
        return cut ? 'крупнобл.' : 'крупнообломочный грунт';
      case SoilType.sand:
        return 'песок';
      case SoilType.sandLoam:
        return 'супесь';
      case SoilType.loam:
        return 'суглинок';
      case SoilType.clay:
        return 'глина';
      // case SoilType.fillUp:
      //   return cut ? 'насыпной' : 'насыпной грунт';
      case SandStructureType.same:
        return cut ? 'крупный' : 'крупный или средней крупности';
      case SandStructureType.small:
        return 'мелкий';
      case SandStructureType.dust:
        return 'пылеватый';
      case SandStructureType.loose:
        return 'рыхлый';
      case CoarseStructureType.sandy:
        return cut ? 'песок' : 'с песчаным заполнителем';
      case CoarseStructureType.clayly:
        return cut ? 'глина' : 'с глинистым заполнителем';
      case MoistureType.semiwet:
        return cut ? 'маловлаж.' : 'маловлажный';
      case MoistureType.wet:
        return cut ? 'насыщ.' : 'насыщенный'
      default:
        return data;
    }
};

export default soilsDecoder;
