import React from 'react';

const labels = {
    // general
    lSize: 'L, длина сооружения',
    hSize: 'H, высота сооружения',
    hcf: (
        <React.Fragment>
          {'h'}<sub>{'cf'}</sub>{', толщина слоя грунта под полом'}
        </React.Fragment>
    ),
    hs: (
        <React.Fragment>
          {'h'}<sub>{'s'}</sub>{', толщина пола'}
        </React.Fragment>
      ),
    gammacf: (
        <React.Fragment>
        {'γ'}<sub>{'cf'}</sub>{', удельный вес пола'}
      </React.Fragment>
    ),
    bExisting: (
        <React.Fragment>
        {'b'}<sub>{'сущ'}</sub>
      </React.Fragment>
    ),
    lExisting: (
        <React.Fragment>
        {'l'}<sub>{'сущ'}</sub>
      </React.Fragment>
    ),
    diam: 'D, диаметр',
    lb: 'l/b, соотношение сторон',
    sepLimit: 'допустимая форма эпюры',
    sizeModule: 'модуль',
    hp: (
      <React.Fragment>
        {'h'}<sub>{'p'}</sub>{', толщина подготовки'}
      </React.Fragment>
    ),
    bColumn: (
      <React.Fragment>
        {'b'}<sub>{'c'}</sub>{', ширина подколонника'}
      </React.Fragment>
    ),
    lColumn: (
      <React.Fragment>
        {'l'}<sub>{'c'}</sub>{', длина подколонника'}
      </React.Fragment>
    ),
    smax: (
      <React.Fragment>
        {'s'}<sub>{'max'}</sub>{', максимально допустимая осадка'}
      </React.Fragment>
    ),
    imax: (
      <React.Fragment>
        {'i'}<sub>{'max'}</sub>{', максимально допустимый крен'}
      </React.Fragment>
    ),
    soilThickness: (
      <React.Fragment>
        {'h'}<sub>{'i'}</sub>{', толщина i-того слоя'}
      </React.Fragment>
    ),
    // Levels
    NL: `NL, отметка природного рельефа`,
    DL: 'DL, отметка планировки',
    CL: 'CL, отметка обреза подколонника',
    FL: 'FL, отметка подошвы',
    WL: 'WL, отметка уровня грунтовых вод',
    AL: 'AL, отметка водоупора',
    AFL: 'AFL, отметка границы',
    fStep: 'шаг',
    // loads
    n: 'N, нормальная сила',
    my: (
      <React.Fragment>
        {'M'}<sub>{'y'}</sub>{', момент '}<em>{'относительно'}</em>{' оси Y'}
      </React.Fragment>
    ),
    mx: (
      <React.Fragment>
        {'M'}<sub>{'x'}</sub>{', момент '}<em>{'относительно'}</em>{' оси X'}
      </React.Fragment>
    ),
    qy: (
      <React.Fragment>
        {'Q'}<sub>{'y'}</sub>{', горизонтальная сила '}<em>{'вдоль'}</em>{' оси Y'}
      </React.Fragment>
    ),
    qx: (
      <React.Fragment>
        {'Q'}<sub>{'x'}</sub>{', горизонтальная сила '}<em>{'вдоль'}</em>{' оси X'}
      </React.Fragment>
    )
};

export default labels;
