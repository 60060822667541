export const oneRectangle = (
    centerX: number,
    limit: number,
    up: number,
    down: number,
    index: number,
    last: boolean
): string[] => {
    let path: string = '',
        lastPath: string = '';
    const paths: string[] = [];

    path = `M ${centerX + limit} ${up} ${centerX - limit} ${up} ${centerX - limit} ${down} ${
        centerX + limit
    } ${down} Z`;
    paths.push(path);

    if (!last) {
        lastPath = `M ${centerX + limit} ${down} ${centerX - limit} ${down} `;
        lastPath += index === 0 ? `M ${centerX + limit} ${up} ${centerX - limit} ${up}` : ``;
        paths.push(lastPath);
    }

    return paths;
};
