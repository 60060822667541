import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import theme from '../../util/theme';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

const styles = {
    ...theme,
    newLine: {
        display: 'block',
        paddingLeft: 20
    }
}

const NewsMessage = props => {
  const { classes, message: { title, createdAt, description } } = props;
  dayjs.locale('ru')
  return (
    <ListItem>
      <ListItemText
        component="div"
        primary={
          <Fragment>
            <Typography component="span" variant="subtitle1" color="primary">
            {dayjs(createdAt).format('DD MMMM YYYY')}
            </Typography>
            {' - '}
            {title}
          </Fragment>
        }
        secondary={
        <Fragment>
            <Typography
              component="span"
              variant="body2"
              color="textPrimary"
            ></Typography>
              {description.map((item, index) => (
                <span className={classes.newLine} key={index}>{item}</span>
              ))}
          </Fragment>
        }
      />
    </ListItem>
  );
};

export default withStyles(styles)(NewsMessage);
