import React, { Fragment } from "react";

// Components
//import TabInput from "./helpers/TabInput";
import SimpleTooltip from "./helpers/simple-tooltip.component";
import SoilTooltip from "./helpers/soils-tooltip.component";

import theme from "../util/theme";

// Mui Stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";

// Icons
import Assignment from "@material-ui/icons/Assignment";

const styles = {
  ...theme
};

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(150, 150, 150, 0.075)',
    },
  },
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
  root: {
    '&:first-child': {
      textAlign: 'left'
    }
  },
  head: {
    backgroundColor: 'rgba(149, 75, 180, 0.1)',
    color: theme.palette.secondary.contrastText,
    padding: 10,
    fontSize: '1rem',
    textAlign: 'right'
  },
  body: {
    padding: 10,
    fontSize: '1rem',
    textAlign: 'right'
  }
}))(TableCell);

const header = [
  //{ title: "#", field: "number" },
  { title: "b, м", field: "b" },
  { title: "l, м", field: "l" },
  { title: "Слой", field: "soilUnder" },
  { title: "d, м", field: "d" },
  { title: "R, т/м²", field: "r" },
  { title: (<Fragment>P<sub>max</sub>, т/м²</Fragment>), field: "pmax" },
  { title: (<Fragment>P<sub>mid</sub>, т/м²</Fragment>), field: "pmid" },
  { title: (<Fragment>P<sub>min</sub>, т/м²</Fragment>), field: "pmin" },
  { title: "Эпюра", field: "sep" },
  { title: (<Fragment>S<sub>max</sub>, см</Fragment>), field: "smax" },
  { title: (<Fragment>h<sub>c</sub>, м</Fragment>), field: "hc" },
  { title: "Объём бетона, м³", field: "volume" },
];

class Report extends React.Component {
  render() {
    console.log(this.props)
    const { classes, data, data: { report }, handleReport, reportDone } = this.props;
    return reportDone ? (
      <div className={classes.dataList}>
        <Typography component="span" variant="subtitle1">
          Анализ
        </Typography>
        <br/>
        <div>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>#</StyledTableCell>
                  {header.map((item, index) => (
                    <StyledTableCell key={`rth${index}`}>
                      {item.field === 'sep' ? <SimpleTooltip titleKey="sep" placement="top-start" text={item.title}/> : item.title}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell></StyledTableCell>
                </TableRow>
                {/*<tr>
              {header.map(item => {
                if (item.field === 'b' || item.field === 'l' || item.field === 'd') {
                  return (<th>
                    <TabInput
                      error={{}}
                      name={item.field}
                      index={{}}
                      handleChange={{}}
                      value={{}}
                      /></th>)
                } else {
                  return (<th></th>)
                }
              })}
            </tr>*/}
              </TableHead>
              <TableBody>
                {report.map((element, i) => (
                  <StyledTableRow key={`stb${i}`}>
                    <StyledTableCell>{i+1}</StyledTableCell>
                    {header.map((item, index) => (
                      <StyledTableCell key={`rtb${index}`}
                        style={{ backgroundColor: (item.field === 'smax' && element.settlement.smaxCondition) ||
                          (item.field === 'pmax' && (element.result.conditionOne || element.result.conditionTwo))
                          ? 'rgba(255,0,0,0.1)'
                          : item.field === 'r' && element.result.rmax
                            ? 'rgba(255,230,128,0.5)'
                            : '' }}>
                        {item.field === 'smax'
                          ? element.settlement[item.field].toFixed(2)
                          : item.field === 'hc'
                            ? element.settlement[item.field].toFixed(2)
                              :item.field === 'soilUnder'
                              ? (
                                  <SoilTooltip
                                    index={element[item.field]}
                                    data={data.soilsData[element[item.field]]}
                                    fragment={<div style={{ backgroundColor: data.soilsData[element['soilUnder']].color, textAlign: 'center' }}>
                                      &nbsp;{element[item.field]+1}&nbsp;</div>}
                                  />
                                )
                              : item.field === 'r' && element.result.rmax
                                ? <SimpleTooltip titleKey='rmax' placement='top-start' text={element.result[item.field].toFixed(2)} />
                                  : item.field === 'sep'
                                    ? (element.result[item.field]/100).toFixed(2)
                                    : element.result[item.field].toFixed(2)
                        }
                      </StyledTableCell>
                    ))}
                    <StyledTableCell>
                      <IconButton onClick={e => handleReport(e, i)}>
                        <Assignment/>
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
        </div>
      </div>
    ) : ('Необходимо выполнить расчёт');
  }
}

export default withStyles(styles)(Report);
