import React from 'react';

import { Task } from 'client/src/models/task.model';

const DraftRep = (props: { data: Task }) => {
  const { data } = props;
  return (
    <article style={{ textAlign: 'left' }}>
      <div style={{ textAlign: 'center' }}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="reportDraft"
            width="600"
            height="600"
            viewBox="0 0 600 600"
            >
            Ваш браузер не поддерживает svg
        </svg>
      </div>
      <br/>
      <table className="soilsTable">
        <thead>
            <tr>
                <th>Цвет</th>
                {data.well.map((item, index) =>
                    <td key={`pw${index}`}>
                        <div style={{ backgroundColor: data.soilsData[item.soil].color }}>&nbsp;&nbsp;</div>
                    </td>)}
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>Слой</th>
                {data.well.map((item, index) => <td key={`pws${index}`}>{item.soil+1}</td>)}
            </tr>
            <tr>
                <th>h, м</th>
                {data.well.map((item, index) => <td key={`pwr${index}`}>{item.thickness}</td>)}
            </tr>
        </tbody>
      </table>
    </article>
  )
}

export default DraftRep;
