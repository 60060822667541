export const d = (down, land, d) => {
  return `d=|FL - DL|=|${down}${land > 0 ? "+" : "-"}${Math.abs(land)}|=${d.toFixed(2)}м`
};

export const dn = (down, relief, dn) => {
  return `d_{n}=|FL - NL|=|${down}${relief > 0 ? "+" : "-"}${Math.abs(relief)}|=${dn.toFixed(2)}м \\\\`;
};

export const db = (d, db, hc, hcf, dbText, d1) => {
  if (dbText) {
    return `d_{b}=d-h_{c}-h_{cf}=${d}-${hc}-${hcf}=${(d-hc-hcf).toFixed(2)} > 2, d_{b}=2 м`
  } else if (d1 === d && db === 0) {
    return `d_{b}=d-h_{c}-h_{cf}=${d}-${hc}-${hcf}=${(d-hc-hcf).toFixed(2)}, d_{1}>d, d_{b}=0`
  } else {
    return `d_{b}=d-h_{c}-h_{cf}=${d}-${hc}-${hcf}=${db.toFixed(2)}м`
  }
};

export const d1 = (hs, hcf, gammacf, gamma2s, d1, preparation, hp, d, db, basement) => {
  if (!basement) {
    if (preparation) {
      return `d_{1}=d+h_{p}=${d.toFixed(2)}+${hp}=${d1.toFixed(2)} м`
    } else {
      return `d_{1}=d=${d1.toFixed(2)} м`
    }
  } else if (preparation) {
    return `d_{1}=h_{s}+h_{cf}\\gamma_{cf}/\\gamma_{II}+h_{p}=${hs}+${hcf}\\times${gammacf}/${gamma2s.toFixed(2)}+${hp}=${d1.toFixed(2)} м`;
  } else if (d1 === d && db === 0) {
    return `d_{1}=h_{s}+h_{cf}\\gamma_{cf}/\\gamma_{II}'=${hs}+${hcf}\\times${gammacf}/${gamma2s.toFixed(2)}=${(parseFloat(hs)+hcf*gammacf/gamma2s).toFixed(2)}, d_{1}>d, d_{1}=d=${d.toFixed(2)} м`
  } else {
    return `d_{1}=h_{s}+h_{cf}\\gamma_{cf}/\\gamma_{II}'=${hs}+${hcf}\\times${gammacf}/${gamma2s.toFixed(2)}=${d1.toFixed(2)} м`;
  }
};

export const r = (gammac1, gammac2, k, mg, mq, mc, kz, b, gamma2, gamma2s, d1, db, c2, r, rVariable, rmaxCondition, rmax, rOrigin) => {
    let formula = '';
    formula += "R=\\frac{\\gamma_{c1}\\gamma_{c2}}{k}\\left[M_{\\gamma}k_{z}b\\gamma_{II}"+
    "+M_{q}d_{1}\\gamma_{II}'+\\left(M_{q}-1\\right)d_{b}\\gamma_{II}'+M_{c}c_{II}\\right]= \\\\ ";
    formula += `=\\frac{${gammac1}\\times${gammac2.toFixed(2)}}{${k}}`;
    formula += `\\times\\left[${mg}\\times${kz}\\times${b.toFixed(2)}\\times${gamma2.toFixed(2)}`;
    formula += `+${mq}\\times${d1.toFixed(2)}\\times${gamma2s.toFixed(2)}+ \\\\`;
    formula += `+\\left(${mq}-1\\right)\\times${db.toFixed(2)}\\times${gamma2s.toFixed(2)}`;
    //formula += `+${mc}\\times${c2.toFixed(2)}\\right]=${r.toFixed(2)} т/м^2`;
    if (rVariable && rVariable.condition) {
      formula += `+${mc}\\times${c2.toFixed(2)}\\right]=${rVariable.r.toFixed(2)} т/м^2 \\\\`;
      formula += `R=2R=2\\times${rVariable.r.toFixed(2)}=${(2*rVariable.r).toFixed(2)} т/м^2\\enspace так\\enspace как\\enspace под\\enspace `
      formula += `подошвой\\enspace подготовка\\enspace п.5.6.20[1]\\\\`;
    } else if (rmaxCondition) {
      formula += `+${mc}\\times${c2.toFixed(2)}\\right]=${rOrigin.toFixed(2)} т/м^2 \\\\`;
      formula += `R=R_{max}=${rmax}т/м^2,\\enspace так\\enspace как\\enspace R>R_{max}`;
    } else formula += `+${mc}\\times${c2.toFixed(2)}\\right]=${r.toFixed(2)} т/м^2 \\\\`;
    return formula
  };

export const footSize = (b, l, roundFoot, checkExisting, diamEx, diamR) => {
  if (roundFoot && checkExisting){
    return `b=\\sqrt{A}=\\sqrt{\\pi D^2/4}=\\sqrt{\\pi\\times${diamEx}^2/4}=${b.toFixed(2)}м`
  } else if (roundFoot) {
    return `b=l=${b.toFixed(2)}м \\\\ D=\\sqrt{b^24/\\pi}=\\sqrt{${b.toFixed(2)}^2/\\pi}=${diamR.toFixed(2)}м`
  } else if (l === 0) {
    return `b=l=${b.toFixed(2)}м`
  } else {
    return `b=${b.toFixed(2)}м \\\\ l=${l.toFixed(2)}м`
  }
};

export const a = (b, l, a, roundFoot, checkExisting, diamEx, diamR) => {
  if (roundFoot) {
    if (checkExisting) {
      return `A=\\pi D^2/4=\\pi\\times${diamEx}^2/4=${a.toFixed(2)} м^2`
    } else {
      return `A=\\pi D^2/4=\\pi\\times${diamR.toFixed(2)}^2/4=${a.toFixed(2)} м^2`
    }
  } else {
    if (l === 0) {
      return `A=bl=${b.toFixed(2)}\\times${b.toFixed(2)}=${a.toFixed(2)} м^2`
    } else {
      return `A=bl=${b.toFixed(2)}\\times${l.toFixed(2)}=${a.toFixed(2)} м^2`
    }
  }
};

export const w = (b, l, wy, wx, roundFoot, checkExisting, diamEx, diamR) => {
  let w = '';
  if (roundFoot) {
    if (checkExisting){
      return `W=\\pi D^3/32=\\pi\\times${diamEx}^3/32=${wx.toFixed(2)} м^3`
    } else {
      return `W=\\pi D^3/32=\\pi\\times${diamR.toFixed(2)}^3/32=${wx.toFixed(2)} м^3`
    }
  } else {
    w += `W_{x}=bl^2/6=${b.toFixed(2)}\\times${l.toFixed(2)}^2/6=${wx.toFixed(2)} м^3 \\\\ `;
    w += `W_{y}=lb^2/6=${l.toFixed(2)}\\times${b.toFixed(2)}^2/6=${wy.toFixed(2)} м^3`;
    return w
  }
};

export const m = (mxEx, myEx, mx, my, qx, qy, up, down) => {
  const height = Math.abs(down - up);
  let m = '';
  if (qx || qy) {
    m += `h_{фунд}=|FL-CL|=${down}-${up}=${height.toFixed(2)}м \\\\ `
  }
  if (qy) {
    m += `M_{x}=M_{x}+Q_{y}h_{фунд}=${mxEx}${qy >= 0 ? '+' : ''}${qy}\\times${height.toFixed(2)}=${mx.toFixed(2)}тм \\\\`
  } else {
    m += `M_{x}=${mx.toFixed(2)}тм \\\\`
  }
  if (qx) {
    m += `M_{y}=M_{y}+Q_{x}h_{фунд}=${myEx}${qx >= 0 ? '+' : ''}${qx}\\times${height.toFixed(2)}=${my.toFixed(2)}тм \\\\`;
  } else {
    m += `M_{y}=${my.toFixed(2)}тм \\\\`;
  }
  return m
}

export const eFormula = ({ loadMx, loadMy, gammamt, l, b, n, d }, result) => {
  const { name, e, sep } = result;
  let eFormula = '';
  if (name === 'x'/*LoadPlane.x*/) {
    eFormula += `e_{x}=M_{x}/\\left(N+\\gamma_{mt}dlb\\right)=${loadMx.toFixed(2)}/\\left(${n}+${gammamt}`;
      eFormula += `\\times${d.value.toFixed(2)}\\times${l.toFixed(2)}\\times${b.toFixed(2)}\\right)=${e.toFixed(2)}м \\\\`;
  } else {
    eFormula += `e_{y}=M_{y}/\\left(N+\\gamma_{mt}dlb\\right)=${loadMy.toFixed(2)}/\\left(${n}+${gammamt}`;
      eFormula += `\\times${d.value.toFixed(2)}\\times${l.toFixed(2)}\\times${b.toFixed(2)}\\right)=${e.toFixed(2)}м \\\\`;
  }
  if (sep < 0) {
    if (name === 'x') {
      eFormula += `1/6<e_{x}/l\\leq 1/4,\\enspace 1/6<${e.toFixed(2)}/${l.toFixed(2)}\\leq 1/4,\\enspace`;
        eFormula += `1/6<1/${(1/(e/l)).toFixed(0)}\\leq 1/4`;
    } else {
      eFormula += `1/6<e_{y}/b\\leq 1/4,\\enspace 1/6<${e.toFixed(2)}/${b.toFixed(2)}\\leq 1/4,\\enspace`;
        eFormula += `1/6<1/${(1/(e/b)).toFixed(0)}\\leq 1/4`;
    }
  } else {
    if (name === 'x'/*LoadPlane.x*/) {
      eFormula += `e_{x}/l\\leq 1/6,\\enspace ${e.toFixed(2)}/${l.toFixed(2)}\\leq 1/6,\\enspace`;
        eFormula += ` 1/${(1/(e/l)).toFixed(0)}\\leq 1/6`;
    } else {
      eFormula += `e_{y}/b\\leq 1/6,\\enspace ${e.toFixed(2)}/${b.toFixed(2)}\\leq 1/6,\\enspace`;
        eFormula += ` 1/${(1/(e/b)).toFixed(0)}\\leq 1/6`;
    }
  }
  return eFormula
} ;

export const c0Formula = ({ loadMx, loadMy, n, gammamt, l, b, d }, result) => {
  const { name, c0 } = result;
  let c0Formula = '';
  if (name === 'x') {
    c0Formula += `C_{0}=l/2-M_{x}/\\left(N+\\gamma_{mt}dlb\\right)=${l.toFixed(2)}/2-${loadMx.toFixed(2)}\\left(`
      c0Formula += `${n}+${gammamt}\\times${d.value.toFixed(2)}\\times${l.toFixed(2)}\\times${b.toFixed(2)}\\right)=${c0.toFixed(2)}м`;
  } else {
    c0Formula += `C_{0}=b/2-M_{y}/\\left(N+\\gamma_{mt}dlb\\right)=${b.toFixed(2)}/2-${loadMy.toFixed(2)}\\left(`
      c0Formula += `${n}+${gammamt}\\times${d.value.toFixed(2)}\\times${l.toFixed(2)}\\times${b.toFixed(2)}\\right)=${c0.toFixed(2)}м`;
  }
  return c0Formula;
}

export const p0 = ({ n, a, pmax, r, gammamt, d }) => {
  const yes = `\\enspace-\\enspace условие\\enspace выполнено`;
  const no = `\\enspace-\\enspace условие\\enspace не\\enspace выполнено`;
  let p = '';
  p += `p=N/A+\\gamma_{mt}d=${n}/${a.toFixed(2)}+${gammamt}\\times${d.value.toFixed(2)}=${pmax.toFixed(2)}т/м^2 \\\\`;
  p += `p<R,\\enspace ${pmax.toFixed(2)}т/м^2<${r.toFixed(2)}т/м^2`;
    p += `${pmax < r ? yes : no} \\\\`;
  return p
}

export const p1 = ({ n, gammamt,loadMx, loadMy, wx, wy, a, r, d, limit }, result) => {
  const { name, pMax, pMid, pMin, sep } = result;
  const yes = `\\enspace-\\enspace условие\\enspace выполнено`;
  const no = `\\enspace-\\enspace условие\\enspace не\\enspace выполнено`;
  let p = '';
  if (name === 'x') {
    p += `p_{max}=N/A+\\gamma_{mt}d+M_{x}/W_{x}=${n}/${a.toFixed(2)}+${gammamt}\\times${d.value.toFixed(2)}`;
      p += `+${Math.abs(loadMx).toFixed(2)}/${wx.toFixed(2)}=${pMax.value.toFixed(2)}т/м^2 \\\\`;
    p += `p_{min}=N/A+\\gamma_{mt}d-M_{x}/W_{x}=${n}/${a.toFixed(2)}+${gammamt}\\times${d.value.toFixed(2)}`;
      p += `-${Math.abs(loadMx).toFixed(2)}/${wx.toFixed(2)}=${pMin.value.toFixed(2)}т/м^2 \\\\`;
  } else {
    p += `p_{max}=N/A+\\gamma_{mt}d+M_{y}/W_{y}=${n}/${a.toFixed(2)}+${gammamt}\\times${d.value.toFixed(2)}`;
      p += `+${Math.abs(loadMy).toFixed(2)}/${wy.toFixed(2)}=${pMax.value.toFixed(2)}т/м^2 \\\\`;
    p += `p_{min}=N/A+\\gamma_{mt}d-M_{y}/W_{y}=${n}/${a.toFixed(2)}+${gammamt}\\times${d.value.toFixed(2)}`;
      p += `-${Math.abs(loadMy).toFixed(2)}/${wy.toFixed(2)}=${pMin.value.toFixed(2)}т/м^2 \\\\`;
  }
  p += `p_{mid}=\\left(p_{max}+p_{min}\\right)/2=\\left(${pMax.value.toFixed(2)}+${pMin.value.toFixed(2)}\\right)/2=`;
    p += `${pMid.toFixed(2)}т/м^2 \\\\`;
  p += `Форма\\enspace эпюры \\enspace${(sep.value/100).toFixed(2)}\\enspace>\\enspace${limit}`
    p += `${(sep.value/100 > limit) ? yes : no } \\\\ `
  p += `p_{max}<1.2R,\\enspace ${pMax.value.toFixed(2)}<1.2\\times${r.toFixed(2)},\\\\ ${pMax.value.toFixed(2)}т/м^2<${(1.2*r).toFixed(2)}т/м^2`
    p += `${pMax.value < r*1.2 ? yes : no} \\\\`;
  p += `p_{mid}<R,\\enspace ${pMid.toFixed(2)}т/м^2<${r.toFixed(2)}т/м^2`;
    p += `${pMid < r ? yes : no} \\\\`;
  return p
};

export const p2 = ({ n, gammamt, l, b, r, d, limit }, result) => {
  const { c0, pMax, pMid, pMin, sep } = result;
  const yes = `\\enspace-\\enspace условие\\enspace выполнено`;
  const no = `\\enspace-\\enspace условие\\enspace не\\enspace выполнено`;
  let p = '';
  p += `p_{max}=2\\left(N+\\gamma_{mt}dlb\\right)/\\left(3bC_{0}\\right)=\\\\`;
    p += `2\\left(${n}+${gammamt}\\times${d.value.toFixed(2)}\\times${l.toFixed(2)}\\times${b.toFixed(2)}\\right)/`;
    p += `\\left(3\\times${b.toFixed(2)}\\times${c0.toFixed(2)}\\right)=${pMax.value.toFixed(2)}т/м^2 \\\\`;
  p += `p_{min}=0.00 \\\\`;
  p += `p_{mid}=\\left(p_{max}+p_{min}\\right)/2=\\left(${pMax.value.toFixed(2)}+${pMin.value.toFixed(0)}\\right)/2=`;
    p += `${pMid.toFixed(2)}т/м^2 \\\\`;
  p += `Отрыв\\enspace${Math.abs(sep.value).toFixed(2)}\\% \\enspace<\\enspace${Math.abs(limit*100).toFixed(2)}\\%`
    p += `${(sep.value > limit*100) ? yes : no } \\\\ `
  p += `p_{max}<1.2R,\\enspace ${pMax.value.toFixed(2)}<1.2\\times${r.toFixed(2)},\\\\ ${pMax.value.toFixed(2)}т/м^2<${(1.2*r).toFixed(2)}т/м^2`
    p += `${pMax.value < r*1.2 ? yes : no} \\\\`;
  p += `p_{mid}<R,\\enspace ${pMid.toFixed(2)}т/м^2<${r.toFixed(2)}т/м^2`;
    p += `${pMid < r ? yes : no} \\\\`;
  return p
};

export const p4 = ({ gammamt, d, loadMx, loadMy, n, wx, wy, r, a }, result) => {
  const { p1, p2, p3, p4, pMax, pMid } = result;
  const yes = `\\enspace-\\enspace условие\\enspace выполнено`;
  const no = `\\enspace-\\enspace условие\\enspace не\\enspace выполнено`;
  let p = '';
  p += `P_{1}=N/A+\\gamma_{mt}d+M_{x}/W_{x}-M_{y}/W_{y}=\\\\ ${n}/${a.toFixed(2)}+${gammamt}\\times${d.value.toFixed(2)}`
    p += `${loadMx > 0 ? '+' : '-'}${Math.abs(loadMx).toFixed(2)}/${wx.toFixed(2)}`
    p += `${loadMy > 0 ? '-' : '+'}${Math.abs(loadMy).toFixed(2)}/${wy.toFixed(2)}=${p1.toFixed(2)}т/м^2 \\\\`;
  p += `P_{2}=N/A+\\gamma_{mt}d+M_{x}/W_{x}+M_{y}/W_{y}=\\\\ ${n}/${a.toFixed(2)}+${gammamt}\\times${d.value.toFixed(2)}`;
    p += `${loadMx > 0 ? '+' : '-'}${Math.abs(loadMx).toFixed(2)}/${wx.toFixed(2)}`
    p += `${loadMy > 0 ? '+' : '-'}${Math.abs(loadMy).toFixed(2)}/${wy.toFixed(2)}=${p2.toFixed(2)}т/м^2 \\\\`;
  p += `P_{3}=N/A+\\gamma_{mt}d-M_{x}/W_{x}+M_{y}/W_{y}=\\\\ ${n}/${a.toFixed(2)}+${gammamt}\\times${d.value.toFixed(2)}`;
    p += `${loadMx > 0 ? '-' : '+'}${Math.abs(loadMx).toFixed(2)}/${wx.toFixed(2)}`
    p += `${loadMy > 0 ? '+' : '-'}${Math.abs(loadMy).toFixed(2)}/${wy.toFixed(2)}=${p3.toFixed(2)}т/м^2 \\\\`;
  p += `P_{4}=N/A+\\gamma_{mt}d-M_{x}/W_{x}-M_{y}/W_{y}=\\\\ ${n}/${a.toFixed(2)}+${gammamt}\\times${d.value.toFixed(2)}`
    p += `${loadMx > 0 ? '-' : '+'}${Math.abs(loadMx).toFixed(2)}/${wx.toFixed(2)}`
    p += `${loadMy > 0 ? '-' : '+'}${Math.abs(loadMy).toFixed(2)}/${wy.toFixed(2)}=${p4.toFixed(2)}т/м^2 \\\\`;
  p += `P_{max}<1.5R,\\enspace ${pMax.value.toFixed(2)}<1.5\\times${r.toFixed(2)},\\\\ ${pMax.value.toFixed(2)}т/м^2<${(1.5*r).toFixed(2)}т/м^2`;
  p += `\\enspace ${pMax.value<(1.5*r) ? yes : no}\\\\`;
  p += `P_{mid}=\\left(P_{1}+P_{2}+P_{3}+P_{2}\\right)/4=\\`;
    p += `left(${p1.toFixed(2)}${p2 > 0 ? '+' : ''}${p2.toFixed(0)}${p3 > 0 ? '+' : ''}${p3.toFixed(0)}${p4 > 0 ? '+' : ''}${p4.toFixed(0)}\\right)/4=${pMid.toFixed(2)}т/м^2\\\\`;
  p += `p_{mid}<R,\\enspace ${pMid.toFixed(2)}т/м^2<${r.toFixed(2)}т/м^2`;
  p += `${pMid < r ? yes : no} \\\\`;
  return p
};

export const sFormula = (foundationPit, pSigmazgCondition) => {
  let s = `s=`;
  if (pSigmazgCondition) {
    s += `\\beta\\displaystyle\\sum_{i=1}^{n}\\frac{\\sigma_{zp,i}h_{i}}{E_{e,i}}`;
  } else {
    s += `\\beta\\displaystyle\\sum_{i=1}^{n}\\frac{\\left(\\sigma_{zp,i}-\\sigma_{z\\gamma,i}\\right)h_{i}}{E_{i}}`;
    s += foundationPit ? '' : `+\\beta\\displaystyle\\sum_{i=1}^{n}\\frac{\\sigma_{z\\gamma,i}h_{i}}{E_{e,i}}`;
  }
  return s
};

export const sigmazgamma0 = () => {
  return `\\sigma_{z\\gamma}=\\sigma_{zg}-\\sigma_{zu}`
};

export const sigmazgamma = () => {
  return `\\sigma_{z\\gamma}=\\alpha\\sigma_{zg,0}`;
};

export const sigmazg0 = (relief, land, gamma2s, d, dn, sigmazg0) => {
  if (land > relief) {
    return `\\sigma_{zg,0}=\\gamma'd_{n}=${gamma2s.toFixed(2)}\\times${dn.toFixed(2)}=${sigmazg0.toFixed(2)}т/м^2`;
  } else {
    return `\\sigma_{zg,0}=\\gamma'd=${gamma2s.toFixed(2)}\\times${d.toFixed(2)}=${sigmazg0.toFixed(2)}т/м^2`;
  }
};

export const sigmazg = () => {
  return`\\sigma_{zg}=\\displaystyle\\sum_{i=1}^{n}\\gamma_{i}h_{i}-u\\left(z\\right)`;
};

export const sigmazp = () => {
  return`\\sigma_{zp}=\\alpha p`;
};

export const uz = () => {
  return `u_{z}=\\rho_{w}gz_{w}`
}

export const hBigSoilE = (formula) => {
  let h = `h\\geq H_{c}(1-\\sqrt[3]{E_2/E_1});\\enspace ${formula.hUp}\\geq ${formula.hc.toFixed(2)}`;
  h += `\\times (1-\\sqrt[3]{${formula.e2}/${formula.e1}});\\enspace ${formula.hUp}\\geq ${formula.h.toFixed(2)}`;
  return h
}

export const wl_a = (l, b, a) => {
  return `a=(l-b)/2=(${l.toFixed(2)}-${b.toFixed(2)})/2=${a.toFixed(2)}м`
}

export const wl_az = (result, sigmazp, az) => {
  const { gammamt, d, b, l, n } = result;
  let _az = `A_{z}=N/\\sigma_{zp}=\\left(N+\\gamma_{mt}dbl\\right)/\\sigma_{zp}=`;
  _az += `\\left(${n.toFixed(2)}+${gammamt.toFixed(2)}\\times${d.toFixed(2)}`;
  _az += `\\times${b.toFixed(2)}\\times${l.toFixed(2)}\\right)/`
  _az += `${sigmazp.toFixed(2)}=${az.toFixed(2)}м^2`;
  return _az
}

export const wl_bz = (az, a, bz) => {
  let _b = `b_{z}=\\sqrt{A_{z}+a^2}-a=\\sqrt{${az.toFixed(2)}+${a.toFixed(2)}^2}`;
  _b += `-${a.toFixed(2)}=${bz.toFixed(2)}м`;
  return _b
}

export const wl_sigmaz = (sigmazp, sigmazgamma, sigmazg, sigmaz) => {
  let _sigmaz = `\\sigma_{z}=\\left(\\sigma_{zp}-\\sigma_{z\\gamma}\\right)+`;
  _sigmaz += `\\sigma_{zg}=\\left(${sigmazp.toFixed(2)}-${sigmazgamma.toFixed(2)}\\right)`
  _sigmaz += `+${sigmazg.toFixed(2)}=${sigmaz.toFixed(2)}т/м^2`;
  return _sigmaz
}

export const wl_p = (pmid, sigmazg0, p) => {
  return `p=p_{mid}-\\sigma_{zg,0}=${pmid.toFixed(2)}-${sigmazg0.toFixed(2)}=${p.toFixed(2)}т/м^2`
}

export const wl_sigmazg = (sigmazg) => {
  let wlSigma = `\\sigma_{zg}=\\sum_{i=1}^{n}\\gamma_{i}h_{i}-u(z)`;
  wlSigma += `=${sigmazg.toFixed(2)}т/м^2`;
  return wlSigma
}

export const wl_sigmazgamma = (alpha, sigmazg0, sigmazgamma) => {
  let wlSigma = `\\sigma_{z\\gamma}=\\alpha\\sigma_{zg,0}=`
  wlSigma += `${alpha.toFixed(3)}\\times ${sigmazg0.toFixed(2)}=${sigmazgamma.toFixed(2)}т/м^2`;
  return wlSigma
};

export const wl_sigmazp = (alpha, p, sigmazp) => {
  let wlSigma = `\\sigma_{zp}=\\alpha p_{mid}=${alpha.toFixed(3)}\\times ${p.toFixed(2)}`;
  wlSigma += `=${sigmazp.toFixed(2)}т/м^2`;
  return wlSigma
};

export const wlCondition = (sigmaz, rz) => {
  let condition = `\\sigma_{z}\\leq R_{z}\\enspace ${sigmaz.toFixed(2)}\\leq${rz.toFixed(2)}`;
  condition += sigmaz <= rz
    ? `\\enspace -условие\\enspace выполнено`
    : `\\enspace -условие\\enspace не\\enspace выполнено`;
  return condition
}

export const waterInGamma = () => {
  return `\\gamma_{sb}=\\left(\\gamma_{s} - \\gamma_{w}\\right) / \\left(1 + e\\right), \\enspace где\\enspace \\gamma_{w} = 1`;
}

export const leaningI = (plane, D, ke, N, e, a, i) => {
  return `i_{${plane}} = Dk_{e}\\frac{Ne}{\\left(a / 2\\right)^3}=${D.toFixed(5)}\\times${ke.toFixed(2)}\\frac{${N.toFixed(2)}\\times${e.toFixed(2)}}{\\left(${a.toFixed(2)}/2\\right)^3}=${i.toFixed(5)}`
}

export const leaningD = (D) => {
  return `D=\\frac{1-\\nu^2}{E}=${D.value.toFixed(5)} (т/м^2)^{-1}`
}

export const leaningDAvg = (D) => {
  return `D=\\sum_{i=1}^{n}\\left(A_{i}\\frac{1-\\nu_{i}^2}{E_{i}}\\right)/\\sum_{i=1}^{n}A_{i}=${D.value.toFixed(5)} (т/м^2)^{-1}`
}

export const leaningN = (n, gammaMt, d, l, b, N) => {
  return `N=N + \\gamma_{cf}dlb = ${n.toFixed(2)} + ${gammaMt.toFixed(2)}\\times${d.toFixed(2)}\\times${l.toFixed(2)}\\times${b.toFixed(2)} = ${N.toFixed(2)} т`;
}
