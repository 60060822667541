export enum BugStatus {
    Active = 'active',
    Archived = 'archived'
}

export interface ReportBugToSave {
    userId: string;
    taskId: string;
    description: string;
    title: string
    status: BugStatus;
    createdAt: string;
    archivedAt?: string;
}

export type ReportBug = ReportBugToSave & { bugId: string };