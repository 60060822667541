import { AdminAction, BugReportAction } from '../types';

const initialState = {
    allTasks: [],
    allUsers: [],
    allBugs: [],
};

// TODO remove any
export default function (state = initialState, action: any) {
    switch (action.type) {
        case AdminAction.SetAllTakskList:
            return {
                ...state,
                allTasks: action.payload,
            };
        case AdminAction.SetAllUsersList:
            return {
                ...state,
                allUsers: action.payload,
            };
        case BugReportAction.SetBugs: 
            return {
                ...state,
                allBugs: action.payload
            }
        default:
            return state;
    }
}
