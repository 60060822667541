export enum CalcCriteria {
    byPmid,
    byPCorner,
    byPEdgeX,
    byPEdgeY,
    bySettlement,
    byWeakLayer,
    bySeparationX,
    bySeparationY,
    bySeparationBoth,
    // byEccentricityX,
    // byEccentricityY,
    // byEccentricityBoth
    byLeaningX,
    byLeaningY,
    // byLeaningXY
}