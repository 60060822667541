import React from 'react';

import theme from '../../util/theme';

// Mui Stuff
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Icons
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';

const styles = {
  ...theme
};

const TasksSearchbar = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  //const open = Boolean(anchorEl);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { classes } = props;
  return (
    <span>
      <IconButton
        className={classes.iconButton}
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
          <MenuItem
            key="logout"
            onClick={props.handleLogout}
          >
              <ExitToApp/>&nbsp;
              Выйти
          </MenuItem>
      </Menu>
    </span>
  );
};

export default withStyles(styles)(TasksSearchbar);
