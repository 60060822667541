import { svgNS } from '../draft/default.draft';

export const cross = (x: number, y: number, crossSize: number) => {
    const path: SVGPathElement = document.createElementNS(svgNS, 'path');
    let d: string = `M ${x - crossSize} ${y} ${x + crossSize} ${y}`;
    d += `M ${x} ${y - crossSize} ${x} ${y + crossSize}`;
    path.setAttribute('d', d);
    path.setAttribute('class', 'gridCross');
    return path;
};
